import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { AgendaPageRoutingModule } from "./agenda-routing.module";

import { AgendaPage } from "./agenda.page";
import { DetallesComponent } from "./detalles/detalles.component";

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, AgendaPageRoutingModule],
    declarations: [AgendaPage, DetallesComponent]
})
export class AgendaPageModule {}
