import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RegistroMedicoModalPageRoutingModule } from "./registro-medico-modal-routing.module";
import { RegistroMedicoModalPage } from "./registro-medico-modal.page";
import { EditarComponent } from "../registro-medico/editar/editar.component";
import { EditarRegistroSubMenuComponent } from "../components/editar-registro-sub-menu/editar-registro-sub-menu.component";
import { ComponentsModule } from "../components/index.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RegistroMedicoModalPageRoutingModule,
        ReactiveFormsModule,
        ComponentsModule
    ],
    declarations: [RegistroMedicoModalPage,]
})
export class RegistroMedicoModalPageModule {}
