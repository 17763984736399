<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="primary" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ registromedico.nombre }}</ion-title>

    <ion-buttons slot="primary">
      <ion-button (click)="getForm()">
        <ion-icon slot="icon-only" color="primary" name="add-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div style="text-align: center" *ngIf="itemsCartilla">
    <img
      src="/assets/cartilla-icons/{{registromedico.icon}}.svg"
      alt=""
      width="100"
    />
  </div>
  <div *ngIf="itemsCartilla">
    <!-- Registro de cirugias -->
    <div [ngSwitch]="registromedico.icon">
      <ion-list *ngSwitchCase="'cirugias'">
        <h3 class="text-center">Registro de Cirugias</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small">{{ fecha(item.fechacirugia)}}</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'cirugias')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Responsable médico: </ion-text>
                {{item.nombrecirugia}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Precio: </ion-text>
                {{item.preciocirugia}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Tipo: </ion-text>
                {{item.tipocirujua}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Observaciones: </ion-text>
                {{item.observacionescirugia}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text>
                {{item.notascirugia}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Registro de Alergias -->
      <ion-list *ngSwitchCase="'alergias'">
        <h3>Registro de Alergias</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechadiagalergias)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'alergias')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Nombre: </ion-text>
                {{item.nombrealergias}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Gravedad: </ion-text>
                {{item.gravedadalergias}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Acciones: </ion-text>
                {{item.accionesrealalergias}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Categoria: </ion-text>
                {{item.categoriaalergias}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text>
                {{item.notasalergias}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <ion-list *ngSwitchCase="'comida'">
        <h3 class="text-center">Registro de Alimentación</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small">{{ fecha(item.fechacomida)}}</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'comida')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Nombre: </ion-text>
                {{item.nombrecomida}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Tipo: </ion-text> {{item.tipocomida}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text>
                {{item.notascomida}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Registro de Fertilidad -->
      <ion-list *ngSwitchCase="'estros'">
        <h3 class="text-center">Registro de Fertilidad</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechainicioestros)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'estros')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Fecha inicio: </ion-text>
                {{fecha(item.fechainicioestros)}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Fecha final: </ion-text>
                {{fecha(item.fechafinalestros)}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas:</ion-text>
                {{item.notasestros}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Registro de Higiene -->
      <ion-list *ngSwitchCase="'higiene'">
        <h3 class="text-center">Registro de Higiene</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechatipohigiene)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'higiene')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="4">
                <ion-text color="primary"> Tipo: </ion-text>
                {{item.tipohigihigiene}}
              </ion-col>
              <ion-col size="8">
                <ion-text color="primary">Gravedad:</ion-text>
                {{item.gravedadhigiene}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text
                >{{item.notashigiene}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <ion-list *ngSwitchCase="'incidencias'">
        <h3 class="text-center">Error, si ves esto reportalo!</h3>
        <ion-toolbar>
          <ion-title size="small"
            >Registro de {{ registromedico.nombre }}</ion-title
          >
          <ion-buttons slot="end">
            <!-- <ion-button (click)="presentPopover($event, item, 'higiene')"> -->
              <ion-button>
              <ion-icon slot="icon-only" color="primary" name="more"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-list-header class="center"> </ion-list-header>
        <!-- <ion-item *ngFor="let item of itemsCartilla; let i=index">
          <ion-icon slot="end" name="logo-ionic"></ion-icon>
          <ion-label>Label with Icon</ion-label>
        </ion-item> -->
      </ion-list>
      <ion-list *ngSwitchCase="'mantenimiento-periodico'">
        <h3 class="text-center">Registro de Desparasitación</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechamantenimientoperiodico)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'mantenimiento-periodico')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Tipo: </ion-text>
                {{item.tipomantenimientoperiodico}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Gravedad: </ion-text>
                {{item.gravedadmantenimientoperiodico}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text
                >{{item.notasmantenimientoperiodico}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Registro de medicamentos -->
      <ion-list *ngSwitchCase="'medicamentos'">
        <h3 class="text-center">Registro de Medicamentos</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechamedicamentos)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'medicamentos')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="6">
                <ion-text color="primary"> Nombre: </ion-text>
                {{item.nombremedicamentos}}
              </ion-col>
              <ion-col size="6">
                <ion-text color="primary"> Dosis: </ion-text>
                {{item.dosismedicamentos}}
              </ion-col>
              <ion-row>
                <ion-col size="12">
                  <ion-text color="primary"> Higiene: </ion-text>
                  {{item.higienemedicamentos}}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <ion-text color="primary"> Notas: </ion-text>
                  {{item.notasmedicamentos}}
                </ion-col>
              </ion-row>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Registro de Mediciones -->
      <ion-list *ngSwitchCase="'mediciones'">
        <h3 class="text-center">Registro de Mediciones</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small">{{ fecha(item.fechamediciones)}}</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'mediciones')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Tipo: </ion-text
                >{{item.tipomediciones}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Valor: </ion-text
                >{{item.valormediciones}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text
                >{{item.notasmediciones}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- patologias existentes -->
      <ion-list *ngSwitchCase="'patologias-existentes'">
        <h3 class="text-center">Registro de Patologías Existentes</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechapatologiasexistentes)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'patologias-existentes')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Nombre: </ion-text>
                {{item.nombrepatologiasexistentes}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Categoria: </ion-text>
                {{item.categoriapatologiasexistentes}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text color="primary"> Notas: </ion-text
                >{{item.notaspatologiasexistentes}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <ion-list *ngSwitchCase="'pdiagnostico'">
        <h3 class="text-center">Registro de Análisis Clínicos</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechapdiagnostico)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'pdiagnostico')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Descripcion: </ion-text>
                {{item.descripcionpdiagnostico}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text>
                {{item.notaspdiagnostico}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <!-- Rgistro de test de diagnostico -->
      <ion-list *ngSwitchCase="'test-diagnostico'">
        <h3 class="text-center">Registro de pruebas diagnóstico</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechatestdiagnostico)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'test-diagnostico')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Nombre: </ion-text>
                {{item.nombretestdiagnostico}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Resultados: </ion-text>
                {{item.resultadotestdiagnostico}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas:</ion-text>
                {{item.notastestdiagnostico}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      <ion-list *ngSwitchCase="'vacunacion'">
        <h3 class="text-center">Registro de Vacunación</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small">{{ fecha(item.fechavacunacion)}}</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="presentPopover($event, item, 'vacunacion')">
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Vacuna: </ion-text
                >{{item.vacunavacunacion}}</ion-col
              >
            </ion-row>
            <ion-row>
              <ion-col size="12"
                ><ion-text color="primary">Notas: </ion-text
                >{{item.notasvacunacion}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
      
      <ion-list *ngSwitchCase="'visitas-a-veterinario'">
        <h3 class="text-center">Registro de Consultas Veterinarias</h3>
        <div *ngIf="itemsCartilla.length === 0" style="text-align: center">
          <h5 class="no-found-text">¡No se han encontrado registros!</h5>
          <ion-button (click)="getForm()">
            Crear Registro
          </ion-button>
        </div>
        <ion-card *ngFor="let item of itemsCartilla">
          <ion-toolbar>
            <ion-title size="small"
              >{{ fecha(item.fechavisitasaveterinario)}}</ion-title
            >
            <ion-buttons slot="end">
              <ion-button
                (click)="presentPopover($event, item, 'visitas-a-veterinario')"
              >
                <ion-icon
                  slot="icon-only"
                  color="primary"
                  name="more"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Responsable médico: </ion-text
                >{{item.nombrevisitasaveterinario}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Descripción: </ion-text
                >{{item.descripcionvisitasaveterinario}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Costo: </ion-text
                >{{item.preciovisitasaveterinario}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-text color="primary"> Notas: </ion-text
                >{{item.notasvisitasaveterinario}}
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </div>
  </div>
  <div class="modal-container">
    <input id="modal-toggle" type="checkbox" checked="{{checked}}" />
    <label class="modal-backdrop" for="modal-toggle-"></label>
    <div class="modal-content" style="overflow: auto">
      <label class="modal-close" for="modal-toggle" (click)="closeModal()"
        >&#x2715;</label
      >
      <h3 class="head-tittle" style="text-align: center; color: var(--ion-color-secondary); font-weight: 600;">
        {{ registromedico.nombre }}
      </h3>
      <div class="form-registro">
        <div [ngSwitch]="registromedico.icon">
          <div *ngSwitchCase="'cirugias'">
            <form
              (submit)="enviarDatos(registromedico.icon,cirugias)"
              [formGroup]="cirugias"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Tipo de cirugía</ion-label
                      >
                      <ion-input
                        formControlName="tipocirujua"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechacirugia"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                     <ion-item>
                      <ion-label position="floating"
                        >Responsable Médico</ion-label
                      >
                      <ion-input
                        formControlName="nombrecirugia"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Costo de cirugía</ion-label
                      >
                      <ion-input
                        formControlName="preciocirugia"
                        type="number"
                        value="0"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Observaciones Médicas</ion-label
                      >
                      <ion-input
                        formControlName="observacionescirugia"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notascirugia"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'alergias'">
            <form
              (submit)="enviarDatos(registromedico.icon,alergias)"
              [formGroup]="alergias"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        formControlName="nombrealergias"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del diagnóstico</ion-label
                      >
                      <ion-input
                        formControlName="fechadiagalergias"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Gravedad</ion-label>
                      <ion-select
                        formControlName="gravedadalergias"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="bajo"
                          >Bajo</ion-select-option
                        >
                        <ion-select-option value="moderado"
                          >Moderado</ion-select-option
                        >
                        <ion-select-option value="alto"
                          >Alto</ion-select-option
                        >
                        <ion-select-option value="emergencia"
                          >Emergencia</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Categoria</ion-label>
                      <ion-select
                        formControlName="categoriaalergias"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Alimentarias"
                          >Alimentarias</ion-select-option
                        >
                        <ion-select-option value="Ambientales"
                          >Ambientales</ion-select-option
                        >
                        <ion-select-option value="Parasitarias"
                          >Parasitarias</ion-select-option
                        >
                        <ion-select-option value="Otras"
                          >Otras</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Acciones realizadas</ion-label
                      >
                      <ion-input
                        formControlName="accionesrealalergias"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasalergias"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'comida'">
            <form
              (submit)="enviarDatos(registromedico.icon,comida)"
              [formGroup]="comida"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        formControlName="nombrecomida"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Tipo</ion-label>
                      <ion-select
                        formControlName="tipocomida"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Alimento Principal"
                          >Alimento principal</ion-select-option
                        >
                        <ion-select-option value="Suplemento"
                          >Suplemento</ion-select-option
                        >
                        <ion-select-option value="Premio"
                          >Premio</ion-select-option
                        >
                        <ion-select-option value="Otro"
                          >Otro</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechacomida"
                        type="date"
                        (ionChange)="setFecha($event)"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label>Recordatorio</ion-label>
                      <ion-select
                        multiple="true"
                        cancelText="Cerrar"
                        okText="Aceptar"
                        formControlName="recordatorios"
                      >
                        <ion-select-option value="dia"
                          >Un dia</ion-select-option
                        >
                        <ion-select-option value="dias"
                          >Tres dias</ion-select-option
                        >
                        <ion-select-option value="semana"
                          >Una semana</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notascomida"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'estros'">
            <form
              (submit)="enviarDatos(registromedico.icon,estros)"
              [formGroup]="estros"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha de inicio</ion-label
                      >
                      <ion-input
                        formControlName="fechainicioestros"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha de finalizacion</ion-label
                      >
                      <ion-input
                        formControlName="fechafinalestros"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasestros"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'higiene'">
            <form
              (submit)="enviarDatos(registromedico.icon,higiene)"
              [formGroup]="higiene"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Tipo de Aplicación</ion-label
                      >
                      <ion-select
                        formControlName="tipohigihigiene"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Estética"
                          >Estética</ion-select-option
                        >
                        <ion-select-option value="Baño"
                          >Baño</ion-select-option
                        >
                        <ion-select-option value="Dientes"
                          >Dientes</ion-select-option
                        >
                        <ion-select-option value="Oídos"
                          >Oídos</ion-select-option
                        >
                        <ion-select-option value="Uñas"
                          >Uñas</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechatipohigiene"
                        type="date"
                        (ionChange)="setFecha($event)"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Gravedad</ion-label>
                      <ion-select
                        formControlName="gravedadhigiene"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Cuidado Especial"
                          >Cuidado Especial</ion-select-option
                        >
                        <ion-select-option value="En casa"
                          >En casa</ion-select-option
                        >
                        <ion-select-option value="Profesional Veterinario"
                          >Profesional Veterinario</ion-select-option
                        >
                        <ion-select-option value="Estilista"
                          >Estilista</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label>Recordatorio</ion-label>
                      <ion-select
                        multiple="true"
                        cancelText="Cerrar"
                        okText="Aceptar"
                        formControlName="recordatorios"
                      >
                        <ion-select-option value="dia"
                          >Un dia</ion-select-option
                        >
                        <ion-select-option value="dias"
                          >Tres dias</ion-select-option
                        >
                        <ion-select-option value="semana"
                          >Una semana</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notashigiene"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'incidencias'">
            <form
              (submit)="enviarDatos(registromedico.icon,incidencias)"
              [formGroup]="incidencias"
            >
              <ion-grid style="padding: 0">
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Descripción</ion-label>
                      <ion-input
                        formControlName="descripcionincidencias"
                        type="text"
                      ></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating">Gravedad</ion-label>
                      <ion-select
                        formControlName="categoriaincidencias"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="alim"
                          >Menor</ion-select-option
                        >
                        <ion-select-option value="ambiem"
                          >Medio</ion-select-option
                        >
                        <ion-select-option value="para"
                          >Mayor</ion-select-option
                        >
                        <ion-select-option value="otras"
                          >Crítico</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del Insidente</ion-label
                      >
                      <ion-input
                        formControlName="fechainsidenteincidencias"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <button
                class="modal-content-btn"
                for="modal-toggle"
                type="submit"
              >
                Agregar a Cartilla
              </button>
            </form>
          </div>
          <div *ngSwitchCase="'limpieza'">
            <form
              (submit)="enviarDatos(registromedico.icon,limpieza)"
              [formGroup]="limpieza"
            >
              <ion-grid style="padding: 0">
                <ion-row>
                  <ion-col>
                    <ion-list>
                      <ion-item>
                        <ion-label position="floating"
                          >Tipo de Limpieza</ion-label
                        >
                        <ion-select
                          formControlName="tipolimpieza"
                          interface="popover"
                          placeholder="Seleccione uno"
                        >
                          <ion-select-option value="inde"
                            >Plato de comida</ion-select-option
                          >
                          <ion-select-option value="bajo"
                            >Cama</ion-select-option
                          >
                          <ion-select-option value="mod"
                            >Juguetes</ion-select-option
                          >
                          <ion-select-option value="alt"
                            >Plato de Agua</ion-select-option
                          >
                          <ion-select-option value="mua"
                            >Correa</ion-select-option
                          >
                        </ion-select>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating">Fecha</ion-label>
                        <ion-input
                          formControlName="fechalimpieza"
                          type="date"
                          (ionChange)="setFecha($event)"
                        ></ion-input>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating"
                          >Tipo de Aplicación</ion-label
                        >
                        <ion-select
                          formControlName="gravedadlimpieza"
                          interface="popover"
                          placeholder="Seleccione uno"
                        >
                          <ion-select-option value="inde"
                            >Cuidado Especial</ion-select-option
                          >
                          <ion-select-option value="bajo"
                            >En casa</ion-select-option
                          >
                          <ion-select-option value="mod"
                            >Profesional Veterinario</ion-select-option
                          >
                        </ion-select>
                      </ion-item>

                      <ion-item>
                        <ion-label position="floating">Notas</ion-label>
                        <ion-input
                          formControlName="notaslimpieza"
                          type="text"
                        ></ion-input>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <button
                class="modal-content-btn"
                for="modal-toggle"
                type="submit"
              >
                Agregar a Cartilla
              </button>
            </form>
          </div>
          <div *ngSwitchCase="'mantenimiento-periodico'">
            <form
              (submit)="enviarDatos(registromedico.icon,mantenimientoperiodico)"
              [formGroup]="mantenimientoperiodico"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Tipo de Desparasitación</ion-label
                      >
                      <ion-select
                        formControlName="tipomantenimientoperiodico"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Desparasitación"
                          >Desparasitación</ion-select-option
                        >
                        <ion-select-option value="Antipulgas/Garrapatas"
                          >Antipulgas/Garrapatas</ion-select-option
                        >
                        <ion-select-option value="Otro"
                          >Otro</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechamantenimientoperiodico"
                        type="date"
                        (ionChange)="setFecha($event)"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Tipo de Aplicación</ion-label
                      >
                      <ion-select
                        formControlName="gravedadmantenimientoperiodico"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="bajo"
                          >En casa</ion-select-option
                        >
                        <ion-select-option value="mod"
                          >Profesional Veterinario</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label>Recordatorio</ion-label>
                      <ion-select
                        multiple="true"
                        cancelText="Cerrar"
                        okText="Aceptar"
                        formControlName="recordatorios"
                      >
                        <ion-select-option value="mensual"
                          >Un mes</ion-select-option
                        >
                        <ion-select-option value="trimestral"
                          >Tres Meses</ion-select-option
                        >
                        <ion-select-option value="semestral"
                          >Seis Meses</ion-select-option
                        >
                        <ion-select-option value="anual"
                          >Un año</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasmantenimientoperiodico"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'medicamentos'">
            <form
              (submit)="enviarDatos(registromedico.icon,medicamentos)"
              [formGroup]="medicamentos"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        formControlName="nombremedicamentos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasmedicamentos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Dosis</ion-label>
                      <ion-input
                        formControlName="dosismedicamentos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha de inicio</ion-label
                      >
                      <ion-input
                        formControlName="fechamedicamentos"
                        type="date"
                        (ionChange)="setFecha($event)"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Tipo de Aplicación</ion-label
                      >
                      <ion-select
                        formControlName="higienemedicamentos"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="inde"
                          >Cuidado Especial</ion-select-option
                        >
                        <ion-select-option value="bajo"
                          >En casa</ion-select-option
                        >
                        <ion-select-option value="mod"
                          >Profesional Veterinario</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label>Recordatorio</ion-label>
                      <ion-select
                        multiple="true"
                        cancelText="Cerrar"
                        okText="Aceptar"
                        formControlName="recordatorios"
                      >
                        <ion-select-option value="dia"
                          >Un dia</ion-select-option
                        >
                        <ion-select-option value="dias"
                          >Tres dias</ion-select-option
                        >
                        <ion-select-option value="semana"
                          >Una semana</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'mediciones'">
            <form
              (submit)="enviarDatos(registromedico.icon,mediciones)"
              [formGroup]="mediciones"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Tipo</ion-label>
                      <ion-select
                        formControlName="tipomediciones"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Peso"
                          >Peso</ion-select-option
                        >
                        <ion-select-option value="Temperatura"
                          >Temperatura</ion-select-option
                        >

                        <ion-select-option value="Altura"
                          >Altura</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Valor</ion-label>
                      <ion-input
                        formControlName="valormediciones"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechamediciones"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasmediciones"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'patologias-existentes'">
            <form
              (submit)="enviarDatos(registromedico.icon,patologiasexistentes)"
              [formGroup]="patologiasexistentes"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        type="text"
                        formControlName="nombrepatologiasexistentes"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del Diagnostico</ion-label
                      >
                      <ion-input
                        formControlName="fechapatologiasexistentes"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Gravedad</ion-label>
                      <ion-select
                        formControlName="categoriapatologiasexistentes"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Menor"
                          >Menor</ion-select-option
                        >
                        <ion-select-option value="Medio"
                          >Medio</ion-select-option
                        >
                        <ion-select-option value="Mayor"
                          >Mayor</ion-select-option
                        >
                        <ion-select-option value="Critico"
                          >Crítico</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notaspatologiasexistentes"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'pdiagnostico'">
            <form
              (submit)="enviarDatos(registromedico.icon,pdiagnostico)"
              [formGroup]="pdiagnostico"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Descripción</ion-label>
                      <ion-input
                        formControlName="descripcionpdiagnostico"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del Diagnostico</ion-label
                      >
                      <ion-input
                        formControlName="fechapdiagnostico"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notaspdiagnostico"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'rnumericos'">
            <form
              (submit)="enviarDatos(registromedico.icon,rnumericos)"
              [formGroup]="rnumericos"
            >
              <ion-grid style="padding: 0">
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        formControlName="nombrernumericos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del Diagnostico</ion-label
                      >
                      <ion-input
                        formControlName="fecharnumericos"
                        type="date"
                      ></ion-input>
                    </ion-item>

                    <ion-item>
                      <ion-label position="floating">Valor</ion-label>
                      <ion-input
                        formControlName="valorrnumericos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating">Margen</ion-label>
                      <ion-input
                        formControlName="margenrnumericos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating">Unidad</ion-label>
                      <ion-input
                        formControlName="unidadrnumericos"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <button
                class="modal-content-btn"
                for="modal-toggle"
                type="submit"
              >
                Agregar a Cartilla
              </button>
            </form>
          </div>
          <div *ngSwitchCase="'test-diagnostico'">
            <form
              (submit)="enviarDatos(registromedico.icon,testdiagnostico)"
              [formGroup]="testdiagnostico"
            >
              <ion-grid  class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Nombre</ion-label>
                      <ion-input
                        formControlName="nombretestdiagnostico"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating"
                        >Fecha del Diagnostico</ion-label
                      >
                      <ion-input
                        formControlName="fechatestdiagnostico"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Resultado</ion-label>
                      <ion-select
                        formControlName="resultadotestdiagnostico"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Positivo"
                          >Positivo</ion-select-option
                        >
                        <ion-select-option value="Negativo"
                          >Negativo</ion-select-option
                        >
                        <ion-select-option value="Sin Conclucion"
                          >Sin conclusión</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notastestdiagnostico"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'vacunacion'">
            <form
              (submit)="enviarDatos(registromedico.icon,vacunacion)"
              [formGroup]="vacunacion"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Seleccione vacuna</ion-label
                      >
                      <ion-select
                        formControlName="vacunavacunacion"
                        interface="popover"
                        placeholder="Seleccione uno"
                      >
                        <ion-select-option value="Parvo Quincenal"
                          >Parvo Quincenal</ion-select-option
                        >
                        <ion-select-option value="Puppy Quincenal"
                          >Puppy Quincenal</ion-select-option
                        >
                        <ion-select-option value="Triple Quincenal"
                          >Triple Quincenal</ion-select-option
                        >
                        <ion-select-option value="Multiple Quincenal"
                          >Multiple Quincenal</ion-select-option
                        >
                        <ion-select-option value="Multiple Anual"
                          >Multiple Anual</ion-select-option
                        >
                        <ion-select-option value="Rabia Anual"
                          >Rabia Anual</ion-select-option
                        >
                        <ion-select-option value="Bordetela Anual"
                          >Bordetela Anual</ion-select-option
                        >
                        <ion-select-option value="Giardia Anual"
                          >Giardia Anual</ion-select-option
                        >
                        <ion-select-option value="Otra"
                          >Otra</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                        <ion-input
                          formControlName="fechavacunacion"
                          type="date"
                          (ionChange)="setFecha($event)"
                        ></ion-input>
                      </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label>Recordatorio</ion-label>
                        <ion-select
                          multiple="true"
                          cancelText="Cerrar"
                          okText="Aceptar"
                          formControlName="recordatorios"
                        >
                          <ion-select-option value="quincenal"
                            >Quincenal</ion-select-option
                          >
                          <ion-select-option value="Anual"
                            >Anual</ion-select-option
                          >
                        </ion-select>
                      </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasvacunacion"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'visitas-a-veterinario'">
            <form
              (submit)="enviarDatos(registromedico.icon,visitasaveterinario)"
              [formGroup]="visitasaveterinario"
            >
              <ion-grid class="no-pad">
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Descripción</ion-label>
                      <ion-input
                        formControlName="descripcionvisitasaveterinario"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Fecha</ion-label>
                      <ion-input
                        formControlName="fechavisitasaveterinario"
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Responsable médico</ion-label
                      >
                      <ion-input
                        formControlName="nombrevisitasaveterinario"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating"
                        >Costo de consulta</ion-label
                      >
                      <ion-input
                        formControlName="preciovisitasaveterinario"
                        type="number"
                        value="0"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="campo-formulario" size="12">
                    <ion-item>
                      <ion-label position="floating">Notas</ion-label>
                      <ion-input
                        formControlName="notasvisitasaveterinario"
                        type="text"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="boton-agregar" size="12">
                    <ion-button
                      expand="block"
                      type="submit"
                    >
                      Agregar a Cartilla
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <div *ngSwitchCase="'test-geneticos'">
            <form
              (submit)="enviarDatos(registromedico.icon,testgeneticos)"
              [formGroup]="testgeneticos"
            >
              <ion-grid style="padding: 0">
                <ion-row>
                  <ion-col>
                    <ion-list>
                      <ion-item>
                        <ion-label position="floating">Nombre</ion-label>
                        <ion-input
                          formControlName="nombretestgeneticos"
                          type="text"
                        ></ion-input>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating">Resultado</ion-label>
                        <ion-select
                          formControlName="resultacdotestgeneticos"
                          interface="popover"
                          placeholder="Seleccione uno"
                        >
                          <ion-select-option value="inde"
                            >-/-</ion-select-option
                          >
                          <ion-select-option value="bajo"
                            >X/-</ion-select-option
                          >
                          <ion-select-option value="mod">X/X</ion-select-option>
                        </ion-select>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating">Fecha</ion-label>
                        <ion-input
                          formControlName="fechatestgeneticos"
                          type="date"
                        ></ion-input>
                      </ion-item>
                      <ion-item>
                        <ion-label position="floating">Notas</ion-label>
                        <ion-input
                          formControlName="notastestgeneticos"
                          type="text"
                        ></ion-input>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <button
                class="modal-content-btn"
                for="modal-toggle"
                type="submit"
              >
                Agregar a Cartilla
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
