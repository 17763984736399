import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { BusinessPageRoutingModule } from "./business-routing.module";

import { BusinessPage } from "./business.page";
import { HorarioModalComponent } from "../agenda/horario-modal/horario-modal.component";
import { IonicSelectableModule } from "ionic-selectable";
import { ColorCircleModule } from "ngx-color/circle";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        BusinessPageRoutingModule,
        ReactiveFormsModule,
        ColorCircleModule,
        IonicSelectableModule,
    ],
    declarations: [BusinessPage],
})
export class BusinessPageModule {}
