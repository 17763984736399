import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FirebaseService } from "src/app/services/firebase/firebase.service";
import { ToastController } from "@ionic/angular";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es";
import moment from "moment";
dayjs.locale("es");
@Component({
    selector: "app-horario-modal",
    templateUrl: "./horario-modal.component.html",
    styleUrls: ["./horario-modal.component.scss"],
})
export class HorarioModalComponent implements OnInit {
    @Input() ubicacion: any;
    public cantidadCitasMaxima = 5;
    public citasString: string = "5";
    public abierto24Horas = false;

    public horariosDefault = {
        deHora: "07",
        deMinutos: "30",
        aHora: "18",
        aMinutos: "00",
        deHoraEx: "18",
        deMinutosEx: "30",
        aHoraEx: "22",
        aMinutosEx: "00",
    };

    public diasAbierto = [
        {
            dia: "Lunes",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Martes",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Miercoles",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Jueves",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Viernes",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Sabado",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
        {
            dia: "Domingo",
            abierto: false,
            extended: false,
            "24Horas": false,
            ...this.horariosDefault,
        },
    ];

    public horarios = [
        { value: "00", label: "12 AM" },
        { value: "01", label: "1 AM" },
        { value: "02", label: "2 AM" },
        { value: "03", label: "3 AM" },
        { value: "04", label: "4 AM" },
        { value: "05", label: "5 AM" },
        { value: "06", label: "6 AM" },
        { value: "07", label: "7 AM" },
        { value: "08", label: "8 AM" },
        { value: "09", label: "9 AM" },
        { value: "10", label: "10 AM" },
        { value: "11", label: "11 AM" },
        { value: "12", label: "12 PM" },
        { value: "13", label: "1 PM" },
        { value: "14", label: "2 PM" },
        { value: "15", label: "3 PM" },
        { value: "16", label: "4 PM" },
        { value: "17", label: "5 PM" },
        { value: "18", label: "6 PM" },
        { value: "19", label: "7 PM" },
        { value: "20", label: "8 PM" },
        { value: "21", label: "9 PM" },
        { value: "22", label: "10 PM" },
        { value: "23", label: "11 PM" },
    ];

    public minutos = [
        { value: "00", label: "en punto" },
        { value: "05", label: "5 minutos" },
        { value: "10", label: "10 minutos" },
        { value: "15", label: "15 minutos" },
        { value: "20", label: "20 minutos" },
        { value: "25", label: "25 minutos" },
        { value: "30", label: "30 minutos" },
        { value: "35", label: "35 minutos" },
        { value: "40", label: "40 minutos" },
        { value: "45", label: "45 minutos" },
        { value: "50", label: "50 minutos" },
        { value: "55", label: "55 minutos" },
    ];
    constructor(
        private firebase: FirebaseService,
        private modalCtrl: ModalController,
        private toastController: ToastController
    ) {}

    async presentToast() {
        const toast = await this.toastController.create({
            message: "Su horario ha sido guardado exitosamente.",
            duration: 2000,
        });
        toast.present();
    }

    async closeModal(creating = false) {
        await this.modalCtrl.dismiss({
            schedule: this.diasAbierto,
            maximasCitas: this.cantidadCitasMaxima,
        });
    }

    ngOnInit(): void {
        try {
            if (this.ubicacion.horarios[0]) {
                this.diasAbierto = this.ubicacion.horarios;
                switch (true) {
                    case this.diasAbierto[0]["24Horas"]:
                    case this.diasAbierto[1]["24Horas"]:
                    case this.diasAbierto[2]["24Horas"]:
                    case this.diasAbierto[3]["24Horas"]:
                    case this.diasAbierto[4]["24Horas"]:
                    case this.diasAbierto[5]["24Horas"]:
                    case this.diasAbierto[6]["24Horas"]:
                        this.abierto24Horas = true;
                        break;

                    default:
                        break;
                }
            }
        } catch (error) {}
    }

    reducirCitasHora() {
        if (this.cantidadCitasMaxima > 0) {
            --this.cantidadCitasMaxima;
            this.citasString = this.cantidadCitasMaxima.toString();
        }
    }

    aumentarCitasHora() {
        if (this.cantidadCitasMaxima < 15) {
            ++this.cantidadCitasMaxima;
            this.citasString = this.cantidadCitasMaxima.toString();
        }
    }

    cambioCitas(event) {
        this.cantidadCitasMaxima = event.detail.value;
        if (event.detail.value == 0) {
            this.citasString = "Sin citas";
        } else {
            this.citasString = this.cantidadCitasMaxima.toString();
        }
    }

    guardarHorario() {
        if (this.abierto24Horas) {
            for (var dia of this.diasAbierto) {
                dia.aHora = "23";
                dia.aMinutos = "55";
                dia.deHora = "00";
                dia.deMinutos = "00";
                dia.abierto = true;
                dia.extended = false;
                dia["24Horas"] = true;
            }
        }

        if (this.ubicacion.prodid !== "0") {
            this.ubicacion.horarios = this.diasAbierto;
            this.firebase
                .updateCollection(
                    this.ubicacion.prodid,
                    "UBICACIONES",
                    this.ubicacion
                )
                .then(() => {
                    this.presentToast();
                    this.closeModal();
                });
        } else {
            this.closeModal(true);
        }
    }
}
