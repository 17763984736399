<ion-header translucent>
  <ion-toolbar>
    <ion-title color="secondary">Editar registro</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(false)">
        <ion-icon color="primary" slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="form-registro" *ngIf="ready">
    <div [ngSwitch]="tipo">
      <div *ngSwitchCase="'cirugias'">
        <form (submit)="enviarDatos(tipo, cirugias)" [formGroup]="cirugias">
          <ion-grid style="padding: 0; margin-bottom: 1em">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Tipo de cirugía</ion-label>
                    <ion-input
                      formControlName="tipocirujua"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechacirugia"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"
                      >Responsable Médico</ion-label
                    >
                    <ion-input
                      formControlName="nombrecirugia"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Costo de cirugía</ion-label>
                    <ion-input
                      formControlName="preciocirugia"
                      type="number"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"
                      >Observaciones Médicas</ion-label
                    >
                    <ion-input
                      formControlName="observacionescirugia"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notascirugia"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'alergias'">
        <form (submit)="enviarDatos(tipo, alergias)" [formGroup]="alergias">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input
                      formControlName="nombrealergias"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"
                      >Fecha del diagnóstico</ion-label
                    >
                    <ion-input
                      formControlName="fechadiagalergias"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Gravedad</ion-label>
                    <ion-select
                      formControlName="gravedadalergias"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="bajo">Bajo</ion-select-option>
                      <ion-select-option value="moderado"
                        >Moderado</ion-select-option
                      >
                      <ion-select-option value="alto">Alto</ion-select-option>
                      <ion-select-option value="emergencia"
                        >Emergencia</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Categoria</ion-label>
                    <ion-select
                      formControlName="categoriaalergias"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="Alimentarias"
                        >Alimentarias</ion-select-option
                      >
                      <ion-select-option value="Ambientales"
                        >Ambientales</ion-select-option
                      >
                      <ion-select-option value="Parasitarias"
                        >Parasitarias</ion-select-option
                      >
                      <ion-select-option value="Otras">Otras</ion-select-option>
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating"
                      >Acciones realizadas</ion-label
                    >
                    <ion-input
                      formControlName="accionesrealalergias"
                      type="text"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasalergias"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'comida'">
        <form (submit)="enviarDatos(tipo, comida)" [formGroup]="comida">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input
                      formControlName="nombrecomida"
                      type="text"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Tipo</ion-label>
                    <ion-select
                      formControlName="tipocomida"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="Alimento Principal"
                        >Alimento principal</ion-select-option
                      >
                      <ion-select-option value="Suplemento"
                        >Suplemento</ion-select-option
                      >
                      <ion-select-option value="Premio"
                        >Premio</ion-select-option
                      >
                      <ion-select-option value="Otro">Otro</ion-select-option>
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechacomida"
                      type="date"
                      (ionChange)="setFecha($event)"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label>Recordatorio</ion-label>
                    <ion-select
                      multiple="true"
                      cancelText="Cerrar"
                      okText="Aceptar"
                      [value]="recordatorios"
                      formControlName="recordatorios"
                    >
                      <ion-select-option value="dia">Un dia</ion-select-option>
                      <ion-select-option value="dias"
                        >Tres dias</ion-select-option
                      >
                      <ion-select-option value="semana"
                        >Una semana</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <!-- <ion-item>
                    <ion-label position="floating">Unidad</ion-label>
                    <ion-select
                      formControlName="unidadcomida"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="kg">KG</ion-select-option>
                      <ion-select-option value="L">L</ion-select-option>
                      <ion-select-option value="gr">gr</ion-select-option>
                      <ion-select-option value="ml">ml</ion-select-option>
                    </ion-select>
                  </ion-item> -->

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notascomida"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'estros'">
        <form (submit)="enviarDatos(tipo, estros)" [formGroup]="estros">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Fecha de inicio</ion-label>
                    <ion-input
                      formControlName="fechainicioestros"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"
                      >Fecha de finalizacion</ion-label
                    >
                    <ion-input
                      formControlName="fechafinalestros"
                      type="date"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasestros"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'higiene'">
        <form (submit)="enviarDatos(tipo, higiene)" [formGroup]="higiene">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Tipo Aplicación</ion-label>
                    <ion-select
                      formControlName="tipohigihigiene"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="Estética"
                        >Estética</ion-select-option
                      >
                      <ion-select-option value="Baño">Baño</ion-select-option>
                      <ion-select-option value="Dientes"
                        >Dientes</ion-select-option
                      >
                      <ion-select-option value="Oídos">Oídos</ion-select-option>
                      <ion-select-option value="Uñas">Uñas</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechatipohigiene"
                      type="date"
                      (ionChange)="setFecha($event)"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Gravedad</ion-label>
                    <ion-select
                      formControlName="gravedadhigiene"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="Cuidado Especial"
                        >Cuidado Especial</ion-select-option
                      >
                      <ion-select-option value="En casa"
                        >En casa</ion-select-option
                      >
                      <ion-select-option value="Profesional Veterinario"
                        >Profesional Veterinario</ion-select-option
                      >
                      <ion-select-option value="Estilista"
                        >Estilista</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label>Recordatorio</ion-label>
                    <ion-select
                      multiple="true"
                      cancelText="Cerrar"
                      okText="Aceptar"
                      [value]="recordatorios"
                      formControlName="recordatorios"
                    >
                      <ion-select-option value="dia">Un dia</ion-select-option>
                      <ion-select-option value="dias"
                        >Tres dias</ion-select-option
                      >
                      <ion-select-option value="semana"
                        >Una semana</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notashigiene"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'incidencias'">
        <form
          (submit)="enviarDatos(tipo, incidencias)"
          [formGroup]="incidencias"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Descripción</ion-label>
                  <ion-input
                    formControlName="descripcionincidencias"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Gravedad</ion-label>
                  <ion-select
                    formControlName="categoriaincidencias"
                    interface="popover"
                    placeholder="Seleccione uno"
                  >
                    <ion-select-option value="alim">Menor</ion-select-option>
                    <ion-select-option value="ambiem">Medio</ion-select-option>
                    <ion-select-option value="para">Mayor</ion-select-option>
                    <ion-select-option value="otras">Crítico</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Fecha del Insidente</ion-label>
                  <ion-input
                    formControlName="fechainsidenteincidencias"
                    type="date"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'limpieza'">
        <form (submit)="enviarDatos(tipo, limpieza)" [formGroup]="limpieza">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Tipo de Limpieza</ion-label>
                    <ion-select
                      formControlName="tipolimpieza"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="inde"
                        >Plato de comida</ion-select-option
                      >
                      <ion-select-option value="bajo">Cama</ion-select-option>
                      <ion-select-option value="mod"
                        >Juguetes</ion-select-option
                      >
                      <ion-select-option value="alt"
                        >Plato de Agua</ion-select-option
                      >
                      <ion-select-option value="mua">Correa</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechalimpieza"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Tipo Aplicación</ion-label>
                    <ion-select
                      formControlName="gravedadlimpieza"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="inde"
                        >Cuidado Especial</ion-select-option
                      >
                      <ion-select-option value="bajo"
                        >En casa</ion-select-option
                      >
                      <ion-select-option value="mod"
                        >Profesional Veterinario</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notaslimpieza"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'mantenimiento-periodico'">
        <form
          (submit)="enviarDatos(tipo, mantenimientoperiodico)"
          [formGroup]="mantenimientoperiodico"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating"
                      >Tipo de Desparasitación</ion-label
                    >
                    <ion-select
                      formControlName="tipomantenimientoperiodico"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                    <ion-select-option value="Desparasitación"
                    >Desparasitación</ion-select-option
                  >
                  <ion-select-option value="Antipulgas/Garrapatas"
                    >Antipulgas/Garrapatas</ion-select-option
                  >
                  <ion-select-option value="Otro"
                    >Otro</ion-select-option
                  >
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechamantenimientoperiodico"
                      type="date"
                      (ionChange)="setFecha($event)"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Tipo Aplicación</ion-label>
                    <ion-select
                      formControlName="gravedadmantenimientoperiodico"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <!-- <ion-select-option value="inde"
                        >Cuidado Especial</ion-select-option
                      > -->
                      <ion-select-option value="bajo"
                        >En casa</ion-select-option
                      >
                      <ion-select-option value="mod"
                        >Profesional Veterinario</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label>Recordatorio</ion-label>
                    <ion-select
                      multiple="true"
                      cancelText="Cerrar"
                      okText="Aceptar"
                      [value]="recordatorios"
                      formControlName="recordatorios"
                    >
                      <ion-select-option value="dia">Un dia</ion-select-option>
                      <ion-select-option value="dias"
                        >Tres dias</ion-select-option
                      >
                      <ion-select-option value="semana"
                        >Una semana</ion-select-option
                      >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasmantenimientoperiodico"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'medicamentos'">
        <form
          (submit)="enviarDatos(tipo, medicamentos)"
          [formGroup]="medicamentos"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input
                      formControlName="nombremedicamentos"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasmedicamentos"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Dosis</ion-label>
                    <ion-input
                      formControlName="dosismedicamentos"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha de inicio</ion-label>
                    <ion-input
                      formControlName="fechamedicamentos"
                      type="date"
                      (ionChange)="setFecha($event)"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label>Recordatorio</ion-label>
                    <ion-select
                      multiple="true"
                      cancelText="Cerrar"
                      okText="Aceptar"
                      [value]="recordatorios"
                      formControlName="recordatorios"
                    >
                      <ion-select-option value="dia">Un dia</ion-select-option>
                      <ion-select-option value="dias"
                        >Tres dias</ion-select-option
                      >
                      <ion-select-option value="semana"
                        >Una semana</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Tipo Aplicación</ion-label>
                    <ion-select
                      formControlName="higienemedicamentos"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="inde"
                        >Cuidado Especial</ion-select-option
                      >
                      <ion-select-option value="bajo"
                        >En casa</ion-select-option
                      >
                      <ion-select-option value="mod"
                        >Profesional Veterinario</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'mediciones'">
        <form (submit)="enviarDatos(tipo, mediciones)" [formGroup]="mediciones">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Tipo</ion-label>
                    <ion-select
                      formControlName="tipomediciones"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="Peso">Peso</ion-select-option>
                      <ion-select-option value="Temperatura"
                        >Temperatura</ion-select-option
                      >

                      <ion-select-option value="Altura"
                        >Altura</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Valor</ion-label>
                    <ion-input
                      formControlName="valormediciones"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechamediciones"
                      type="date"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasmediciones"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'patologias-existentes'">
        <form
          (submit)="enviarDatos(tipo, patologiasexistentes)"
          [formGroup]="patologiasexistentes"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Nombre</ion-label>
                  <ion-input
                    type="text"
                    formControlName="nombrepatologiasexistentes"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"
                    >Fecha del Diagnostico</ion-label
                  >
                  <ion-input
                    formControlName="fechapatologiasexistentes"
                    type="date"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Gravedad</ion-label>
                  <ion-select
                    formControlName="categoriapatologiasexistentes"
                    interface="popover"
                    placeholder="Seleccione uno"
                  >
                    <ion-select-option value="Menor">Menor</ion-select-option>
                    <ion-select-option value="Medio">Medio</ion-select-option>
                    <ion-select-option value="Mayor">Mayor</ion-select-option>
                    <ion-select-option value="Critico"
                      >Crítico</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Notas</ion-label>
                  <ion-input
                    formControlName="notaspatologiasexistentes"
                    type="text"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'pdiagnostico'">
        <form
          (submit)="enviarDatos(tipo, pdiagnostico)"
          [formGroup]="pdiagnostico"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Descripción</ion-label>
                  <ion-input
                    formControlName="descripcionpdiagnostico"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"
                    >Fecha del Diagnostico</ion-label
                  >
                  <ion-input
                    formControlName="fechapdiagnostico"
                    type="date"
                  ></ion-input>
                </ion-item>

                <ion-item>
                  <ion-label position="floating">Notas</ion-label>
                  <ion-input
                    formControlName="notaspdiagnostico"
                    type="text"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'rnumericos'">
        <form (submit)="enviarDatos(tipo, rnumericos)" [formGroup]="rnumericos">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Nombre</ion-label>
                  <ion-input
                    formControlName="nombrernumericos"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"
                    >Fecha del Diagnostico</ion-label
                  >
                  <ion-input
                    formControlName="fecharnumericos"
                    type="date"
                  ></ion-input>
                </ion-item>

                <ion-item>
                  <ion-label position="floating">Valor</ion-label>
                  <ion-input
                    formControlName="valorrnumericos"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Margen</ion-label>
                  <ion-input
                    formControlName="margenrnumericos"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Unidad</ion-label>
                  <ion-input
                    formControlName="unidadrnumericos"
                    type="text"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'test-diagnostico'">
        <form
          (submit)="enviarDatos(tipo, testdiagnostico)"
          [formGroup]="testdiagnostico"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Nombre</ion-label>
                  <ion-input
                    formControlName="nombretestdiagnostico"
                    type="text"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"
                    >Fecha del Diagnostico</ion-label
                  >
                  <ion-input
                    formControlName="fechatestdiagnostico"
                    type="date"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Resultado</ion-label>
                  <ion-select
                    formControlName="resultadotestdiagnostico"
                    interface="popover"
                    placeholder="Seleccione uno"
                  >
                    <ion-select-option value="Positivo"
                      >Positivo</ion-select-option
                    >
                    <ion-select-option value="Negativo"
                      >Negativo</ion-select-option
                    >
                    <ion-select-option value="Sin Conclucion"
                      >Sin conclusión</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Notas</ion-label>
                  <ion-input
                    formControlName="notastestdiagnostico"
                    type="text"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'vacunacion'">
        <form (submit)="enviarDatos(tipo, vacunacion)" [formGroup]="vacunacion">
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Seleccione vacuna</ion-label>
                    <ion-select
                      formControlName="vacunavacunacion"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                    <ion-select-option value="Parvo Quincenal"
                    >Parvo Quincenal</ion-select-option
                  >
                  <ion-select-option value="Puppy Quincenal"
                    >Puppy Quincenal</ion-select-option
                  >
                  <ion-select-option value="Triple Quincenal"
                    >Triple Quincenal</ion-select-option
                  >
                  <ion-select-option value="Multiple Quincenal"
                    >Multiple Quincenal</ion-select-option
                  >
                  <ion-select-option value="Multiple Anual"
                    >Multiple Anual</ion-select-option
                  >
                  <ion-select-option value="Rabia Anual"
                    >Rabia Anual</ion-select-option
                  >
                  <ion-select-option value="Bordetela Anual"
                    >Bordetela Anual</ion-select-option
                  >
                  <ion-select-option value="Giardia Anual"
                    >Giardia Anual</ion-select-option
                  >
                  <ion-select-option value="Otra"
                    >Otra</ion-select-option
                  >
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechavacunacion"
                      type="date"
                      (ionChange)="setFecha($event)"
                    ></ion-input>
                  </ion-item>
                  <!-- <ion-item>
                    <ion-label position="floating"
                      >Tipo Aplicación</ion-label
                    >
                    <ion-select
                      formControlName="higienevacunacion"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="inde"
                        >Cuidado Especial</ion-select-option
                      >
                      <ion-select-option value="bajo"
                        >En casa</ion-select-option
                      >
                      <ion-select-option value="mod"
                        >Profesional Veterinario</ion-select-option
                      >
                    </ion-select>
                  </ion-item> -->
                  <ion-item>
                    <ion-label>Recordatorio</ion-label>
                    <ion-select
                      multiple="true"
                      cancelText="Cerrar"
                      okText="Aceptar"
                      [value]="recordatorios"
                      formControlName="recordatorios"
                    >
                    <ion-select-option value="quincenal"
                    >Quincenal</ion-select-option
                  >
                  <ion-select-option value="Anual"
                    >Anual</ion-select-option
                  >
                    </ion-select>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasvacunacion"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'visitas-a-veterinario'">
        <form
          (submit)="enviarDatos(tipo, visitasaveterinario)"
          [formGroup]="visitasaveterinario"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Descripción</ion-label>
                    <ion-input
                      formControlName="descripcionvisitasaveterinario"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechavisitasaveterinario"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"
                      >Responsable médico</ion-label
                    >
                    <ion-input
                      formControlName="nombrevisitasaveterinario"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Costo de consulta</ion-label>
                    <ion-input
                      formControlName="preciovisitasaveterinario"
                      type="number"
                    ></ion-input>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notasvisitasaveterinario"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
      <div *ngSwitchCase="'test-geneticos'">
        <form
          (submit)="enviarDatos(tipo, testgeneticos)"
          [formGroup]="testgeneticos"
        >
          <ion-grid style="padding: 0">
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input
                      formControlName="nombretestgeneticos"
                      type="text"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Resultado</ion-label>
                    <ion-select
                      formControlName="resultacdotestgeneticos"
                      interface="popover"
                      placeholder="Seleccione uno"
                    >
                      <ion-select-option value="inde">-/-</ion-select-option>
                      <ion-select-option value="bajo">X/-</ion-select-option>
                      <ion-select-option value="mod">X/X</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Fecha</ion-label>
                    <ion-input
                      formControlName="fechatestgeneticos"
                      type="date"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Notas</ion-label>
                    <ion-input
                      formControlName="notastestgeneticos"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" type="submit">Guardar cambios</ion-button>
        </form>
      </div>
    </div>
  </div>
</ion-content>
