import { Component, Input, OnInit, ɵConsole } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController, ToastController } from "@ionic/angular";
import { FirebaseService } from "src/app/services/firebase/firebase.service";

import dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import * as relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import "dayjs/locale/es";
import { Recordatorio } from "src/app/interfaces/recordatorio";
import { ReminderServiceService } from "src/app/services/reminder-service.service";
dayjs.locale("es");

@Component({
  selector: "app-editar",
  templateUrl: "./editar.component.html",
  styleUrls: ["./editar.component.scss"],
})
export class EditarComponent implements OnInit {
  @Input() tipo: string;
  @Input() datos: any;
  public ready: boolean = false;
  public dataGroup: UntypedFormGroup;
  public cirugias: UntypedFormGroup;
  public alergias: UntypedFormGroup;
  public comida: UntypedFormGroup;
  public estros: UntypedFormGroup;
  public higiene: UntypedFormGroup;
  public incidencias: UntypedFormGroup;
  public limpieza: UntypedFormGroup;
  public mantenimientoperiodico: UntypedFormGroup;
  public medicamentos: UntypedFormGroup;
  public mediciones: UntypedFormGroup;
  public patologiasexistentes: UntypedFormGroup;
  public pdiagnostico: UntypedFormGroup;
  public rnumericos: UntypedFormGroup;
  public testdiagnostico: UntypedFormGroup;
  public vacunacion: UntypedFormGroup;
  public visitasaveterinario: UntypedFormGroup;
  public testgeneticos: UntypedFormGroup;

  public fechaSeleccionada: string;
  private docId: string;

  public recordatorios: [] = [];

  constructor(
    private afs: FirebaseService,
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private toastController: ToastController,

    private recordatorioService: ReminderServiceService
  ) {}

  ngOnInit() {
    this.docId = this.datos.prodid;
    console.log(this.docId);
    switch (this.tipo) {
      case "cirugias":
        this.cirugias = this.formBuilder.group({
          tipocirujua: [this.datos.tipocirujua, Validators.required],
          fechacirugia: [this.datos.fechacirugia, Validators.required],
          nombrecirugia: [this.datos.nombrecirugia, Validators.required],
          preciocirugia: [this.datos.preciocirugia, Validators.required],
          observacionescirugia: [this.datos.observacionescirugia],
          notascirugia: [this.datos.notascirugia],
        });
        this.fechaSeleccionada = this.datos.fechacirugia;
        this.ready = true;
        break;
      case "alergias":
        this.alergias = this.formBuilder.group({
          nombrealergias: [this.datos.nombrealergias, Validators.required],
          fechadiagalergias: [
            this.datos.fechadiagalergias,
            Validators.required,
          ],
          gravedadalergias: [this.datos.gravedadalergias, Validators.required],
          categoriaalergias: [
            this.datos.categoriaalergias,
            Validators.required,
          ],
          accionesrealalergias: [this.datos.accionesrealalergias],
          notasalergias: [this.datos.notasalergias],
        });
        this.fechaSeleccionada = this.datos.fechadiagalergias;
        this.ready = true;
        break;
      case "comida":
        this.comida = this.formBuilder.group({
          nombrecomida: [this.datos.nombrecomida, Validators.required],
          tipocomida: [this.datos.tipocomida, Validators.required],
          notascomida: [this.datos.notascomida],
          fechacomida: [this.datos.fechacomida, Validators.required],
          recordatorios: [],
        });
        this.recordatorios = this.datos.recordatorios;
        this.ready = true;
        break;
      case "estros":
        this.estros = this.formBuilder.group({
          fechainicioestros: [
            this.datos.fechainicioestros,
            Validators.required,
          ],
          fechafinalestros: [this.datos.fechafinalestros, Validators.required],
          notasestros: [this.datos.notasestros],
        });
        this.fechaSeleccionada = this.datos.fechainicioestros;
        this.ready = true;
        break;
      case "higiene":
        this.higiene = this.formBuilder.group({
          tipohigihigiene: [this.datos.tipohigihigiene, Validators.required],
          fechatipohigiene: [this.datos.fechatipohigiene, Validators.required],
          gravedadhigiene: [this.datos.gravedadhigiene, Validators.required],
          notashigiene: [this.datos.notashigiene],
          recordatorios: [],
        });
        this.recordatorios = this.datos.recordatorios;

        this.fechaSeleccionada = this.datos.fechatipohigiene;
        this.ready = true;
        break;
      case "incidencias":
        this.incidencias = this.formBuilder.group({
          descripcionincidencias: [
            this.datos.descripcionincidencias,
            Validators.required,
          ],
          categoriaincidencias: [
            this.datos.categoriaincidencias,
            Validators.required,
          ],
          fechainsidenteincidencias: [
            this.datos.fechainsidenteincidencias,
            Validators.required,
          ],
        });

        this.fechaSeleccionada = this.datos.fechainsidenteincidencias;
        this.ready = true;
        break;
      case "limpieza":
        this.limpieza = this.formBuilder.group({
          tipolimpieza: [this.datos.tipolimpieza, Validators.required],
          fechalimpieza: [this.datos.fechalimpieza, Validators.required],
          gravedadlimpieza: [this.datos.gravedadlimpieza, Validators.required],
          notaslimpieza: [this.datos.notaslimpieza],
        });
        this.ready = true;
        break;
      case "mantenimiento-periodico":
        this.mantenimientoperiodico = this.formBuilder.group({
          tipomantenimientoperiodico: [
            this.datos.tipomantenimientoperiodico,
            Validators.required,
          ],
          fechamantenimientoperiodico: [
            this.datos.fechamantenimientoperiodico,
            Validators.required,
          ],
          gravedadmantenimientoperiodico: [
            this.datos.gravedadmantenimientoperiodico,
            Validators.required,
          ],
          notasmantenimientoperiodico: [this.datos.notasmantenimientoperiodico],
          recordatorios: [],
        });
        this.recordatorios = this.datos.recordatorios;
        this.ready = true;
        break;
      case "medicamentos":
        this.medicamentos = this.formBuilder.group({
          nombremedicamentos: [
            this.datos.nombremedicamentos,
            Validators.required,
          ],
          notasmedicamentos: [this.datos.notasmedicamentos],
          dosismedicamentos: [
            this.datos.dosismedicamentos,
            Validators.required,
          ],
          fechamedicamentos: [
            this.datos.fechamedicamentos,
            Validators.required,
          ],
          higienemedicamentos: [
            this.datos.higienemedicamentos,
            Validators.required,
          ],
          recordatorios: [],
        });
        this.ready = true;
        break;
      case "mediciones":
        this.mediciones = this.formBuilder.group({
          tipomediciones: [this.datos.tipomediciones, Validators.required],
          fechamediciones: [this.datos.fechamediciones, Validators.required],
          valormediciones: [this.datos.valormediciones, Validators.required],
          notasmediciones: [this.datos.notasmediciones],
        });
        this.ready = true;
        break;
      case "patologias-existentes":
        this.patologiasexistentes = this.formBuilder.group({
          nombrepatologiasexistentes: [
            this.datos.nombrepatologiasexistentes,
            Validators.required,
          ],
          fechapatologiasexistentes: [
            this.datos.fechapatologiasexistentes,
            Validators.required,
          ],
          categoriapatologiasexistentes: [
            this.datos.categoriapatologiasexistentes,
            Validators.required,
          ],
          notaspatologiasexistentes: [this.datos.notaspatologiasexistentes],
        });
        this.ready = true;
        break;
      case "pdiagnostico":
        this.pdiagnostico = this.formBuilder.group({
          descripcionpdiagnostico: [
            this.datos.descripcionpdiagnostico,
            Validators.required,
          ],
          fechapdiagnostico: [
            this.datos.fechapdiagnostico,
            Validators.required,
          ],
          notaspdiagnostico: [this.datos.notaspdiagnostico],
        });
        this.ready = true;
        break;
      case "rnumericos":
        this.rnumericos = this.formBuilder.group({
          nombrernumericos: [this.datos.nombrernumericos, Validators.required],
          fecharnumericos: [this.datos.fecharnumericos, Validators.required],
          valorrnumericos: [this.datos.valorrnumericos, Validators.required],
          margenrnumericos: [this.datos.margenrnumericos, Validators.required],
          unidadrnumericos: [this.datos.unidadrnumericos, Validators.required],
        });
        this.ready = true;
        break;
      case "test-diagnostico":
        this.testdiagnostico = this.formBuilder.group({
          nombretestdiagnostico: [
            this.datos.nombretestdiagnostico,
            Validators.required,
          ],
          fechatestdiagnostico: [
            this.datos.fechatestdiagnostico,
            Validators.required,
          ],
          resultadotestdiagnostico: [
            this.datos.resultadotestdiagnostico,
            Validators.required,
          ],
          notastestdiagnostico: [this.datos.notastestdiagnostico],
        });
        this.ready = true;
        break;
      case "vacunacion":
        this.vacunacion = this.formBuilder.group({
          vacunavacunacion: [this.datos.vacunavacunacion, Validators.required],
          fechavacunacion: [this.datos.fechavacunacion, Validators.required],
          notasvacunacion: [this.datos.notasvacunacion],
          recordatorios: [],
        });
        this.recordatorios = this.datos.recordatorios;
        this.ready = true;
        break;
      case "visitas-a-veterinario":
        this.visitasaveterinario = this.formBuilder.group({
          descripcionvisitasaveterinario: [
            this.datos.descripcionvisitasaveterinario,
            Validators.required,
          ],
          fechavisitasaveterinario: [
            this.datos.fechavisitasaveterinario,
            Validators.required,
          ],
          nombrevisitasaveterinario: [
            this.datos.nombrevisitasaveterinario,
            Validators.required,
          ],
          preciovisitasaveterinario: [
            this.datos.preciovisitasaveterinario,
            Validators.required,
          ],
          notasvisitasaveterinario: [this.datos.notasvisitasaveterinario],
        });
        this.ready = true;
        break;
      case "test-geneticos":
        this.testgeneticos = this.formBuilder.group({
          nombretestgeneticos: [
            this.datos.nombretestgeneticos,
            Validators.required,
          ],
          resultacdotestgeneticos: [
            this.datos.resultacdotestgeneticos,
            Validators.required,
          ],
          fechatestgeneticos: [
            this.datos.fechatestgeneticos,
            Validators.required,
          ],
          notastestgeneticos: [this.datos.notastestgeneticos],
        });
        this.ready = true;
        break;
      default:
        break;
    }
  }

  setFecha(f) {
    this.fechaSeleccionada = f.detail.value;
    console.log("FECHA: ", this.fechaSeleccionada);
  }

  crearRecordatorios(
    lista: [],
    titulo: string,
    mascota: string,
    idRegistro: string
  ) {
    const mensaje = "Recordatorio pendiente.";

    console.log("fecha seleccionada ", this.fechaSeleccionada);

    for (let index = 0; index < lista.length; index++) {
      const element = lista[index];
      let date: dayjs.Dayjs;
      switch (element) {
        case "quincenal":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            15,
            "day"
          );
          break;
          case "mensual":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            30,
            "day"
          );
          break;
          case "trimestral":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            80,
            "day"
          );
          break;
          case "semestral":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            170,
            "day"
          );
          break;
        case "anual":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            360,
            "day"
          );
          break;
      }
      console.log("date created: ", date.format("YYYY-MM-DD"));
      const recordatorio = new Recordatorio(
        mensaje,
        titulo,
        date.toDate().valueOf(),
        idRegistro
      );
      recordatorio.idDestino = mascota;
      recordatorio.ruta = "registro-medico";
      const user = JSON.parse(localStorage.getItem("usuario"));
      recordatorio.idReceptor = user.uid;
      this.recordatorioService.saveReminder(recordatorio);
    }
  }

  dismiss(updated: boolean) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      updated: updated,
      coleccion: this.tipo,
    });
  }

  async presentAlertToast(text: string) {
    const toast = await this.toastController.create({
      color: "danger",
      header: "ERROR!",
      position: "top",
      message: text,
      duration: 2000,
    });
    toast.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  async borrarRecordatorios(recordatorios) {
    let reminderIds = [];
    for (let index = 0; index < recordatorios.length; index++) {
      const element: any = recordatorios[index];
      reminderIds.push(element.ms);
      await this.afs.deleteCollection(element.prodid, "RECORDATORIOS");
    }
    console.log("recordatorio mss: ", reminderIds);
    this.recordatorioService.cancelReminders(reminderIds);
  }

  enviarDatos(collection: any, datos: any) {
    const data = datos.value;
    try {
      if (datos.valid) {
        const res = collection.toUpperCase();

        console.log("RES: ", res);

        const recs = datos.value.recordatorios;

        if (res == "ESTROS") {
          let valid = dayjs(datos.value.fechainicioestros, "YYYY-MM-DD").isBefore(
            dayjs(datos.value.fechafinalestros, "YYYY-MM-DD"),
            "day"
          );
          if(!valid) {
            this.presentAlertToast("Verificar fechas.");
            return;
          } 
        }


        console.log("recs", datos.value);
        console.log("registro id", this.docId);
        this.afs
          .updateCollection(this.docId, `CARTILLA-REGISTRO-${res}`, data)
          .then(() => {
            if (
              res == "VACUNACION" ||
              res == "HIGIENE" ||
              res == "MANTENIMIENTO-PERIODICO" ||
              res == "COMIDA"
            ) {
              this.afs
                .getCollectionListDateWhere(
                  "RECORDATORIOS",
                  "idRegistro",
                  "==",
                  this.docId
                )
                .subscribe((response) => {
                  console.log("recordatorios de fb: ", response);
                  this.borrarRecordatorios(response).then(() => {
                    console.log("hmm");
                  });
                });
              if (recs && recs.length > 0) {
                const titulo = "Recordatorio de " + collection;
                this.crearRecordatorios(
                  recs,
                  titulo,
                  this.datos.uidmascota,
                  this.docId
                );
              }
            }
            this.presentToast("Datos actualizados exitosamente.").then(() => {
              this.dismiss(true);
            });
          });
      } else {
        this.presentAlertToast("Falta completar datos.");
      }
    } catch (error) {
      console.error("Error ---> ", error);
    }
  }
}
