export const enciclopedia = [
  {
    label: "",
    icon: "home",
    colletion: "CARTILLA",
    value: "home",
    position: 0,
  },
  {
    label: "Alergias",
    position: 1,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-ALERGIAS",
    value: "alergias",
  },
  {
    label: "Cirugias",
    position: 2,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-CIRUGIAS",
    value: "cirugias",
  },
  {
    label: "Estros",
    position: 3,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-ESTROS",
    value: "estros",
  },
  {
    label: "Comida",
    position: 4,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-COMIDA",
    value: "comida",
  },
  {
    label: "Higiene",
    position: 5,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-HIGIENE",
    value: "higiene",
  },
  {
    label: "Medicamentos",
    position: 6,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-MEDICAMENTOS",
    value: "medicamentos",
  },
  {
    label: "Mediciones",
    position: 7,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-MEDICIONES",
    value: "mediciones",
  },
  {
    label: "Patologias",
    position: 8,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-PATOLOGIAS-EXISTENTES",
    value: "patologias",
  },
  {
    label: "Vacunación",
    position: 9,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-VACUNACION",
    value: "vacunacion",
  },
  {
    label: "Test",
    position: 10,
    icon: "document",
    colletion: "CARTILLA-REGISTRO-TEST-DIAGNOSTICO",
    value: "test",
  },
];
