<ion-row
    class="tarjeta"
    *ngFor="let pet of pets"
    style="margin-bottom: 1.5rem !important"
>
    <ion-col class="no-pad" size="12">
        <ion-grid class="contenido-tarjeta no-pad">
            <ion-row class="borde-img">
                <ion-col class="dog-col ion-text-center" size="12">
                    <img
                        [src]="pet.imagen || '/assets/placeholder-animal.jpg'"
                        alt="Doggo"
                    />
                </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center ion-justify-content-center">
                <ion-col size="12">
                    <ion-grid class="nombre-des">
                        <ion-row class="ion-justify-content-start">
                            <ion-col size="6" class="ion-align-self-start">
                                <h3>{{ pet.nombre || pet.grupo }}</h3>
                            </ion-col>
                            <ion-col size="1" class="ion-align-self-start">
                                <ion-icon
                                    color="primary"
                                    [name]="
                                        pet.genero === 'MACHO'
                                            ? 'male'
                                            : pet.genero != null
                                            ? 'female'
                                            : ''
                                    "
                                ></ion-icon>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12">
                                <p>
                                    {{ pet.detalle }}
                                </p>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-grid class="no-pad">
                        <ion-row>
                            <ion-col size="4">
                                <ion-grid class="no-pad">
                                    <ion-row class="ion-text-center">
                                        <ion-col class="texto-gris" size="12">
                                            <p>ESPECIE</p>
                                        </ion-col>
                                        <ion-col size="12">
                                            <h5>{{ pet.especie }}</h5>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                            <ion-col size="4">
                                <ion-grid class="no-pad">
                                    <ion-row class="ion-text-center">
                                        <ion-col class="texto-gris" size="12">
                                            <p>TAMAÑO</p>
                                        </ion-col>
                                        <ion-col size="12">
                                            <h5>{{ pet.size }}</h5>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                            <ion-col size="4">
                                <ion-grid class="no-pad">
                                    <ion-row class="ion-text-center">
                                        <ion-col class="texto-gris" size="12">
                                            <p>EDAD</p>
                                        </ion-col>
                                        <ion-col size="12">
                                            <h5>
                                                {{
                                                    calcularEdad(
                                                        pet.fechaDeNacimiento ||
                                                            0
                                                    )
                                                }}
                                            </h5>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-col>
    <ion-col class="boton-tarjeta ion-text-center" size="12">
        <ion-button color="secondary" style="border-radius: 0 !important; --border-radius:0" (click)="view(pet)"> Ver más </ion-button>
    </ion-col>
</ion-row>
