// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: "AIzaSyBUyqvV8BGzqsOBG_pY7-5vdpn-73BhnOY",
        authDomain: "petsvetlook.firebaseapp.com",
        databaseURL: "https://petsvetlook.firebaseio.com",
        projectId: "petsvetlook",
        storageBucket: "petsvetlook.appspot.com",
        messagingSenderId: "152641342481",
        appId: "1:152641342481:web:ab932fe8f892ec0835b5b0",
        measurementId: "G-T9TLDWED2Z",
    },
    apidogs: "https://api.petfinder.com/v2/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
