import { FirebaseService } from "./../services/firebase/firebase.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
    NavParams,
    ActionSheetController,
    ModalController,
    ToastController,
    Platform,
} from "@ionic/angular";

import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
    GeolocationOptions,
    Geoposition,
    Geolocation,
} from "@ionic-native/geolocation/ngx";
import { Router } from "@angular/router";
import { UtilService } from "../services/util.service";
import { Dia } from "../interfaces/dia";

import dayjs, { Dayjs } from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import "dayjs/locale/es";
import { HorarioModalComponent } from "../agenda/horario-modal/horario-modal.component";
import { Cedula } from "../interfaces/cedula";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import moment from "moment";
import axios from "axios";
dayjs.locale("es");

declare var google: any;
const formB = {
    estado: false,
    color_marcador: "",
    nombre_negocio: "",
    telefono: "",
    direccion: "",
    sitio_web: "",
    descripcion: "",
    week: "",
    coordenadas: "",
    businessType: "veterinaria",
    emergencia: false,
    calificacion: "",
    nombreResponsable: "",
    paternoResponsable: "",
    maternoResponsable: "",
    responsibleDoctor: "",
    cedula: "",
};

class Alimento {
    public marca: string;
    public nombre: string;
}

@Component({
    selector: "app-business",
    templateUrl: "./business.page.html",
    styleUrls: ["./business.page.scss"],
})
export class BusinessPage implements OnInit {
    [x: string]: any;
    @ViewChild("businessMap") mapElement: ElementRef;
    @ViewChild("googlePlaces", { read: ElementRef }) ionInputElRef: ElementRef;
    @ViewChild("googlePlaces") searchElementRef: ElementRef;
    uid: string;
    dataForm: UntypedFormGroup;
    image: any;
    title: string = "Mis Negocios";
    imgBase64: any;
    information: any;
    options: GeolocationOptions;
    currentPos: Geoposition;
    places: Array<any>;
    public map: any;
    public coordenadas = "";
    myBusness = [];
    stateBussines = false;
    estadoFrom = "guardar";
    myNegocio: any;
    disableVet = false;
    vetLabel = "Veterinarios (0/20)";
    vetLimit = 10;
    vetCount = 0;
    generalLabel = "Generales (0/20)";
    generalLimit = 10;
    generalCount = 0;
    accesoriosLabel = "Para Mascotas (0/10)";
    accesoriosLimit = 10;
    accesoriosCount = 0;
    estilistasLabel = "Para Estilistas (0/15)";
    estilistasLimit = 15;
    estilistaCount = 0;
    proveedorLabel = "Productos (0/30)";
    proveedorLimit = 30;
    proveedorCount = 0;
    alimentoLabel = "Alimento para Macotas (0/20)";
    alimentoLimit = 20;
    alimentoCount = 0;

    public botonCedulaString = "Verificar Cédula";
    public botonCedulaColor = "secondary";
    public searchCedula = false;
    public esCedulaRepetida = false;
    public showCedulaRepetida = false;
    public editando: boolean = true;
    public servicios = [];
    public productos = [];
    public dias = {};
    public colorMarcador = "#09ccde";
    public cameraLoading: boolean = false;

    public currentLatitude: any;
    public currentlongitude: any;
    public selfMarker: any;
    public currentBussiness = [];
    public markers = [];
    public infoWindow: any;
    public content: any;
    public marker: any;
    public geocoder: any;

    public currentBusiness: any;

    public direccion: string = "";

    public toastButtons = [
        {
            text: "¡Contáctanos!",
            role: "info",
            handler: () => this.enviarCorreo(),
        },
        {
            text: "Cerrar",
            role: "cancel",
            handler: () => {},
        },
    ];

    public alertButtons = [
        {
            text: "Cancelar",
            role: "cancel",
        },
        {
            text: "Borrar Negocio",
            role: "confirm",
            handler: () => {
                this.eliminarNegocio(this.currentBusiness);
                this.stateBussines = false;
                this.estadoFrom = "guardar";
            },
        },
    ];

    selectedProducts = [];
    selectedServices = [];
    selectedAlimento = [];
    selectedVetServices = [];
    selectedAccesorios = [];
    selectedEstilistas = [];
    selectedProveedor = [];
    productosProveedor = [];
    productosAccesorios: string[];
    productosEstilista: string[];
    alimentoMascotas: Alimento[];
    alimentoNames = [];
    servicesNormal: string[];
    servicesVet: string[];

    citasMaximas: any;

    isAction = false;
    schedule: any = {};
    weeks = [
        { name: "Lun a Mie", days: ["Lunes", "Martes", "Miercoles"], id: 1 },
        {
            name: "Lun a Jue",
            days: ["Lunes", "Martes", "Miercoles", "Jueves"],
            id: 2,
        },
        {
            name: "Lun a Vie",
            days: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"],
            id: 3,
        },
        {
            name: "Lun a Sáb",
            days: [
                "Lunes",
                "Martes",
                "Miercoles",
                "Jueves",
                "Viernes",
                "Sábado",
            ],
            id: 4,
        },
        {
            name: "Lun a Dom",
            days: [
                "Lunes",
                "Martes",
                "Miercoles",
                "Jueves",
                "Viernes",
                "Sábado",
                "Domingo",
            ],
            id: 5,
        },
        { name: "Sáb a Dom", days: ["Domingo", "Sábado"], id: 6 },
        { name: "Solo Domingos", days: ["Domingo"], id: 7 },
    ];
    selectedWeek: any;
    products;
    services;

    public dataCedula: any = {};
    public esTitutlo = false;
    public detenTodo = false;
    public ubicacion: any;
    public cedulaVerificada = false;
    public showCedulaVerificada = false;
    public showCedulaError = false;
    public tipoNegocio: any;
    today: Dayjs;
    pinImg = "assets/Pinesx3/Main.png";
    public colors = [
        "#09CCDE",
        "#DD4141",
        "#44C64F",
        "#F29933",
        "#7F379D",
        "#DB70B2",
        "#4779DD",
        "#EEEEEE",
        "#2A2A2A",
    ];
    constructor(
        private geolocation: Geolocation,
        private navParams: NavParams,
        private formBuilder: UntypedFormBuilder,
        public actionSheetCtrl: ActionSheetController,
        public camera: Camera,
        private storage: AngularFireStorage,
        private modalController: ModalController,
        public afs: AngularFirestore,
        public fireservice: FirebaseService,
        public navCtrl: Router,
        public util: UtilService,
        private toastController: ToastController,
        public afs2: FirebaseService,
        private platform: Platform
    ) {
        this.uid = this.navParams.data.uid;
        //this.dias = [];
        const dias = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
        ];
        for (let index = 0; index < dias.length; index++) {
            const dia = dias[index];
            const day = new Dia();
            day.id = dia;
            day.dia = dia;
            day.orden = index.toString();
            this.dias[dia] = day;
        }
        this.selectedWeek = this.weeks[0].id;
        this.today = dayjs();
        this.ubicacion = {
            cantidadMaximaCitasHora: 5,
            schedule: {},
            prodid: "0",
        };
    }

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    ngOnInit() {
        this.getBusness();
        this.detailBusness(formB);
        this.getProducts();
        this.getServices();
    }

    ngAfterViewInit() {
        this.createMap();
    }

    enviarCorreo() {
        window.location.href =
            "mailto:contacto@vetlook.com.mx?subject=Verificación de cédula profesional e información de negocio";
        // if (this.platform.is("android") || this.platform.is("ios")) {
        //     this.theInAppBrowser.create(
        //         "mailto:contacto@vetlook.com.mx?subject=Verificación de cédula profesional e información de negocio",
        //         "_system",
        //         "hidden=yes,location=yes"
        //     );
        // } else {
        //     window.open(
        //         "mailto:contacto@vetlook.com.mx?subject=Verificación de cédula profesional e información de negocio",
        //         "_system"
        //     );
        // }
    }

    changeColor(_color) {
        this.colorMarcador = _color.color.hex;
        this.changePinColor(this.colorMarcador);
    }

    changePinColor(_color) {
        var index = this.colors.indexOf(_color.toUpperCase());
        switch (index) {
            case 1:
                this.pinImg = "assets/Pinesx3/Rojo.png";
                break;
            case 2:
                this.pinImg = "assets/Pinesx3/Verde.png";
                break;
            case 3:
                this.pinImg = "assets/Pinesx3/Naranja.png";
                break;
            case 4:
                this.pinImg = "assets/Pinesx3/Morado.png";
                break;
            case 5:
                this.pinImg = "assets/Pinesx3/Rosa.png";
                break;
            case 6:
                this.pinImg = "assets/Pinesx3/Azul.png";
                break;
            case 7:
                this.pinImg = "assets/Pinesx3/Blanco.png";
                break;
            case 8:
                this.pinImg = "assets/Pinesx3/Negro.png";
                break;
            default:
                this.pinImg = "assets/Pinesx3/Main.png";
                break;
        }
    }

    async createMap() {
        var mapCreated = null;
        await this.geolocation.getCurrentPosition().then((resp) => {
            this.currentLatitude = resp.coords.latitude;
            this.currentlongitude = resp.coords.longitude;

            this.geocoder = new google.maps.Geocoder();

            const latLng = new google.maps.LatLng(
                resp.coords.latitude,
                resp.coords.longitude
            );
            const style = [
                {
                    featureType: "water",
                    stylers: [
                        {
                            saturation: 43,
                        },
                        {
                            lightness: -11,
                        },
                        {
                            hue: "#0088ff",
                        },
                    ],
                },
                {
                    featureType: "poi.business",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            hue: "#ff0000",
                        },
                        {
                            saturation: -100,
                        },
                        {
                            lightness: 99,
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [
                        {
                            color: "#808080",
                        },
                        {
                            lightness: 54,
                        },
                    ],
                },
                {
                    featureType: "landscape.man_made",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#ece2d9",
                        },
                    ],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#ccdca1",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#767676",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.stroke",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "landscape.natural",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            visibility: "on",
                        },
                        {
                            color: "#b8cb93",
                        },
                    ],
                },
                {
                    featureType: "poi.park",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi.sports_complex",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi.medical",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
            ];

            const mapOptions = {
                disableDefaultUI: true,
                center: latLng,
                zoom: 13,
                mapTypeId: "roadmap",
                controls: {
                    compass: false,
                },
                setCompassEnabled: true,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                rotateControl: true,
                controller: {
                    clustering: true,
                    rendering: "animated",
                    algorithm: "nonHierarchicalDistanceBasedAlgorithm",
                },
                zoomControl: false,
                clickableIcons: false,
                gestureHandling: "greedy",
                styles: style,
            };

            this.map = new google.maps.Map(
                this.mapElement.nativeElement,
                mapOptions
            );

            mapCreated = this.map;

            this.createAutocomplete(this.map);

            this.addLocationMarker(this.currentLatitude, this.currentlongitude);

            this.coordenadas =
                this.currentLatitude + "," + this.currentlongitude;
        });

        this.map = mapCreated;
    }

    addLocationMarker(latitude: any, longitude: any) {
        var pinUrl = "assets/Pinesx3/Main.png";

        const position = new google.maps.LatLng(latitude, longitude);
        this.marker = new google.maps.Marker({
            draggable: true,
            animation: google.maps.Animation.DROP,
            position: position,
            icon: {
                url: pinUrl, // url
                scaledSize: new google.maps.Size(72, 72), // scaled size
            },
        });

        this.marker.setMap(this.map);

        this.geocodeLatLng(this.marker.position);

        this.marker.addListener("dragend", (e) => {
            this.geocodeLatLng(this.marker.position);
        });

        this.map.panTo(this.marker.position);
    }

    geocodeLatLng(latLng) {
        const inputTest = document.getElementById(
            "googlePlaces"
        ) as HTMLInputElement;

        if (!inputTest) {
            return;
        }

        this.coordenadas = latLng.lat() + "," + latLng.lng();

        this.geocoder.geocode({ location: latLng }).then((response) => {
            if (response.results[0]) {
                inputTest.value = response.results[0].formatted_address;
                this.direccion = response.results[0].formatted_address;
            }
        });
    }

    removeMarkers() {
        this.marker.setMap(null);
        this.marker = null;
    }

    async unToasteGenerico(text: string, color: string, _position) {
        const toast = await this.toastController.create({
            color: color,
            header: "ATENCIÓN!",
            position: _position,
            message: text,
            duration: 5000,
        });
        toast.present();
    }

    async presentHorarioModal() {
        const modal = await this.modalController.create({
            component: HorarioModalComponent,
            cssClass: "horarioModal",
            componentProps: {
                ubicacion: this.ubicacion,
            },
        });
        modal.present();
        const data = await modal.onWillDismiss();
        try {
            this.schedule = data.data.schedule;
            this.citasMaximas = data.data.maximasCitas;
        } catch (error) {}
    }

    verifyLocationStatusOld(item: any) {
        const fecha = this.today;
        const hora = fecha.hour();
        const day = fecha.format("dddd");
        return item.schedule[this.letraCapital(day)]["horas"][hora]["estado"];
    }

    setCurrentBusiness(_business) {
        this.currentBusiness = _business;
    }

    verifyLocationStatus(horarios: any) {
        var today = moment().format("dddd");
        switch (today) {
            case "Monday":
                today = "Lunes";

                break;
            case "Tuesday":
                today = "Martes";

                break;

            case "Wednesday":
                today = "Miercoles";

                break;

            case "Thursday":
                today = "Jueves";
                break;
            case "Friday":
                today = "Viernes";

                break;

            case "Saturday":
                today = "Sabado";

                break;

            case "Sunday":
                today = "Domingo";

                break;

            default:
                break;
        }
        for (var horario of horarios) {
            if (horario.dia != today) {
                continue;
            }

            if (!horario.abierto) {
                continue;
            }

            var de = moment(horario.deHora + horario.deMinutos, "HHmm");
            var a = moment(horario.aHora + horario.aMinutos, "HHmm");

            var ahora = moment();

            if (ahora.isBetween(de, a)) {
                return true;
            }

            if (!horario.extended) {
                return false;
            }

            de = moment(horario.deHoraEx + horario.deMinutosEx, "HHmm");
            a = moment(horario.aHoraEx + horario.aMinutosEx, "HHmm");

            if (ahora.isBetween(de, a)) {
                return true;
            }

            return false;
        }
    }

    eliminarNegocio(id) {
        this.fireservice.deleteCollection(id.prodid, "UBICACIONES");
    }

    getBusness() {
        this.fireservice
            .getCollectionListDateWhere("UBICACIONES", "userId", "==", this.uid)
            .subscribe((response) => {
                this.myBusness = response;
                this.myBusness.map((location) => {
                    if (Array.isArray(location.horarios)) {
                        location.open = this.verifyLocationStatus(
                            location.horarios
                        );
                    } else if (location.schedule) {
                        location.open = this.verifyLocationStatusOld(location);
                    } else {
                        location.open = false;
                    }

                    location.editable = true;
                });
            });
    }

    detailBusness(item) {
        if (typeof item != "undefined") {
            this.myNegocio = item;
            this.image = item.foto;
            this.colorMarcador = item.color_marcador;
            this.changePinColor(this.colorMarcador);
            if (typeof item.productosEstilista != "undefined") {
                var array = [];
                for (var product in item.productosEstilista) {
                    array.push(item.productosEstilista[product]);
                }

                this.selectedEstilistas = array;

                this.estilistasLabel =
                    "Para Estilistas (" +
                    this.selectedEstilistas.length +
                    "/" +
                    this.estilistasLimit.toString() +
                    ")";

                this.estilistaCount =
                    this.estilistaCount + this.selectedEstilistas.length;
            }

            if (typeof item.services != "undefined") {
                var array = [];
                for (var product in item.services) {
                    array.push(item.services[product]);
                }

                this.selectedServices = array;

                this.generalLabel =
                    "Generales (" +
                    this.selectedServices.length +
                    "/" +
                    this.generalLimit.toString() +
                    ")";

                this.generalCount =
                    this.generalCount + this.selectedServices.length;
            }

            if (typeof item.productosAccesorios != "undefined") {
                var array = [];
                for (var product in item.productosAccesorios) {
                    array.push(item.productosAccesorios[product]);

                    this.selectedAccesorios = array;
                }

                this.accesoriosLabel =
                    "Para Mascotas (" +
                    this.selectedAccesorios.length +
                    "/" +
                    this.accesoriosLimit.toString() +
                    ")";

                this.accesoriosCount =
                    this.accesoriosCount + this.selectedAccesorios.length;
            }

            if (typeof item.serviciosVeterinarios != "undefined") {
                var array = [];
                for (var product in item.serviciosVeterinarios) {
                    array.push(item.serviciosVeterinarios[product]);
                }

                this.selectedVetServices = array;

                this.vetLabel =
                    "Veterinarios (" +
                    this.selectedVetServices.length +
                    "/" +
                    this.vetLimit.toString() +
                    ")";

                this.vetCount = this.vetCount + this.selectedVetServices.length;
            }

            if (typeof item.alimentoMascotas != "undefined") {
                var array = [];
                for (var product in item.alimentoMascotas) {
                    array.push(item.alimentoMascotas[product]);
                }

                this.selectedAlimento = array;

                this.alimentoLabel =
                    "Alimento Para Mascotas (" +
                    this.selectedAlimento.length +
                    "/" +
                    this.alimentoLimit.toString() +
                    ")";

                this.alimentoCount =
                    this.alimentoCount + this.selectedAlimento.length;
            }
        } else {
            item = {
                color_marcador: "",
                nombre_negocio: "",
                telefono: "",
                direccion: "",
                sitio_web: "",
                descripcion: "",
                coordenadas: "",
                businessType: "veterinaria",
                calificacion: "",
                responsibleDoctor: "",
                nombreResponsable: "",
                paternoResponsable: "",
                maternoResponsable: "",
                cedula: "",
            };
        }
        this.dataForm = this.formBuilder.group({
            ...item,
        });
    }

    updatedAction() {
        this.isAction = !this.isAction;
    }

    getProducts() {
        this.fireservice
            .getCollectionListM("PRODUCTOS_NEGOCIO")
            .subscribe((data: any) => {
                for (var result of data) {
                    switch (result.nombre) {
                        case "Accesorios Para Mascotas":
                            this.accesoriosLimit = result.limite;
                            this.productosAccesorios = result.productos;
                            break;
                        case "Productos de Proveedor":
                            this.proveedorLimit = result.limite;
                            this.productosProveedor = result.productos;
                            break;
                        case "Accesorios Para Estilistas":
                            this.estilistasLimit = result.productos.length;
                            this.productosEstilista = result.productos;
                            this.productosEstilista.sort(function (a, b) {
                                return a
                                    .toLowerCase()
                                    .localeCompare(b.toLowerCase());
                            });
                            break;
                        case "Alimento para Mascotas":
                            this.alimentoLimit = result.limite;
                            var array = [];
                            for (var key in result.productos) {
                                for (var product of result.productos[key]) {
                                    var obj = {};
                                    obj["marca"] = key;
                                    obj["nombre"] =
                                        product.nombre +
                                        " - " +
                                        product.presentacion +
                                        " | " +
                                        key;
                                    array.push(obj);
                                }
                            }
                            this.alimentoMascotas = array.sort(function (a, b) {
                                return a.marca
                                    .toLowerCase()
                                    .localeCompare(b.marca.toLowerCase());
                            });
                            this.alimentoNames = array;
                            break;

                        default:
                            break;
                    }
                }

                this.estilistasLabel =
                    "Para Estilistas (" +
                    "0" +
                    "/" +
                    this.estilistasLimit.toString() +
                    ")";
                this.alimentoLabel =
                    "Alimento Para Mascotas (" +
                    "0" +
                    "/" +
                    this.alimentoLimit.toString() +
                    ")";
                this.accesoriosLabel =
                    "Veterinarios (" +
                    "0" +
                    "/" +
                    this.accesoriosLimit.toString() +
                    ")";

                this.proveedorLabel =
                    "Productos (" +
                    "0" +
                    "/" +
                    this.proveedorLimit.toString() +
                    ")";
            });
    }
    getServices() {
        this.fireservice
            .getCollectionListM("SERVICIOS_NEGOCIO")
            .subscribe((data: any) => {
                for (var result of data) {
                    if (result.nombre == "generales") {
                        this.generalLimit = result.limite;
                        this.servicesNormal = result.servicios;
                        this.servicesNormal.sort(function (a, b) {
                            return a
                                .toLowerCase()
                                .localeCompare(b.toLowerCase());
                        });
                    } else if (result.nombre == "veterinarios") {
                        this.vetLimit = result.limite;
                        this.servicesVet = result.servicios;
                        this.servicesVet.sort(function (a, b) {
                            return a
                                .toLowerCase()
                                .localeCompare(b.toLowerCase());
                        });
                    }
                }

                this.generalLabel =
                    "Generales (" +
                    "0" +
                    "/" +
                    this.generalLimit.toString() +
                    ")";

                this.vetLabel =
                    "Veterinarios (" +
                    "0" +
                    "/" +
                    this.vetLimit.toString() +
                    ")";
            });
    }
    updatedState(item: any) {
        const { emergencia, prodid } = item;
        const data = {
            emergencia: !emergencia,
        };
        this.fireservice.updateCollection(prodid, "UBICACIONES", data);
    }
    updatedStart(item: any) {
        const { estado, prodid } = item;
        const data = {
            estado: !estado,
        };
        this.fireservice.updateCollection(prodid, "UBICACIONES", data);
    }

    dismissModal() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }

    public presentActionSheet() {
        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            allowEdit: false,
            correctOrientation: true,
        };
        this.actionSheetCtrl
            .create({
                header: "Seleccionar fuente de imagen",
                buttons: [
                    {
                        text: "Desde la Galeria",
                        icon: "images",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.PHOTOLIBRARY;
                            this.takeImage(options);
                        },
                    },
                    {
                        text: "Desde la Cámara",
                        icon: "camera",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.CAMERA;
                            this.takeImage(options);
                        },
                    },
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                ],
            })
            .then((res) => {
                res.present();
            });
    }

    takeImage(option) {
        this.cameraLoading = true;
        this.camera.getPicture(option).then(
            (img) => {
                this.imgBase64 = img;
                this.image = "data:image/jpeg;base64," + img;
                this.cameraLoading = false;
            },
            (err) => {
                console.log(err);
                this.cameraLoading = false;
            }
        );
    }

    async upload(estado, item?) {
        if (estado === "guardar") {
            if (this.imgBase64) {
                var filename = Math.floor(Date.now() / 1000) + ".jpg";
                const storageRef = this.storage.ref("negocios").child(filename);
                await storageRef
                    .putString(this.imgBase64, "base64")
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((url) => {
                            this.cameraLoading = false;
                            this.guardar(url);
                        });
                        return snapshot;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                if (this.image) {
                    this.guardar(this.image);
                } else {
                    const url = "/assets/vetplaceholder.jpg";
                    this.guardar(url);
                }
            }
        } else {
            if (this.imgBase64) {
                var filename = Math.floor(Date.now() / 1000) + ".jpg";
                const storageRef = this.storage.ref("negocios").child(filename);
                await storageRef
                    .putString(this.imgBase64, "base64")
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((url) => {
                            this.cameraLoading = false;
                            this.editarBusiness(item, url);
                        });
                        return snapshot;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                if (this.image) {
                    this.editarBusiness(item, "");
                } else {
                    this.editarBusiness(item, "");
                }
            }
        }
    }

    letraCapital(palabra: string) {
        return palabra.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };

    //verificar que el titulo de la cedula ingresada sea correcta

    async guardar(url: any) {
        const data = this.dataForm.value;
        data.foto = url;
        data.uid = this.uid;
        data.nombre = data.nombre_negocio;
        data.userId = this.uid;
        data.horarios = this.schedule;
        data.cantidadMaximaCitasHora = this.citasMaximas;
        data.estado = true;
        data.coordenadas = this.coordenadas;

        data.nombreResponsable = this.letraCapital(data.nombreResponsable);

        data.serviciosVeterinarios = Object.assign(
            {},
            this.selectedVetServices
        );

        data.services = Object.assign({}, this.selectedServices);

        data.productosAccesorios = Object.assign({}, this.selectedAccesorios);

        data.productosEstilista = Object.assign({}, this.selectedEstilistas);

        data.alimentoMascotas = Object.assign({}, this.selectedAlimento);

        if (data.businessType == "") {
            this.unToasteGenerico(
                "Por favor elija el tipo de negocio",
                "danger",
                "top"
            );
            // this.dataForm.value.name.setFocus();
            // this.inputs.toArray()[1].setFocus();
            return false;
        }

        // //si es estetica u otros
        if (data.businessType !== "veterinaria") {
            this.searchCedula = false;
            if (data.responsibleDoctor == "") {
                this.unToasteGenerico(
                    "Por favor ingrese el nombre del responsable",
                    "danger",
                    "top"
                );
                return false;
            }
        } else {
            this.searchCedula = true;
        }

        data.cedulaVerificada = this.cedulaVerificada;

        if (data.telefono == "") {
            this.unToasteGenerico(
                "Por favor ingrese el teléfono de contacto",
                "danger",
                "top"
            );
            return false;
        }

        var phoneRegex =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (!phoneRegex.test(data.telefono)) {
            this.unToasteGenerico(
                "Por favor verifica que tu número telefónico sea válido",
                "danger",
                "top"
            );
            return false;
        }

        data.direccion = this.direccion;
        data.createdAt = moment(Date.now()).format("YYYYMMDDHHmmss");
        data.updatedAt = moment(Date.now()).format("YYYYMMDDHHmmss");
        data.color_marcador = this.colorMarcador;

        if (this.direccion == "") {
            this.unToasteGenerico(
                "Por favor ingrese la dirección",
                "danger",
                "top"
            );
            return false;
        }

        this.unToasteGenerico("Guardando negocio...", "primary", "bottom");

        this.fireservice.addCollection("UBICACIONES", data);

        this.unToasteGenerico("Negocio Guardado!", "success", "bottom");

        this.stateBussines = false;
        this.selectedVetServices = [];
        this.selectedServices = [];
        this.selectedAccesorios = [];
        this.selectedEstilistas = [];
        this.selectedAlimento = [];
        this.dataForm.reset();
    }

    async botonVerificarCedula() {
        var {
            nombreResponsable,
            maternoResponsable,
            paternoResponsable,
            cedula,
        } = this.dataForm.value;

        this.afs2
            .getCollectionListDateWhere("UBICACIONES", "cedula", "==", cedula)
            .subscribe((result) => {
                if (result.length >= 3) {
                    this.esCedulaRepetida = true;
                    this.showCedulaRepetida = true;
                } else {
                    var payload = {
                        nombre: nombreResponsable,
                        apellidoM: maternoResponsable,
                        cedula: cedula,
                        apellidoP: paternoResponsable,
                    };

                    this.esCedulaRepetida = false;

                    axios
                        .post(
                            "https://api.vetlook.com.mx/verificaCedula",
                            payload
                        )
                        .then((response) => {
                            if (response.data.valida) {
                                this.cedulaVerificada = true;
                                this.showCedulaVerificada = true;
                            } else {
                                this.cedulaVerificada = false;
                                this.showCedulaError = true;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });
    }

    checkCedulaButton() {
        if (this.ubicacion.cedulaVerificada || this.cedulaVerificada) {
            this.botonCedulaString = "Cédula Verificada";
            this.botonCedulaColor = "success";
            return true;
        }
        if (
            this.dataForm.value.nombreResponsable != "" &&
            this.dataForm.value.paternoResponsable != "" &&
            this.dataForm.value.maternoResponsable != "" &&
            this.dataForm.value.cedula != ""
        ) {
            return false;
        } else {
            return true;
        }
    }

    toastDismiss() {
        this.showCedulaVerificada = false;
        this.showCedulaError = false;
        this.showCedulaRepetida = false;
    }

    editarBusiness(item, url) {
        const data = this.dataForm.value;
        if (url != "") {
            data.foto = url;
        }
        data.serviciosVeterinarios = Object.assign(
            {},
            this.selectedVetServices
        );

        data.horarios = this.schedule;

        data.coordenadas = this.coordenadas;

        data.services = Object.assign({}, this.selectedServices);

        data.productosAccesorios = Object.assign({}, this.selectedAccesorios);

        data.productosEstilista = Object.assign({}, this.selectedEstilistas);

        data.alimentoMascotas = Object.assign({}, this.selectedAlimento);

        this.fireservice.updateCollection(item.prodid, "UBICACIONES", data);
        this.stateBussines = false;
        this.selectedVetServices = [];
        this.selectedServices = [];
        this.selectedEstilistas = [];
        this.selectedAccesorios = [];
        this.selectedAlimento = [];
        this.dataForm.reset();
    }

    accesoriosChanged(e) {
        if (this.selectedAccesorios.length > this.accesoriosLimit) {
            while (this.selectedAccesorios.length > this.accesoriosLimit) {
                this.selectedAccesorios.pop();
            }
            this.unToasteGenerico(
                "El limite de accesorios es " + this.accesoriosLimit.toString(),
                "danger",
                "top"
            );
        } else {
            this.selectedAccesorios = e.value;
        }

        this.accesoriosLabel =
            "Para Mascotas (" +
            this.selectedAccesorios.length +
            "/" +
            this.accesoriosLimit.toString() +
            ")";

        this.accesoriosCount = 0;
    }

    accesoriosSelected(e) {
        if (this.accesoriosCount <= this.accesoriosLimit) {
            if (e.isSelected == true) {
                this.accesoriosCount++;
            } else {
                this.accesoriosCount--;
            }
        } else {
            this.unToasteGenerico(
                "El limite de accesorios es " + this.accesoriosLimit.toString(),
                "danger",
                "top"
            );
        }

        this.accesoriosLabel =
            "Para Mascotas (" +
            this.accesoriosCount +
            "/" +
            this.accesoriosLimit.toString() +
            ")";
    }

    colorChanged(e) {
        this.colorMarcador = e.detail.value;
    }
    servicesChanged(e) {
        if (this.selectedServices.length > this.generalLimit) {
            while (this.selectedServices.length > this.generalLimit) {
                this.selectedServices.pop();
            }
            this.unToasteGenerico(
                "El limite de servicios es " + this.generalLimit.toString(),
                "danger",
                "top"
            );
        } else {
            this.selectedServices = e.value;
        }

        this.generalLabel =
            "Generales (" +
            this.selectedServices.length +
            "/" +
            this.generalLimit.toString() +
            ")";

        this.generalCount = 0;
    }

    servicesSelected(e) {
        if (this.generalCount <= this.generalLimit) {
            if (e.isSelected == true) {
                this.generalCount++;
            } else {
                this.generalCount--;
            }
        } else {
            this.unToasteGenerico(
                "El limite de servicios es " + this.generalLimit.toString(),
                "danger",
                "top"
            );
        }

        this.generalLabel =
            "Generales (" +
            this.generalCount +
            "/" +
            this.generalLimit.toString() +
            ")";
    }

    estilistaChanged(e) {
        if (this.selectedEstilistas.length > this.estilistasLimit) {
            while (this.selectedEstilistas.length > this.estilistasLimit) {
                this.selectedEstilistas.pop();
            }
            this.unToasteGenerico(
                "El limite de accesorios es " + this.estilistasLimit.toString(),
                "danger",
                "top"
            );
        } else {
            this.selectedEstilistas = e.value;
        }

        this.estilistasLabel =
            "Para Estilistas (" +
            this.selectedEstilistas.length +
            "/" +
            this.estilistasLimit.toString() +
            ")";
        this.estilistaCount = 0;
    }

    estilistaSelected(e) {
        if (this.estilistaCount <= this.estilistasLimit) {
            if (e.isSelected == true) {
                this.estilistaCount++;
            } else {
                this.estilistaCount--;
            }
        } else {
            this.unToasteGenerico(
                "El limite de accesorios es " + this.estilistasLimit.toString(),
                "danger",
                "top"
            );
        }

        this.estilistasLabel =
            "Para Estilistas (" +
            this.estilistaCount +
            "/" +
            this.estilistasLimit.toString() +
            ")";
    }

    alimentoChanged(e) {
        if (this.selectedAlimento.length > this.alimentoLimit) {
            while (this.selectedAlimento.length > this.alimentoLimit) {
                this.selectedAlimento.pop();
            }
            this.unToasteGenerico(
                "El limite de productos es " + this.alimentoLimit.toString(),
                "danger",
                "top"
            );
        } else {
            this.selectedAlimento = e.value;
        }

        this.alimentoCount = 0;

        this.alimentoLabel =
            "Alimento Para Mascotas (" +
            this.selectedAlimento.length +
            "/" +
            this.alimentoLimit.toString() +
            ")";
    }

    alimentoSelected(e) {
        if (this.alimentoCount <= this.vetLimit) {
            if (e.isSelected == true) {
                this.alimentoCount++;
            } else {
                this.alimentoCount--;
            }
        } else {
            this.unToasteGenerico(
                "El limite de servicios es " + this.alimentoLimit.toString(),
                "danger",
                "top"
            );
        }

        this.alimentoLabel =
            "Alimento Para Mascotas (" +
            this.alimentoCount +
            "/" +
            this.alimentoLimit.toString() +
            ")";
    }

    servicesVetChanged(e) {
        if (this.selectedVetServices.length > this.vetLimit) {
            while (this.selectedVetServices.length > this.vetLimit) {
                this.selectedVetServices.pop();
            }
            this.unToasteGenerico(
                "El limite de servicios es " + this.vetLimit.toString(),
                "danger",
                "top"
            );
        } else {
            this.selectedVetServices = e.value;
        }

        this.vetLabel =
            "Veterinarios (" +
            this.selectedVetServices.length +
            "/" +
            this.vetLimit.toString() +
            ")";
        // this.selectedServices = e.detail.value;
    }

    servicesVetSelected(e) {
        if (this.vetCount <= this.vetLimit) {
            if (e.isSelected == true) {
                this.vetCount++;
            } else {
                this.vetCount--;
            }
        } else {
            this.unToasteGenerico(
                "El limite de servicios es " + this.vetLimit.toString(),
                "danger",
                "top"
            );
        }

        this.vetLabel =
            "Veterinarios (" +
            this.vetCount +
            "/" +
            this.vetLimit.toString() +
            ")";
    }

    getGroupNames(alimento: Alimento, index: number, alimentos: Alimento[]) {
        if (index === 0 || alimento.marca !== alimentos[index - 1].marca) {
            return alimento.marca;
        }
        return null;
    }

    createAutocomplete(_map) {
        var input = this.ionInputElRef.nativeElement.querySelector(
            "input"
        ) as HTMLInputElement;

        const defaultBounds = {
            north: _map.center.toJSON().lat + 0.1,
            south: _map.center.toJSON().lat - 0.1,
            east: _map.center.toJSON().lng + 0.1,
            west: _map.center.toJSON().lng - 0.1,
        };

        const autocomplete = new google.maps.places.Autocomplete(input, {
            types: ["geocode"],
            bounds: defaultBounds,
            componentRestrictions: { country: "mx" },
        });

        google.maps.event.addListener(autocomplete, "place_changed", () => {
            const place = autocomplete.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.removeMarkers();
            this.addLocationMarker(lat, lng);
            const coordenadas = `${lat},${lng}`;
            this.dataForm.get("coordenadas").setValue(coordenadas);
            this.dataForm.get("direccion").setValue(place.formatted_address);
            this.direccion = place.formatted_address;
            this.coordenadas = coordenadas;
        });
    }
}
