import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { Recordatorio } from "../interfaces/recordatorio";

import { FirebaseService } from "./firebase/firebase.service";

@Injectable({
  providedIn: "root",
})
export class ReminderServiceService {
  constructor(
    public localNotifications: LocalNotifications,
    private router: Router,

    private afs: FirebaseService,
  ) { }


  listenToReminders() {
    this.localNotifications.on("click").subscribe((data) => {
      this.router.navigate([data.ruta, data.idDestino]);
    });
  }

  saveReminder(recordatorio: Recordatorio) {
    console.log("fecha recordatorio:", recordatorio.fecha());
    const data = {
      mensaje: recordatorio.mensaje,
      idDestino: recordatorio.idDestino,
      ruta: recordatorio.ruta,
      titulo: recordatorio.titulo,
      fecha: recordatorio.fecha(),
      ms: recordatorio.ms,
      idReceptor: recordatorio.idReceptor,
      idRegistro: recordatorio.idRegistro
    };
    this.afs.addCollection("RECORDATORIOS", data).then(() => {
      this.setReminder(recordatorio);
    })
  }

  cancelReminders(reminders) {

    this.localNotifications.cancel(reminders);

    
  }


  setReminder(recordatorio: Recordatorio) {

    this.localNotifications.getScheduledIds().then(ids => {
      if (ids.filter(id => id == recordatorio.ms).length < 1) { //not created yet
        // Schedule delayed notification
        this.localNotifications.schedule({
          id: recordatorio.ms,
          title: recordatorio.titulo,
          text: recordatorio.mensaje,
          trigger: { at: recordatorio.date() },
          led: "FF0000",
          foreground: true,
          data: {
            idDestino: recordatorio.idDestino,
            ruta: recordatorio.ruta,
          },
        });
      }

    })


  }
}
