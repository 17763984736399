import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
    redirectUnauthorizedTo,
    canActivate,
} from "@angular/fire/compat/auth-guard";
const redirectUnauthorizedToLanding = () =>
    redirectUnauthorizedTo(["add-user"]);

const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: "full" },
    {
        path: "home",
        loadChildren: () =>
            import("./home/home.module").then((m) => m.HomePageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "add-user",
        loadChildren: () =>
            import("./add-user/add-user.module").then(
                (m) => m.AddUserPageModule
            ),
    },
    {
        path: "login/:type",
        loadChildren: () =>
            import("./login/login.module").then((m) => m.LoginPageModule),
    },
    // agregando el registro de usuarios y de negocios a mano

    {
        path: "registro/:type",
        loadChildren: () =>
            import("./registro/registro.module").then(
                (m) => m.RegistroPageModule
            ),
    },
    {
        path: "lost-password",
        loadChildren: () =>
            import("./lost-password/lost-password.module").then(
                (m) => m.LostPasswordPageModule
            ),
    },
    {
        path: "negocios/:negocio",
        loadChildren: () =>
            import("./negocio/negocio.module").then((m) => m.NegocioPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "citas",
        loadChildren: () =>
            import("./citas-usuario/citas-usuario.module").then(
                (m) => m.CitasUsuarioPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "notificaciones",
        loadChildren: () =>
            import("./notificaciones/notificaciones.module").then(
                (m) => m.NotificacionesPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "citas/:cita",
        loadChildren: () =>
            import("./cita/cita.module").then((m) => m.CitaPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    // Fin registro de usuarios y negocios a mano

    {
        path: "enciclopedia",
        loadChildren: () =>
            import(
                "./enciclopedia-categoria/enciclopedia-categoria.module"
            ).then((m) => m.EnciclopediaCategoriaPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "enciclopedia/:grupo",
        loadChildren: () =>
            import("./enciclopedia/especies/especies.module").then(
                (m) => m.EspeciesPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "enciclopedia/:grupo/:especie",
        loadChildren: () =>
            import("./enciclopedia/razas/razas.module").then(
                (m) => m.RazasPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "enciclopedia/:grupo/:especie/:raza",
        loadChildren: () =>
            import("./enciclopedia/raza/raza.module").then(
                (m) => m.RazaPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "enciclopedia-especies-razas/:tipo",
        loadChildren: () =>
            import(
                "./enciclopedia-especies-razas/enciclopedia-especies-razas.module"
            ).then((m) => m.EnciclopediaEspeciesRazasPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "enciclopedia-especies-contenido/:raza",
        loadChildren: () =>
            import(
                "./enciclopedia-especies-contenido/enciclopedia-especies-contenido.module"
            ).then((m) => m.EnciclopediaEspeciesContenidoPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "redsocial",
        loadChildren: () =>
            import("./redsocial/redsocial.module").then(
                (m) => m.RedsocialPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "membresias",
        loadChildren: () =>
            import("./membresias/membresias.module").then(
                (m) => m.MembresiasPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "add-mascota",
        loadChildren: () =>
            import("./add-mascota/add-mascota.module").then(
                (m) => m.AddMascotaPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "lista/:tipo",
        loadChildren: () =>
            import("./lista/lista.module").then((m) => m.ListaPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "adoptar/:id",
        loadChildren: () =>
            import("./adotar/adotar.module").then((m) => m.AdotarPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "solicitud-adopcion/:id/:nombre/:imagen",
        loadChildren: () =>
            import("./solicitud-adopcion/solicitud-adopcion.module").then(
                (m) => m.SolicitudAdopcionPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "mis-solicitudes",
        loadChildren: () =>
            import("./mis-solicitudes/mis-solicitudes.module").then(
                (m) => m.MisSolicitudesPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "pets-perdidas/:id",
        loadChildren: () =>
            import("./pets-perdidas/pets-perdidas.module").then(
                (m) => m.PetsPerdidasPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "negocio-detalle",
        loadChildren: () =>
            import("./negocio-detalle/negocio-detalle.module").then(
                (m) => m.NegocioDetallePageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "registro-medico",
        loadChildren: () =>
            import("./registro-medico/registro-medico.module").then(
                (m) => m.RegistroMedicoPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },

    {
        path: "registro-medico-modal",
        loadChildren: () =>
            import("./registro-medico-modal/registro-medico-modal.module").then(
                (m) => m.RegistroMedicoModalPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "view-lost/:id",
        loadChildren: () =>
            import("./view-lost/view-lost.module").then(
                (m) => m.ViewLostPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "perfil",
        loadChildren: () =>
            import("./perfil/perfil.module").then((m) => m.PerfilPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "mascotas-perdidas",
        loadChildren: () =>
            import("./mascotas-perdidas/mascotas-perdidas.module").then(
                (m) => m.MascotasPerdidasPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "business",
        loadChildren: () =>
            import("./business/business.module").then(
                (m) => m.BusinessPageModule
            ),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "profile-pet/:id/:origin",
        loadChildren: () =>
            import("./profile-pet/profile-pet.module").then(
                (m) => m.ProfilePetPageModule
            ),
    },
    {
        path: "agenda",
        loadChildren: () =>
            import("./agenda/agenda.module").then((m) => m.AgendaPageModule),
        ...canActivate(redirectUnauthorizedToLanding),
    },
    {
        path: "tutoriales",
        loadChildren: () =>
            import("./tutoriales/tutoriales.module").then(
                (m) => m.TutorialesPageModule
            ),
    },
    {
        path: "legal",
        loadChildren: () =>
            import("./legal/legal.module").then((m) => m.LegalPageModule),
    },
    {
        path: "seleccionar-mascota",
        loadChildren: () =>
            import("./seleccionar-mascota/seleccionar-mascota.module").then(
                (m) => m.SeleccionarMascotaPageModule
            ),
    },
    {
        path: "seleccionar-horario",
        loadChildren: () =>
            import(
                "./cita/seleccionar-horario/seleccionar-horario.module"
            ).then((m) => m.SeleccionarHorarioPageModule),
    },
    { path: "**", redirectTo: "home" },

    {
        path: "publicidad",
        loadChildren: () =>
            import("./publicidad/publicidad.module").then(
                (m) => m.PublicidadPageModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
