<ion-searchbar
  class="searchbar"
  placeholder="Buscar por cualquier criterio"
  cancelbuttontext="Custom Cancel"
  type="text"
  mode="ios"
  debounce="500"
  color="light"
  (ionInput)="getItems($event)"
></ion-searchbar>

<ion-list class="searchbar-list ion-padding" *ngIf="isSearch">
  <!-- <ion-item
    *ngFor="let item of searchData; let i = index"
    (click)="view(item)"
    >{{ item.nombre }}</ion-item
  > -->
  <h3>Mascotas</h3>
  <app-item-pets [pets]="searchData" (onclick)="view($event)"></app-item-pets>
</ion-list>
