import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import {
    LoadingController,
    AlertController,
    ToastController,
} from "@ionic/angular";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import { ActionSheetController } from "@ionic/angular";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";

declare var window;

@Injectable({
    providedIn: "root",
})
export class UtilService {
    public image: string;
    public fstorage: any;
    public httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        public actionSheetCtrl: ActionSheetController,
        public camera: Camera,
        public httpClient: HttpClient,
        public toastCtrl: ToastController
    ) {
        this.image = "";
    }

    async loadingPresent(msj: string) {
        return await this.loadingCtrl
            .create({
                message: msj,
            })
            .then((res) => {
                res.present().then(() => console.log("Loading present!"));
                res.onDidDismiss().then(() => {
                    console.log("Loading dismissed!");
                });
            });
    }
    async loadingDismiss() {
        return await this.loadingCtrl.dismiss();
    }

    /**
     * Ventana de alert default, confirmar
     * Botones : ['Cancelar', 'Aceptar']
     */
    async presentToast(caso?: string, text?: string) {
        const toast = await this.toastCtrl.create({
            message: `${text}`,
            duration: 2500,
            color: ``,
        });
        switch (caso) {
            case "EXITO":
                toast.color = "success";
                break;
            case "ERROR":
                toast.color = "danger";
                break;
            case "WARNING":
                toast.color = "tertiary";
                break;
            case "PETICION":
                toast.color = "primary";
                break;
        }
        toast.present();
    }
    async alert(title: string, msj: string, subTitle?: string, $event?: any) {
        return await this.alertCtrl
            .create({
                header: title,
                subHeader: subTitle,
                message: msj,
                buttons: [
                    {
                        text: "Cancelar",
                        handler: () => {
                            this.alertDismiss();
                        },
                    },
                    {
                        text: "Aceptar",
                        handler: () => {
                            if ($event) {
                                $event();
                            }
                        },
                    },
                ],
            })
            .then((res) => res.present());
    }
    /**
     * Ventana de alert INPUT
     * Botones : ['Cancelar' , 'Enviar']
     */
    async alertInput(
        title: string,
        msj: string,
        placeholderInput?: string,
        subTitle?: string,
        $event?: any
    ) {
        return await this.alertCtrl
            .create({
                header: title,
                subHeader: subTitle,
                message: msj,
                inputs: [
                    {
                        name: "value",
                        placeholder: placeholderInput,
                        type: "text",
                    },
                ],
                buttons: [
                    {
                        text: "Cancelar",
                        role: "cancel",
                        handler: () => {
                            this.alertDismiss();
                        },
                    },
                    {
                        text: "Guardar",
                        handler: (data) => {
                            if (data) {
                                $event(data.value);
                            } else {
                                const msjError = "No hay dato ingresado";
                                this.alertInput(title, msj, msjError, $event);
                            }
                        },
                    },
                ],
            })
            .then((res) => res.present());
    }

    async alertDismiss() {
        return await this.alertCtrl.dismiss();
    }
    public presentActionSheet() {
        this.image = "";
        const options: CameraOptions = {
            quality: 100,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
        };
        this.actionSheetCtrl
            .create({
                header: "Select Image Source",
                buttons: [
                    {
                        text: "Load from Library",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.PHOTOLIBRARY;
                            this.takeImage(options);
                        },
                    },
                    {
                        text: "Use Camera",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.CAMERA;
                            return this.takeImage(options);
                        },
                    },
                    {
                        text: "Cancel",
                        role: "cancel",
                    },
                ],
            })
            .then((res) => res.present());
    }
    takeImage(option: any) {
        this.camera.getPicture(option).then((img) => {
            this.image = window.Ionic.WebView.convertFileSrc(img);
        });
    }
    getImgFStorage(path: any) {
        return (this.fstorage = this.httpClient.get(
            `https://firebasestorage.googleapis.com/v0/b/petsvetlook.appspot.com/o/${path}`
        ));
    }
    public sendGet(endpoint: any) {
        return this.httpClient.get(`${endpoint}`, this.httpOptions);
    }

    //hacer post a lo de la cedulas

    public postApiCedula(endpoint, body) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods":
                    "GET, POST, OPTIONS, PUT, DELETE",
                "Access-Control-Allow-Headers":
                    "Origin, Accept, Access-Control-Allow-Origin",
            }),
        };
        return this.httpClient.post(`${endpoint}`, body, httpOptions);
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    /**
     * getAccesToken
     */
    public getAccesToken() {
        const apiKey = {
            grant_type: "client_credentials",
            client_id: "BuQyyuXDik5t2pIjuW0LMyJa9UaeOfx66bsejUzashSZauEukX",
            client_secret: "dZoQUT5vOlJvFMrPHzyu9zGIx5qJcaHIZHyAU6sR",
        };
        return this.httpClient
            .post(
                environment.apidogs + "/oauth2/token",
                apiKey,
                this.httpOptions
            )
            .pipe(retry(1), catchError(this.errorHandl));
    }
    public postApi(endpoint, key, body) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + key,
            }),
        };
        return this.httpClient.post(
            `${environment.apidogs}`,
            body,
            httpOptions
        );
    }
    public getPets(endpoint: any, key) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + key,
            }),
        };
        return this.httpClient.get(
            `${environment.apidogs}/${endpoint}`,
            httpOptions
        );
    }
}
