import { Component, OnInit, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-sub-menu",
  templateUrl: "./sub-menu.component.html",
  styleUrls: ["./sub-menu.component.scss"]
})
export class SubMenuComponent implements OnInit {
  constructor(public popoverController: PopoverController) {}

  ngOnInit() {}

  onclick(valor: any) {
    this.popoverController.dismiss(valor);
  }
}
