import dayjs, { Dayjs } from "dayjs";
import * as relativeTime from 'dayjs/plugin/relativeTime';
export class Cita {
  prodid: string;
  hora: string;
  dia: string;
  fecha: string;
  idUbicacion: string;
  idUsuario: string;
  idMascota: string;
  motivo: string;
  comentarios: string;
  email: string;
  telefono: string;
  nombre: string;
  status: string;
  ms: number;

  mascota: any;
  ubicacion:any;
  day: Dayjs;

  statusColor() {
    if (this.status === "Aceptada") {
      return "success";
    } else if (this.status === "Cancelada") {
      return "danger";
    } else {
      return "warning";
    }
  }

  setMs(ms: number){
    this.ms = ms;
    this.day = dayjs(new Date(this.ms));
  }

  fechaLarga() {
    const f = new Date(this.ms);
    return dayjs(f).format("DD MMM, YYYY");
  }
  fechaRelativa(){
    return dayjs().to(this.day);
  }
}
