import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ProfilePetPageRoutingModule } from "./profile-pet-routing.module";

import { ProfilePetPage } from "./profile-pet.page";
import { EditarComponent } from "../registro-medico/editar/editar.component";
import { ComponentsModule } from "../components/index.module";
import { EditarRegistroSubMenuComponent } from "../components/editar-registro-sub-menu/editar-registro-sub-menu.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ProfilePetPageRoutingModule,
        ReactiveFormsModule,
        ComponentsModule
    ],
    declarations: [ProfilePetPage, EditarComponent,],
    exports: [EditarComponent]
})
export class ProfilePetPageModule {}
