<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon color="primary" slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="mascota"> Cita con {{ mascota.nombre }} </ion-title>
  </ion-toolbar>
</ion-header>
<div class="spin-center">
  <ion-spinner *ngIf="!mascota"></ion-spinner>
</div>
<ion-content fullscreen class="ion-padding" *ngIf="mascota">
  <ion-card>
    <ion-card-content *ngIf="mascota">
      <ion-label class="wrap-text">
        Tienes una cita con {{ cita.nombre }}
      </ion-label>
      <ion-item>
        <ion-label>
          {{ cita.dia }} , {{ cita.day.format("DD MMMM") }}
        </ion-label>
        <ion-icon name="calendar" size="large" slot="end"></ion-icon>
      </ion-item>
      <ion-item>
        <ion-label>
          {{ cita.hora }}
        </ion-label>
        <ion-icon name="alarm" size="large" slot="end"></ion-icon>
      </ion-item>
      <ion-item>
        <ion-label>
          {{ mascota.nombre }}
        </ion-label>
        <i class="mascotas-vetlook" slot="end"></i>
      </ion-item>
      <ion-item>
        <ion-label> Estado </ion-label>
        <ion-chip disabled="true" [color]="cita.statusColor()" slot="end">
          <ion-label>{{ cita.status }}</ion-label>
        </ion-chip>
      </ion-item>
      <ion-list lines="none">
        <ion-item>
          <ion-label>Motivo de la cita </ion-label>
        </ion-item>
        <div *ngIf="cita.motivo">
          <p class="motivo">
            {{ cita.motivo }}
          </p>
        </div>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <ion-toolbar>
    <ion-buttons slot="end" *ngIf="cita.status == 'Solicitada'">
      <ion-button color="success" fill="outline" (click)="aceptarCita(cita)">
        Aceptar cita
      </ion-button>
    </ion-buttons>
    <!-- <ion-buttons slot="end" *ngIf="cita.status == 'Aprobada'">
      <ion-button fill="outline" (click)="closeModal()"> Cerrar </ion-button>
    </ion-buttons> -->

    <ion-buttons slot="start" *ngIf="cita.status !='Cancelada'">
      <ion-button color="danger" fill="outline" (click)="presentAlertConfirm()">
        Cancelar cita
      </ion-button>
    </ion-buttons>
    <ion-title> </ion-title>
  </ion-toolbar>
</ion-content>
