import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavParams, AlertController } from "@ionic/angular";
import { FirebaseService } from "../services/firebase/firebase.service";
import { AppserviceService } from "./../services/appservice.service";
import { CallNumber } from "@ionic-native/call-number/ngx";

declare var google: any;

@Component({
    selector: "app-negocio-detalle",
    templateUrl: "./negocio-detalle.page.html",
    styleUrls: ["./negocio-detalle.page.scss"],
})
export class NegocioDetallePage implements OnDestroy, OnInit {
    negocio;
    resenas: any[] = [];
    rate = 0;
    isResena = false;
    public usuario: any;
    public resultado = 0;
    constructor(
        public modalController: ModalController,
        private navParams: NavParams,
        private afs: FirebaseService,
        private storageService: AppserviceService,
        private alertCtrl: AlertController,
        private callNumber: CallNumber
    ) {}

    ngOnInit() {
        this.negocio = this.navParams.data;
        console.log("Negocio ---> ", this.negocio);
        this.usuario = JSON.parse(localStorage.getItem("usuario"));
        this.cargarResenas();
        this.cargarCalificacion();
    }
    dismissModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            dismissed: true,
        });
    }
    borrar(item) {
        this.afs.deleteCollection(item.prodid, "RESENAS");
    }
    presentToastWithOptions() {
        this.callNumber
            .callNumber(this.negocio.telefono, true)
            .then((res) => console.log("Launched dialer!", res))
            .catch((err) => console.log("Error launching dialer", err));
    }
    async presentAlertPrompt() {
        const alert = await this.alertCtrl.create({
            header: "SOLICITAR CITA A" + this.negocio.nombre_negocio,
            inputs: [
                {
                    name: "nombre",
                    type: "text",
                    placeholder: "Escribe tu nombre",
                },
                {
                    name: "telefono",
                    type: "text",
                    id: "name2-id",
                    placeholder: "Escribe tu telefono",
                },

                // input date with min & max
                {
                    name: "fecha",
                    type: "date",
                    min: "2020-03-01",
                    max: "2021-01-12",
                },

                {
                    name: "mensaje",
                    type: "text",
                    id: "name3-id",
                    placeholder: "Motivo de la consulta",
                },
            ],
            buttons: [
                {
                    text: "Cancelar",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => {
                        console.log("Confirm Cancel");
                    },
                },
                {
                    text: "Reservar",
                    handler: (data) => {
                        this.enviarDatos(data, "CITAS", "cita");
                        console.log("Confirm Ok", data);
                    },
                },
            ],
        });

        await alert.present();
    }
    async confirmarBorrar(item) {
        const aler = await this.alertCtrl
            .create({
                header: "Vetlook",
                subHeader: "",
                message: "Seguro que desea borrar el comentario",
                buttons: [
                    {
                        text: "Cancelar",
                        role: "cancel",
                        handler: () => {
                            console.log("Alert dismiss");
                        },
                    },
                    {
                        text: "Borrar",
                        handler: () => {
                            this.borrar(item);
                        },
                    },
                ],
            })
            .then((res) => res.present());
    }

    cargarResenas() {
        let data: any;
        this.afs
            .getCollectionListDateWhere(
                "RESENAS",
                "uidNegocio",
                "==",
                this.negocio.uid
            )
            .subscribe((result) => {
                data = result;
                console.log("Reseñas", result);
                this.resenas = data;
            });
    }

    cargarCalificacion() {
        // this.negocio.uid
        this.afs
            .getCollectionListDateWhere(
                "CALIFICACIONES",
                "idnegocio",
                "==",
                this.negocio.uid
            )
            .subscribe((data) => {
                console.log("CALIFICACIONES", data);
                let suma = 0;
                data.map((element: any) => {
                    suma += element.puntaje;
                    return suma;
                });
                this.resultado = isNaN(suma / data.length)
                    ? 0
                    : suma / data.length;
                this.rate = this.resultado;
            });
    }

    async escribirResena() {
        return await this.alertCtrl
            .create({
                subHeader: "Escribe tu experiencia en este lugar",
                inputs: [
                    {
                        name: "value",
                        placeholder: "Escribe tu Reseña",
                        type: "text",
                    },
                ],
                buttons: [
                    {
                        text: "Cancelar",
                        role: "cancel",
                        handler: () => {},
                    },
                    {
                        text: "Guardar",
                        handler: (data) => {
                            if (data) {
                                this.enviarDatos(
                                    data.value,
                                    "RESENAS",
                                    "resena"
                                );
                                console.log("Reseña -->", data);
                                this.isResena = true;
                                this.storageService.single_notification(
                                    "Gracias por escribir la reseña"
                                );
                                // $event(data.value);
                            } else {
                                const msjError = "No hay dato ingresado";
                                this.storageService.single_notification(
                                    "Hola, No escribiste tu reseña"
                                );
                            }
                        },
                    },
                ],
            })
            .then((res) => res.present());
    }
    onModelChange(puntuacion) {
        const califi = localStorage.getItem("calificacion")
            ? JSON.parse(localStorage.getItem("calificacion"))
            : null;
        const calData = {
            puntaje: puntuacion,
            iduser: this.usuario.uid,
            createdAt: new Date().toISOString().slice(0, 10),
            estado: true,
            idnegocio: this.negocio.uid,
        };
        const validRes = califi
            ? califi.createdAt === new Date().toISOString().slice(0, 10) &&
              califi.idnegocio === this.negocio.uid
            : false;
        if (!validRes) {
            this.afs.addCollection("CALIFICACIONES", calData);
            this.storageService.single_notification(
                `${this.usuario.name}, Gracias por tu Calificación.  Vetlook`
            );
            localStorage.setItem("calificacion", JSON.stringify(calData));
        } else {
            this.storageService.single_notification(
                `${this.usuario.name}, Ya has calificado este negocio.  Vetlook`
            );
        }
    }
    enviarDatos(data, collection, tipo) {
        try {
            const result = this.usuario;
            let resena: any;
            if (result != null) {
                if (tipo === "resena") {
                    resena = {
                        usuario: result.displayName
                            ? result.displayName
                            : result.email,
                        uidUser: result.uid,
                        createdAt: new Date(),
                        uidNegocio: this.negocio.uid,
                        mensaje: data,
                        foto: result.photoURL,
                        calificacion: 0,
                    };
                } else {
                    const reserva = data;
                    reserva.email = result.email;
                    reserva.uid = result.uid;
                    reserva.createdAt = new Date();
                    reserva.uidNegocio = this.negocio.uid;
                    resena = reserva;
                }
                console.log("user", resena);
                this.afs.addCollection(collection, resena);
                setTimeout(() => {
                    this.cargarResenas();
                }, 1000);
            } else {
                console.log("No hay usuario");
            }
            // });
        } catch (error) {
            console.error("Error ---> ", error);
        }
    }
    ngOnDestroy() {
        console.log("termino");
        if (!this.isResena) {
            const userDataGet = JSON.parse(localStorage.getItem("usuario"));
            // tslint:disable-next-line: max-line-length
            this.storageService.single_notification(
                `Hola, ${userDataGet.fullName}, tu comentario es importante para nostros puedes calificar el negocio en cualquier momento`
            );
        }
    }
}
