import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SearchbarComponent } from "./searchbar/searchbar.component";
import { ItemPetsComponent } from "./item-pets/item-pets.component";
import { EditarRegistroSubMenuComponent } from "./editar-registro-sub-menu/editar-registro-sub-menu.component";

@NgModule({
  declarations: [SearchbarComponent, ItemPetsComponent, EditarRegistroSubMenuComponent],
  imports: [CommonModule, FormsModule, IonicModule],
  exports: [SearchbarComponent, ItemPetsComponent, EditarRegistroSubMenuComponent],
})
export class ComponentsModule {}
