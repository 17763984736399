import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicRatingModule } from "ionic4-rating";
import { IonicModule } from "@ionic/angular";

import { NegocioDetallePageRoutingModule } from "./negocio-detalle-routing.module";

import { NegocioDetallePage } from "./negocio-detalle.page";
import { Routes, RouterModule } from "@angular/router";
const routes: Routes = [
  {
    path: "",
    component: NegocioDetallePage
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicRatingModule,
    RouterModule.forChild(routes),
    IonicModule,
    NegocioDetallePageRoutingModule
  ],
  declarations: [NegocioDetallePage]
})
export class NegocioDetallePageModule {}
