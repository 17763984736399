import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FirebaseService } from "../services/firebase/firebase.service";
import { enciclopedia } from "./constant";
import { DatoService } from "../services/dato.service";
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { CallNumber } from "@ionic-native/call-number/ngx";
import dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import * as relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import "dayjs/locale/es";
dayjs.locale("es");
import * as duration from "dayjs/plugin/duration";
import { EditarComponent } from "../registro-medico/editar/editar.component";
import { EditarRegistroSubMenuComponent } from "../components/editar-registro-sub-menu/editar-registro-sub-menu.component";
dayjs.extend(duration);

@Component({
  selector: "app-profile-pet",
  templateUrl: "./profile-pet.page.html",
  styleUrls: ["./profile-pet.page.scss"],
})
export class ProfilePetPage implements OnInit {
  data: any;
  option = "";
  enciclopedia = enciclopedia;
  ALERGIAS: any[] = [];
  CIRUGIAS: any[] = [];
  ESTROS: any[] = [];
  COMIDA: any[] = [];
  HIGIENE: any[] = [];
  MEDICAMENTOS: any[] = [];
  MEDICIONES: any[] = [];
  PATOLOGIAS: any[] = [];
  VACUNACION: any[] = [];
  DESPARASITACIONES: any[] = [];
  CONSULTAS: any[] = [];
  ANALISIS: any[] = [];
  TEST: any[] = [];
  imagen: string;
  isAdopcion: boolean;
  origin: string;
  public fActual: dayjs.Dayjs;
  public itemsCategoria: any;
  constructor(
    private route: ActivatedRoute,
    private afs: FirebaseService,
    private datoService: DatoService,
    private rout: Router,
    private alertCtrl: AlertController,
    public toastController: ToastController,
    private callNumber: CallNumber,
    private modalController: ModalController,
    public popoverController: PopoverController,
  ) {
    this.identificarParam();
    this.fActual = dayjs();
  }

  async presentPopover(e: any, item: any, tipo: string) {
    const popover = await this.popoverController.create({
      component: EditarRegistroSubMenuComponent,
      event: e,
      mode: "ios",
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    switch (data) {
      case "eliminar":
        this.eliminarRegistro(item, tipo);
        break;
      case "editar":
        this.editarRegistro(item, tipo);
        break;
    }
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.orden || 100000;
    const bandB = b.orden || 100000;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  getItemsCartilla() {
    this.afs
      .getCollectionListDateWhere("ITEMS-CARTILLA", "estado", "==", true)
      .subscribe((items) => {
        this.itemsCategoria = items;
        this.itemsCategoria = this.itemsCategoria.sort(this.compare);
        console.log("items: ", items);
      });
  }

  async editarRegistro(data: any, tipo: string) {
    const modal = await this.modalController.create({
      component: EditarComponent,
      componentProps: {
        datos: data,
        tipo: tipo,
      },
    });
    modal.present();
    const datos: any = await modal.onWillDismiss();
    console.log(datos);
    if (datos.updated) {
      //this.updatedData(datos.coleccion);
    }
  }

  async eliminarRegistro(data: any, collection: string) {
    const alert = await this.alertCtrl.create({
      header: "Borrar registro",
      message: "Por favor confirme que desea borrar el registro.",
      buttons: [
        {
          text: "Cancelal",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah", data);
          },
        },
        {
          text: "Borrar",
          handler: () => {
            console.log("Confirm Okay");
            this.afs
              .deleteCollection(
                data.prodid,
                `CARTILLA-REGISTRO-${collection.toUpperCase()}`
              )
              .then(() => {
                this.presentToast("Registro borrado exitosamente.")
              });
          },
        },
      ],
    });

    await alert.present();
  }

  fecha(fecha: string) {
    return dayjs(fecha, "YYYY-MM-DD").format("LL");
  }

  ngOnInit() {
    this.getItemsCartilla();
  }

  letraCapital(palabra: string) {
    return palabra.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  addRegisterHelt(item: any, seccion: string, icono: string) {
    const data = {
      seccion: seccion,
      icono: icono,
      mascotaId: item.prodid,
      mostrarModal: true,
    };
    this.datoService.setDato(data);
    this.rout.navigate(["/registro-medico"]);
  }

  editar(item: any) {
    this.datoService.setDato(item);
    this.rout.navigate(["/add-mascota"]);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  async presentToastWithOptions() {
    // const toast = await this.toastController.create({
    //   header: "Deseas Contactar al que lo encontro ",
    //   message: "",
    //   position: "top",
    //   buttons: [
    //     {
    //       side: "end",
    //       icon: "call",
    //       text: " Llamar Ahora",
    //       handler: () => {

    //           .then((res) => console.log("Launched dialer!", res))
    //           .catch((err) => console.log("Error launching dialer", err));
    //       },
    //     },
    //   ],
    // });
    // toast.present();
    this.callNumber.callNumber(this.data.telefono, true);
  }
  async confirmarBorrar(item: any) {
    const aler = await this.alertCtrl
      .create({
        header: "Vetlook",
        subHeader: "",
        message: "Seguro que desea borrar a " + item.nombre,
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            handler: () => {
              console.log("Alert dismiss");
            },
          },
          {
            text: "Aceptar",
            handler: () => {
              this.borrar(item);
            },
          },
        ],
      })
      .then((res) => res.present());
  }
  borrar(item: any) {
    this.afs.deleteCollection(item.prodid, "MASCOTAS");
    this.rout.navigate(["/lista", "MASCOTAS"]);
  }

  adotar(item: any) {
    this.rout.navigate(["/adoptar", item.prodid]);
  }
  updatedAdopcion(item: any) {
    if (item) {
      const e = !item.adopcion.estado;
      const data = item;
      data.adopcion.estado = e;
      data.adopcion.createdAt = new Date();
      this.afs.updateCollection(item.prodid, "MASCOTAS", data);
    }
  }

  calcularEdad(item: any) {
    let years = "";
    let months = "";
    var duration = dayjs.duration(this.fActual.diff(dayjs(item)));
    if (duration.years() > 0) {
      if (duration.years() == 1) {
        years = duration.years() + " año,";
      } else {
        years = duration.humanize() + ",";
      }
    }
    months = duration.months() + " meses";
    return years + " " + months;
  }

  identificarParam() {
    const id = this.route.snapshot.params.id;
    this.origin = this.route.snapshot.params.origin;
    this.loadData(id);
  }
  loadData(id: any) {
    this.afs.getCollectionDetail(id, "MASCOTAS").subscribe((res) => {
      if (res) {
        this.data = res;
        this.isAdopcion = this.data.adopcion.estado;
       // this.datoService.setDato(this.data);
        this.imagen = this.data.imagen || "/assets/placeholder-animal.jpg";
        this.loadDocument();
      }
    });
  }

  updatedData(collection: string) {
    this.afs
      .getCollectionListDateWhere(
        `CARTILLA-REGISTRO-${collection.toUpperCase()}`,
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        switch (collection) {
          case "cirugias":
            this.CIRUGIAS = result;
            break;
          case "alergias":
            this.ALERGIAS = result;
            break;
          case "comida":
            this.COMIDA = result;
            break;
          case "estros":
            this.ESTROS = result;
            break;
          case "higiene":
            this.HIGIENE = result;
            break;
          case "incidencias":
            break;
          case "limpieza":
            break;
          case "mantenimiento-periodico":
            this.DESPARASITACIONES = result;
            break;
          case "medicamentos":
            this.MEDICAMENTOS = result;
            break;
          case "mediciones":
            this.MEDICIONES = result;
            break;
          case "patologias-existentes":
            this.PATOLOGIAS = result;
            break;
          case "pdiagnostico":
            this.ANALISIS = result;
            break;
          case "rnumericos":
            break;
          case "test-diagnostico":
            this.TEST = result;
            break;
          case "vacunacion":
            this.VACUNACION = result;
            break;
          case "visitas-a-veterinario":
            this.CONSULTAS = result;
            break;
          case "test-geneticos":
            break;
          default:
            break;
        }
      });
  }

  loadDocument() {
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-ALERGIAS",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.ALERGIAS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-MANTENIMIENTO-PERIODICO",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.DESPARASITACIONES = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-VISITAS-A-VETERINARIO",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.CONSULTAS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-PDIAGNOSTICO",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.ANALISIS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-CIRUGIAS",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.CIRUGIAS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-ESTROS",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.ESTROS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-COMIDA",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.COMIDA = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-HIGIENE",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.HIGIENE = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-MEDICAMENTOS",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.MEDICAMENTOS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-MEDICIONES",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.MEDICIONES = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-PATOLOGIAS-EXISTENTES",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.PATOLOGIAS = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-VACUNACION",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.VACUNACION = result;
      });
    this.afs
      .getCollectionListDateWhere(
        "CARTILLA-REGISTRO-TEST-DIAGNOSTICO",
        "uidmascota",
        "==",
        this.data.prodid
      )
      .subscribe((result) => {
        this.TEST = result;
      });
  }
}
