import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DatoService {
  private dato: BehaviorSubject<any> = new BehaviorSubject("");
  constructor() {}
  getDato() {
    return this.dato;
  }
  setDato(valor: any) {
    this.dato.next(valor);
  }
  // nombre: "",
  //   grupo: "",
  //   especie: "",
  //   tipo: "",
  //   genero: "",
  //   color: "",
  //   size: "",
  //   fechaDeNacimiento: "",
  //   esterilizacion: "",
  //   detalle: ""
}
