import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DatoService } from "./../../services/dato.service";
import { FirebaseService } from "./../../services/firebase/firebase.service";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { PopoverController } from "@ionic/angular";
import { SubMenuComponent } from "./../../sub-menu/sub-menu.component";
import dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import * as relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import "dayjs/locale/es";
dayjs.locale("es");
@Component({
  selector: "app-item-pets",
  templateUrl: "./item-pets.component.html",
  styleUrls: ["./item-pets.component.scss"],
})
export class ItemPetsComponent implements OnInit {
  @Input() pets: any[] = [];
  @Input() isMore: boolean;
  @Input() origin: string;
  @Output() onclick = new EventEmitter<any>();
  public fActual: dayjs.Dayjs;
  constructor(
    private routes: Router,
    private alertCtrl: AlertController,
    private afs: FirebaseService,
    public popoverController: PopoverController,
    private datoService: DatoService
  ) {
    this.fActual = dayjs();
  }

  ngOnInit() {
    // this.mostar = this.tipo === "ADOPCIONES" ? false : true;
    console.log(this.pets);
  }

  view(item: any) {
    this.onclick.emit(item);
  }

  async presentPopover(e: any, item: any) {
    const popover = await this.popoverController.create({
      component: SubMenuComponent,
      event: e,
      mode: "ios",
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    switch (data) {
      case "adopcion":
        this.darAdocion(item);
        break;
      case "cartilla":
        this.cartilla(item);
        break;
      case "perdida":
        this.perdido(item);
        break;
    }
  }

  calcularEdad(item: any) {
    let years = "";
    let months = "";
    var duration = dayjs.duration(this.fActual.diff(dayjs(item)));
    if (duration.years() > 0) {
      if (duration.years() == 1) {
        years = duration.years() + " año,";
      } else {
        years = duration.humanize() + ",";
      }
    }
    months = duration.months() + " meses";
    return years + " " + months;
  }

  async confirmarBorrar(item: any) {
    const aler = await this.alertCtrl
      .create({
        header: "Vetlook",
        subHeader: "",
        message: "Seguro que desea borrar a " + item.nombre,
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            handler: () => {
              console.log("Alert dismiss");
            },
          },
          {
            text: "Aceptar",
            handler: () => {
              this.borrar(item);
            },
          },
        ],
      })
      .then((res) => res.present());
  }
  borrar(item) {
    this.afs.deleteCollection(item.prodid, "MASCOTAS");
  }
  editar(item: any) {
    this.datoService.setDato(item);
    this.routes.navigate(["/add-mascota"]);
  }
  darAdocion(item) {
    const e = !item.adopcion.estado;
    const data = item;
    data.adopcion.estado = e;
    data.adopcion.createdAt = new Date();
    this.afs.updateCollection(item.prodid, "MASCOTAS", data);
    this.routes.navigate(["/lista", "ADOPCIONES"]);
  }
  cartilla(item) {
    const data = {
      mascotaId: item.prodid,
      mostrarModal: false,
    };
    this.datoService.setDato(data);
    this.routes.navigate(["/registro-medico"]);
  }
  perdido(item) {
    if (item.perdido.estado) {
      const data = item;
      data.perdido = {
        estado: false,
        ultimaV: "",
        telefono: "",
        recompensa: "",
        mensaje: "",
        createdAt: "",
      };
      this.afs.updateCollection(item.prodid, "MASCOTAS", data);
    } else {
      this.routes.navigate(["/pets-perdidas", item.prodid]);
    }
  }
}
