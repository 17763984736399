import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Cita } from "../interfaces/cita";
import { FirebaseService } from "../services/firebase/firebase.service";
import { HorarioModalComponent } from "./horario-modal/horario-modal.component";
import { DetallesComponent } from "./detalles/detalles.component";
import { BusinessPage } from "./../business/business.page";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
@Component({
    selector: "app-agenda",
    templateUrl: "./agenda.page.html",
    styleUrls: ["./agenda.page.scss"],
})
export class AgendaPage implements OnInit {
    public dias: string[];
    public diasCortos: string[];
    public meses: string[];
    public mesesCortos: string[];
    public usuario: any;
    public ubicacion: any;
    public fechaSeleccionada: string;
    customPickerOptions: any;
    public citas: Cita[];
    public citasOriginales: Cita[];
    public horas: any;
    public ubicaciones: any[];

    public days: Dayjs[] = [];
    public selectedDay: Dayjs;
    public today: Dayjs;
    public ubicacionModel: any;
    constructor(
        public modalController: ModalController,
        private firebase: FirebaseService
    ) {
        this.citas = [];
        this.citasOriginales = this.citas;
    }
    customActionSheetOptions: any = {
        header: "Sus negocios",
        subHeader: "Seleccione un negocio",
    };

    private OrdenarYAgruparCitas() {
        this.citas.sort(function (a, b) {
            return a.hora.localeCompare(b.hora);
        });
        this.horas = Object.entries(
            this.citas.reduce((r, a) => {
                r[a.hora] = [...(r[a.hora] || []), a];
                return r;
            }, {})
        );
        this.horas.sort(function (a, b) {
            return a["0"].localeCompare(b["0"]);
        });
    }

    generateDays() {
        this.today = dayjs();

        this.days.push(this.today);
        for (let index = 0; index < 10; index++) {
            this.days.push(this.days[index].add(1, "day"));
        }
    }

    async mostrarNegocios() {
        const modal = await this.modalController.create({
            component: BusinessPage,
            cssClass: "myModal",
            componentProps: this.usuario,
        });
        modal.onDidDismiss().then((dataReturned) => {
            console.log(dataReturned);
        });
        return await modal.present();
    }

    selectDay(day: Dayjs) {
        this.selectedDay = day;
        this.fechaSeleccionada = day.format("YYYY-MM-DD");
        this.obtenerCitasDelDia();
    }

    async presentDetailsModal(id) {
        const cita = this.citas.find((cita) => cita.prodid === id);
        const modal = await this.modalController.create({
            component: DetallesComponent,
            componentProps: {
                cita: cita,
                ubicacion: this.ubicacion,
            },
        });
        modal.present();
        const { data } = await modal.onWillDismiss();
        /* console.log(data);
    if (data.cancelada) {
      this.obtenerCitasDelDia();
    } */
    }
    async presentModal() {
        const modal = await this.modalController.create({
            component: HorarioModalComponent,
            cssClass: "horarioModal",
            componentProps: {
                ubicacion: this.ubicacion,
            },
        });
        return await modal.present();
    }

    obtenerCitasDelDia() {
        if (!this.ubicacion) {
            return;
        }

        this.citas = [];
        this.firebase
            .getCitasUbicacionFecha(
                this.ubicacion.prodid,
                this.fechaSeleccionada
            )
            .subscribe((response) => {
                for (let index = 0; index < response.length; index++) {
                    const cita = new Cita();
                    cita.dia = response[index]["dia"];
                    cita.email = response[index]["email"];
                    cita.fecha = response[index]["fecha"];
                    cita.hora = response[index]["hora"];
                    cita.idMascota = response[index]["idMascota"];
                    cita.idUbicacion = response[index]["idUbicacion"];
                    cita.idUsuario = response[index]["idUsuario"];
                    cita.motivo = response[index]["motivo"];
                    cita.nombre = response[index]["nombre"];
                    cita.prodid = response[index]["prodid"];
                    cita.status = response[index]["status"];
                    cita.telefono = response[index]["telefono"];
                    cita.setMs(response[index]["ms"]);
                    this.citas.push(cita);
                }
                this.OrdenarYAgruparCitas();
            });
    }

    establecerUbicacion(id: string) {
        this.ubicacion = this.ubicaciones.filter((m) => m.prodid === id)[0];
        this.obtenerCitasDelDia();
    }

    ubicacionSeleccionada() {
        this.establecerUbicacion(this.ubicacionModel);
    }

    ngOnInit() {
        this.fechaSeleccionada = moment().format("YYYY-MM-DD");
        this.getDataUser();
        this.usuario = JSON.parse(localStorage.getItem("usuario"));
        this.generateDays();
        this.firebase
            .getCollectionListDateWhere(
                "UBICACIONES",
                "userId",
                "==",
                this.usuario.id
            )
            .subscribe((response: any) => {
                this.ubicaciones = response;
                if (response.length > 0) {
                    this.ubicacionModel = response[0].prodid;
                    this.ubicacion = this.ubicaciones.filter(
                        (m) => m.prodid === this.ubicacionModel
                    )[0];
                    this.selectDay(this.today);
                }
            });
    }

    getDataUser() {
        const result = JSON.parse(localStorage.getItem("usuario"));
        this.usuario = result;
    }
}
