<ion-header class="ion-padding ion-no-border">
    <ion-toolbar
        ><ion-buttons slot="start">
            <ion-back-button
                defaultHref="lista/MASCOTAS"
                text=""
                color="tertiary"
                icon="arrow-back"
            >
            </ion-back-button>
        </ion-buttons>
        <ion-title>Mi Mascota</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid class="no-pad">
        <ion-row style="margin-top: 15em" *ngIf="!data">
            <ion-col size="12">
                <img
                    src="/assets/loading-pet.gif"
                    alt=""
                    srcset=""
                    style="display: block; margin: auto"
                />
            </ion-col>
        </ion-row>
        <ion-row
            *ngIf="data"
            class="header"
            [ngStyle]="{'background-image': 'url(' + imagen + ')'}"
        >
        </ion-row>
        <ion-row class="infoProfile" *ngIf="data">
            <ion-col size="12">
                <h3>¡Hola, {{data.nombre}}!</h3>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="no-pad" size="12">
                <ion-segment
                    scrollable
                    value="0"
                    [(ngModel)]="option"
                    color="primary"
                    mode="ios"
                    *ngIf="itemsCategoria"
                >
                    <ion-segment-button class="botones-segmento" value="0">
                        <img
                            class="icon-img"
                            src="/assets/icon/home.svg"
                            alt="Inicio"
                            id="homeId"
                        />
                        <ion-label>Inicio</ion-label>
                    </ion-segment-button>

                    <ion-segment-button
                        class="botones-segmento"
                        *ngFor="let item of itemsCategoria"
                        [value]="item.nombre"
                    >
                        <img
                            class="icon-img"
                            src="/assets/cartilla-icons/{{item.icon}}.svg"
                            alt="{{item.nombre}}"
                            id="{{item.uid}}"
                        />
                        <ion-label> {{item.nombre}} </ion-label>
                    </ion-segment-button>
                </ion-segment>
            </ion-col>
        </ion-row>

        <ion-row class="ion-padding" [ngSwitch]="option">
            <ion-col class="no-pad" size="12" *ngSwitchCase="0">
                <ion-grid class="no-pad profile" *ngIf="data">
                    <ion-row>
                        <ion-col size="12">
                            <ion-grid class="no-pad">
                                <ion-row *ngIf="data.size">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Tamaño:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{data.size}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.fechaDeNacimiento">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Edad:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>
                                            {{calcularEdad(data.fechaDeNacimiento)}}
                                        </p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.especie">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Especie:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{letraCapital(data.especie)}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.grupo">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Grupo:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{letraCapital(data.grupo)}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.tipo">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Tipo:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{data.tipo}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.esterilizacion">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Esterilizado:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>
                                            {{letraCapital(data.esterilizacion)}}
                                        </p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.genero">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Sexo:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{letraCapital(data.genero)}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.color">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Color:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{data.color}}</p>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="data.detalle">
                                    <ion-col class="no-pad" size="3.5">
                                        <p><span>Detalle:</span></p>
                                    </ion-col>
                                    <ion-col class="text-info">
                                        <p>{{data.detalle}}</p>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="no-pad btn-footer">
                            <ion-button
                                color="secondary"
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="editar(data)"
                                >Editar</ion-button
                            >
                            <ion-button
                                color="danger"
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="confirmarBorrar(data)"
                                >Eliminar</ion-button
                            >
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col
                class="no-pad"
                size="12"
                *ngSwitchCase="'Análisis Clínicos'"
            >
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Análisis Clínicos</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="ANALISIS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let AN of ANALISIS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(AN.fechapdiagnostico)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, AN, 'pdiagnostico')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Descripción:
                                            </ion-text>
                                            {{AN.descripcionpdiagnostico}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:
                                            </ion-text>
                                            {{AN.notaspdiagnostico}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Análisis Clínicos', 'pdiagnostico')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col
                class="no-pad"
                size="12"
                *ngSwitchCase="'Consultas Veterinarias'"
            >
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Consultas Veterinarias</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="CONSULTAS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let CONSULTA of CONSULTAS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(CONSULTA.fechavisitasaveterinario)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, CONSULTA, 'visitas-a-veterinario')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Responsable médico: </ion-text
                                            >{{CONSULTA.nombrevisitasaveterinario}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Descripción: </ion-text
                                            >{{CONSULTA.descripcionvisitasaveterinario}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Costo: </ion-text
                                            >{{CONSULTA.preciovisitasaveterinario}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas: </ion-text
                                            >{{CONSULTA.notasvisitasaveterinario}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Consultas Veterinarias', 'visitas-a-veterinario')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Desparasitación'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Desparasitación</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="DESPARASITACIONES.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let DESP of DESPARASITACIONES">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(DESP.fechamantenimientoperiodico)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, DESP, 'mantenimiento-periodico')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Tiempo:
                                            </ion-text>
                                            {{DESP.tipomantenimientoperiodico}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Gravedad:
                                            </ion-text>
                                            {{DESP.gravedadmantenimientoperiodico}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas: </ion-text
                                            >{{DESP.notasmantenimientoperiodico}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Desparasitación', 'mantenimiento-periodico')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Alergias'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Alergias</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="ALERGIAS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let ALERGIA of ALERGIAS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(ALERGIA.fechadiagalergias)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, ALERGIA, 'alergias')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Nombre:
                                            </ion-text>
                                            {{ALERGIA.nombrealergias}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Gravedad:
                                            </ion-text>
                                            {{ALERGIA.gravedadalergias}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Acciones:
                                            </ion-text>
                                            {{ALERGIA.accionesrealalergias}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Categoria:
                                            </ion-text>
                                            {{ALERGIA.categoriaalergias}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:
                                            </ion-text>
                                            {{ALERGIA.notasalergias}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Alergias', 'alergias')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Cirugias'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Cirugias</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="CIRUGIAS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let CIRUGIA of CIRUGIAS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(CIRUGIA.fechacirugia)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, CIRUGIA, 'cirugias')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Responsable médico:
                                            </ion-text>
                                            {{CIRUGIA.nombrecirugia}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Precio:
                                            </ion-text>
                                            {{CIRUGIA.preciocirugia}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Tipo:
                                            </ion-text>
                                            {{CIRUGIA.tipocirujua}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Observaciones:
                                            </ion-text>
                                            {{CIRUGIA.observacionescirugia}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:
                                            </ion-text>
                                            {{CIRUGIA.notascirugia}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Cirugias', 'cirugias')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Fertilidad'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Fertilidad</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="ESTROS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let ESTRO of ESTROS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(ESTRO.fechainicioestros)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, ESTRO, 'estros')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Fecha inicio:
                                            </ion-text>
                                            {{fecha(ESTRO.fechainicioestros)}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Fecha final:
                                            </ion-text>
                                            {{fecha(ESTRO.fechafinalestros)}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:</ion-text
                                            >
                                            {{ESTRO.notasestros}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Fertilidad', 'estros')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Alimentación'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Alimentación</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="COMIDA.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let COMID of COMIDA">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(COMID.fechacomida)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, COMID, 'comida')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Nombre:
                                            </ion-text>
                                            {{COMID.nombrecomida}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Tipo:
                                            </ion-text>
                                            {{COMID.tipocomida}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:
                                            </ion-text>
                                            {{COMID.notascomida}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Alimentación', 'comida')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Higiene'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Higiene</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="HIGIENE.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let HIGIEN of HIGIENE">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(HIGIEN.fechatipohigiene)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, HIGIEN, 'higiene')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="4">
                                            <ion-text color="primary">
                                                Tipo:
                                            </ion-text>
                                            {{HIGIEN.tipohigihigiene}}
                                        </ion-col>
                                        <ion-col size="8">
                                            <ion-text color="primary"
                                                >Gravedad:</ion-text
                                            >
                                            {{HIGIEN.gravedadhigiene}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas: </ion-text
                                            >{{HIGIEN.notashigiene}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Higiene', 'higiene')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Medicamentos'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Medicamentos</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="MEDICAMENTOS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let MEDICAMENTO of MEDICAMENTOS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(MEDICAMENTO.fechamedicamentos)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, MEDICAMENTO, 'medicamentos')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Nombre:
                                            </ion-text>
                                            {{MEDICAMENTO.nombremedicamentos}}
                                        </ion-col>
                                        <ion-col size="6">
                                            <ion-text color="primary">
                                                Dosis:
                                            </ion-text>
                                            {{MEDICAMENTO.dosismedicamentos}}
                                        </ion-col>
                                        <ion-row>
                                            <ion-col size="12">
                                                <ion-text color="primary">
                                                    Higiene:
                                                </ion-text>
                                                {{MEDICAMENTO.higienemedicamentos}}
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col size="12">
                                                <ion-text color="primary">
                                                    Notas:
                                                </ion-text>
                                                {{MEDICAMENTO.notasmedicamentos}}
                                            </ion-col>
                                        </ion-row>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Medicamentos', 'medicamentos')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Mediciones'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Mediciones</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="MEDICIONES.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let MEDICIONE of MEDICIONES">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(MEDICIONE.fechamediciones)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, MEDICIONE, 'mediciones')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Tipo: </ion-text
                                            >{{MEDICIONE.tipomediciones}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Valor: </ion-text
                                            >{{MEDICIONE.valormediciones}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas: </ion-text
                                            >{{MEDICIONE.notasmediciones}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Mediciones', 'mediciones')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col
                class="no-pad"
                size="12"
                *ngSwitchCase="'Patologías Existentes'"
            >
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Patologías Existentes</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="PATOLOGIAS.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let PATOLOGIA of PATOLOGIAS">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(PATOLOGIA.fechapatologiasexistentes)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, PATOLOGIA, 'patologias-existentes')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Nombre:
                                            </ion-text>
                                            {{PATOLOGIA.nombrepatologiasexistentes}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Categoria:
                                            </ion-text>
                                            {{PATOLOGIA.categoriapatologiasexistentes}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <ion-text color="primary">
                                                Notas: </ion-text
                                            >{{PATOLOGIA.notaspatologiasexistentes}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Patologías Existentes', 'patologias-existentes')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col class="no-pad" size="12" *ngSwitchCase="'Vacunación'">
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de Vacunación</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-text-center"
                        *ngIf="VACUNACION.length === 0"
                    >
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let VACUNACIO of VACUNACION">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(VACUNACIO.fechavacunacion)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, VACUNACIO, 'vacunacion')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Vacuna: </ion-text
                                            >{{VACUNACIO.vacunavacunacion}}</ion-col
                                        >
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12"
                                            ><ion-text color="primary"
                                                >Notas: </ion-text
                                            >{{VACUNACIO.notasvacunacion}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Vacunación', 'vacunacion')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col
                class="no-pad"
                size="12"
                *ngSwitchCase="'Pruebas diagnóstico'"
            >
                <ion-grid class="no-pad">
                    <ion-row class="ion-text-center">
                        <ion-col class="no-pad" size="12">
                            <h3>Registro de pruebas diagnóstico</h3>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-text-center" *ngIf="TEST.length === 0">
                        <ion-col size="12">
                            <h4>¡No hay registros!</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let TES of TEST">
                        <ion-col>
                            <ion-card>
                                <ion-toolbar>
                                    <ion-title size="small"
                                        >{{
                                        fecha(TES.fechatestdiagnostico)}}</ion-title
                                    >
                                    <ion-buttons slot="end">
                                        <ion-button
                                            (click)="presentPopover($event, TES, 'test-diagnostico')"
                                        >
                                            <ion-icon
                                                slot="icon-only"
                                                color="primary"
                                                name="more"
                                            ></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                                <ion-card-content>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Nombre:
                                            </ion-text>
                                            {{TES.nombretestdiagnostico}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Resultadoooos:
                                            </ion-text>
                                            {{TES.resultadotestdiagnostico}}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-text color="primary">
                                                Notas:</ion-text
                                            >
                                            {{TES.notastestdiagnostico}}
                                        </ion-col>
                                    </ion-row>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-button
                                *ngIf="origin === 'MASCOTAS'"
                                (click)="addRegisterHelt(data, 'Pruebas diagnóstico', 'test-diagnostico')"
                                type="button"
                                color="primary"
                                expand="block"
                                fill="outline"
                                size="small"
                            >
                                <ion-label>Ir a sección</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer [translucent]="true">
    <div
        style="height: env(safe-area-inset-bottom); background-color: white"
    ></div>
</ion-footer>
