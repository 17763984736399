import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
@Injectable({
  providedIn: "root",
})
export class AppserviceService {
  constructor(
    public storage: Storage,
    public localNotifications: LocalNotifications
  ) {}
  // set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      const result = await this.storage.set(key, value);
      return true;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  }
  // to get a key/value pair
  async get(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }
  // set a key/value object
  // tslint:disable-next-line:ban-types
  setObject(key: string, object: Object) {
    localStorage.setItem(key, JSON.stringify(object));
  }
  // get a key/value object

  // remove a single key value:
  remove(key: string) {
    this.storage.remove(key);
  }
  //  delete all data from your application:
  clear() {
    localStorage.removeItem("usuario");
    localStorage.removeItem("apiKey");
    localStorage.removeItem("notificaciones");
    localStorage.removeItem("recordatorios");
    localStorage.removeItem("calificacion");
  }

  single_notification(textData) {
    try {
      // Schedule a single notification
      this.localNotifications.schedule({
        id: 1,
        text: textData,
        lockscreen: true,
        sound: "file://sound.mp3",
        led: "FF0000",
        data: { secret: "key_data" },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
