import { PerfilPageModule } from "./perfil/perfil.module";
import { BusinessPageModule } from "./business/business.module";
import { PublicidadPageModule } from "./publicidad/publicidad.module";
import { Facebook } from "@ionic-native/facebook/ngx";
import { NegocioDetallePageModule } from "./negocio-detalle/negocio-detalle.module";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import {
    IonicModule,
    IonicRouteStrategy,
    AlertController,
} from "@ionic/angular";
import {
    LocationStrategy,
    HashLocationStrategy,
    registerLocaleData,
} from "@angular/common";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { File } from "@ionic-native/file/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { Stripe } from "@ionic-native/stripe/ngx";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire/compat/";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { IonicStorageModule } from "@ionic/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import localeEsAr from "@angular/common/locales/es-AR";
import { RegistroMedicoModalPageModule } from "./registro-medico-modal/registro-medico-modal.module";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
registerLocaleData(localeEsAr, "es-Ar");
import { IonicRatingModule } from "ionic4-rating";
import { SubMenuComponent } from "./sub-menu/sub-menu.component";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated-12/ionic/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { AngularFireAuthGuard } from "@angular/fire/compat/auth-guard";
import { ProfilePetPageModule } from "./profile-pet/profile-pet.module";
import { AgendaPageModule } from "./agenda/agenda.module";
import { HorarioModalComponent } from "./agenda/horario-modal/horario-modal.component";
import { LaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { IonicSelectableModule } from "ionic-selectable";
import { Device } from "@ionic-native/device/ngx";
import { CUSTOM_ELEMENTS_SCHEMA, isDevMode } from "@angular/core";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
@NgModule({
    declarations: [AppComponent, SubMenuComponent, HorarioModalComponent],
    exports: [SubMenuComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        FormsModule,
        NegocioDetallePageModule,
        PerfilPageModule,
        BusinessPageModule,
        RegistroMedicoModalPageModule,
        ReactiveFormsModule,
        IonicRatingModule,
        ProfilePetPageModule,
        BusinessPageModule,
        PublicidadPageModule,
        AgendaPageModule,
        IonicSelectableModule,
        IonicStorageModule.forRoot({
            name: "_vetlook_",
            driverOrder: ["indexeddb"],
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
    ],
    providers: [
        LocalNotifications,
        StatusBar,
        Geolocation,
        NativeGeocoder,
        SplashScreen,
        Deeplinks,
        Stripe,
        CallNumber,
        HttpClient,
        Facebook,
        Device,
        GooglePlus,
        File,
        Camera,
        FilePath,
        InAppBrowser,
        AlertController,
        AngularFireAuthGuard,
        SignInWithApple,
        FCM,
        LaunchNavigator,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: "es-Ar" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
// { provide: Camera, useClass: CameraMock },
