import { Component, OnInit } from "@angular/core";

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FirebaseService } from "./../services/firebase/firebase.service";
import { NavController } from "@ionic/angular";
import { HomePage } from "../home/home.page";
import * as moment from "moment";
import { NgModel } from "@angular/forms";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-publicidad",
    templateUrl: "./publicidad.page.html",
    styleUrls: ["./publicidad.page.scss"],
})
export class PublicidadPage implements OnInit {
    mostrarBotonCerrarPublicidad = true;

    slideOpts = {
        initialSlide: 0,
        speed: 1050,
        autoplay: {
            delay: 5200,
        },
        loop: false,
        preloadImages: true,
        lazy: false,
        updateOnImagesReady: true,
    };

    visible = false;
    spinner = true;

    publicidad = [];

    constructor(
        public afs: AngularFirestore,
        public fireservice: FirebaseService,
        private modalController: ModalController
    ) {}

    cerrarModal() {
        this.modalController.dismiss();
    }

    ngOnInit() {
        this.getAds();
    }

    async getAds() {
        this.fireservice
            .getCollectionListM("publicidad")
            .subscribe((data: any) => {
                for (var value of data) {
                    if (value.activo == true && value.tipo == "carrusel") {
                        this.publicidad.push(value);
                    }
                }
            });
    }

    load() {
        this.visible = true;
        this.spinner = false;
    }

    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
