<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="closeModal()">
                <ion-icon
                    color="tertiary"
                    slot="icon-only"
                    name="close"
                ></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="tertiary">Horario</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-grid class="ion-padding">
        <ion-row
            style="text-align: center"
            class="ion-justify-content-center ion-no-padding"
        >
            <ion-col size="6">
                <ion-label class="bodyText"
                    >Citas por hora: {{ citasString }}</ion-label
                >
            </ion-col>
        </ion-row>
        <ion-row style="margin-bottom: 1.5rem">
            <ion-range
                aria-label="Citas por hora: {{ citasString }}"
                [ticks]="true"
                [snaps]="true"
                [min]="0"
                [max]="15"
                [pin]="true"
                color="secondary"
                (ionChange)="cambioCitas($event)"
                [(ngModel)]="cantidadCitasMaxima"
                ><ion-icon
                    slot="start"
                    color="secondary"
                    name="remove-circle"
                    (click)="reducirCitasHora()"
                    style="cursor: pointer"
                ></ion-icon>
                <ion-icon
                    slot="end"
                    color="secondary"
                    name="add-circle"
                    (click)="aumentarCitasHora()"
                    style="cursor: pointer"
                ></ion-icon
            ></ion-range>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-col sizeLg="auto">
                <ion-toggle
                    [(ngModel)]="abierto24Horas"
                    justify="center"
                    class="bodyText"
                    >Abierto las 24 horas</ion-toggle
                >
            </ion-col>
        </ion-row>
    </ion-grid>
    <ng-container *ngIf="!abierto24Horas">
        <ion-grid class="ion-padding" *ngFor="let diaSemana of diasAbierto">
            <ion-row class="ion-align-items-center ion-justify-content-start">
                <ion-col size="10">
                    <ion-toggle
                        [(ngModel)]="diaSemana.abierto"
                        justify="center"
                        labelPlacement="end"
                        >Abrir los {{ diaSemana.dia }}</ion-toggle
                    >
                </ion-col>
                <ion-col size="2">
                    <ion-button
                        (click)="diaSemana.extended = !diaSemana.extended"
                        style="height: 2rem; width: 2rem; font-size: 0.1rem"
                    >
                        <ion-icon
                            *ngIf="!diaSemana.extended"
                            name="add-circle"
                            size="small"
                        ></ion-icon>
                        <ion-icon
                            *ngIf="diaSemana.extended"
                            name="remove-circle"
                            size="small"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row
                *ngIf="diaSemana.abierto"
                class="ion-align-items-center ion-justify-content-center"
                style="margin-bottom: 1.5rem"
            >
                <ion-col sizeLg="3" sizeXs="6">
                    <ion-select
                        interface="action-sheet"
                        labelPosition="stacked"
                        label="De "
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.deHora"
                    >
                        <ion-select-option
                            *ngFor="let horario of horarios; let i = index"
                            [value]="horario.value"
                            >{{ horario.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>
                <ion-col sizeLg="3" sizeXs="6">
                    <ion-select
                        interface="action-sheet"
                        placeholder="10 Minutos"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.deMinutos"
                    >
                        <ion-select-option
                            *ngFor="let minuto of minutos; let i = index"
                            value="{{ minuto.value }}"
                            >{{ minuto.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>

                <ion-col sizeLg="3" sizeXs="6" class="ion-align-self-center">
                    <ion-select
                        interface="action-sheet"
                        labelPosition="stacked"
                        label="A "
                        placeholder="6 PM"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.aHora"
                    >
                        <ion-select-option
                            *ngFor="let horario of horarios; let i = index"
                            value="{{ horario.value }}"
                            >{{ horario.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>
                <ion-col sizeLg="3" sizeXs="6" class="ion-align-self-center">
                    <ion-select
                        interface="action-sheet"
                        placeholder="45 Minutos"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.aMinutos"
                    >
                        <ion-select-option
                            *ngFor="let minuto of minutos; let i = index"
                            value="{{ minuto.value }}"
                            >{{ minuto.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>

                <ion-col
                    size="12"
                    class="ion-no-margin ion-no-padding"
                    *ngIf="diaSemana.extended"
                >
                    <ion-item
                        style="width: 85%; margin: 0; padding: 0"
                    ></ion-item>
                </ion-col>

                <ion-col
                    *ngIf="diaSemana.extended"
                    sizeLg="3"
                    sizeXs="6"
                    class="ion-align-self-center"
                >
                    <ion-select
                        interface="action-sheet"
                        labelPosition="stacked"
                        label="De "
                        placeholder="6 PM"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.deHoraEx"
                    >
                        <ion-select-option
                            *ngFor="let horario of horarios; let i = index"
                            value="{{ horario.value }}"
                            >{{ horario.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>
                <ion-col
                    *ngIf="diaSemana.extended"
                    sizeLg="3"
                    sizeXs="6"
                    class="ion-align-self-center"
                >
                    <ion-select
                        interface="action-sheet"
                        placeholder="45 Minutos"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.deMinutosEx"
                    >
                        <ion-select-option
                            *ngFor="let minuto of minutos; let i = index"
                            value="{{ minuto.value }}"
                            >{{ minuto.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>

                <ion-col
                    *ngIf="diaSemana.extended"
                    sizeLg="3"
                    sizeXs="6"
                    class="ion-align-self-center"
                >
                    <ion-select
                        interface="action-sheet"
                        labelPosition="stacked"
                        label="A "
                        placeholder="10 PM"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.aHoraEx"
                    >
                        <ion-select-option
                            *ngFor="let horario of horarios; let i = index"
                            value="{{ horario.value }}"
                            >{{ horario.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>
                <ion-col
                    *ngIf="diaSemana.extended"
                    sizeLg="3"
                    sizeXs="6"
                    class="ion-align-self-center"
                >
                    <ion-select
                        interface="action-sheet"
                        placeholder="25 Minutos"
                        class="smallSelect"
                        cancelText="Cancelar"
                        [(ngModel)]="diaSemana.aMinutosEx"
                    >
                        <ion-select-option
                            *ngFor="let minuto of minutos; let i = index"
                            value="{{ minuto.value }}"
                            >{{ minuto.label }}</ion-select-option
                        >
                    </ion-select>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>

<ion-footer
    class="ion-no-border"
    style="background-color: var(--ion-color-primary)"
>
    <ion-button
        (click)="guardarHorario()"
        style="
            border-radius: 0 !important;
            --border-radius: 0;
            --box-shadow: none;
        "
    >
        <ion-text color="light">Guardar</ion-text>
    </ion-button>
</ion-footer>
