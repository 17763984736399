import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent implements OnInit {
  @Input() placeholder: string;
  @Input() data: any[] = [];
  @Output() onclick = new EventEmitter<any>();
  @Output() typing = new EventEmitter<boolean>(false);
  searchData: any;
  isSearch = false;
  constructor() {}

  ngOnInit() {}

  async getItems(ev: any) {
    const val: any = ev.target.value;
    if (val && val.trim() !== "") {
      this.isSearch = true;
      this.typing.emit(true);
      const nombre = this.data.filter((item) => {
        if (item.nombre) {
          return item.nombre.trim().toUpperCase().includes(val.toUpperCase());
        }
      });
      const sexo: any = this.data.filter((item) => {
        return item.genero.toUpperCase().includes(val.toUpperCase());
      });
      const especie: any = this.data.filter((item) => {
        return item.especie.toUpperCase().includes(val.toUpperCase());
      });
      const grupo: any = this.data.filter((item) => {
        return item.grupo.toUpperCase().includes(val.toUpperCase());
      });
      const color: any = this.data.filter((item) => {
        return item.color.toUpperCase().includes(val.toUpperCase());
      });
      const size: any = this.data.filter((item) => {
        return item.size.toUpperCase().includes(val.toUpperCase());
      });
      const tipo: any = this.data.filter((item) => {
        return item.tipo.toUpperCase().includes(val.toUpperCase());
      });
      this.searchData = nombre
        .concat(sexo)
        .concat(especie)
        .concat(grupo)
        .concat(color)
        .concat(size)
        .concat(tipo);
    } else {
      this.typing.emit(false);
      this.isSearch = false;
    }
  }
  view(item: any) {
    this.onclick.emit(item);
  }
}

// const val: any = ev.target.value;
// if (val && val.trim() !== "") {
//   this.isItemAvailable = true;
//   const location: any = this.locations.filter(item => {
//     if (item.uid && item.nombre_negocio) {
//       console.log(item.nombre_negocio);
//       return item.nombre_negocio.trim().toUpperCase().includes(val.toUpperCase());
//     }
//   });

//   const producto: any = this.productos.filter(item => {
//     return item.nomproducto.toUpperCase().includes(val.toUpperCase());
//   });

//   const servicio: any = this.servicios.filter(item => {
//     return item.nomservicio.toUpperCase().includes(val.toUpperCase());
//   });
//   this.searchQuery = location.concat(servicio).concat(producto);
// } else {
//   this.isItemAvailable = false;
// }
