import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    AngularFirestore,
    AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";
// const API = "https://payment-vet.herokuapp.com";
//usando una server local
// const API = "http://localhost:8000";
const API = "http://104.248.230.180";

@Injectable({
    providedIn: "root",
})
export class FirebaseService {
    loading: any;
    public idNEgocio: any;
    constructor(private afs: AngularFirestore, public http: HttpClient) {}

    getCollectionList(collection: string) {
        return this.afs
            .collection(collection, (ref) => ref.orderBy("nombre", "desc"))
            .valueChanges();
    }

    getCollectionListA(collection: string) {
        return this.afs
            .collection(collection, (ref) => ref.orderBy("orden", "asc"))
            .valueChanges();
    }
    getCollectionListM(collection: string) {
        return this.afs.collection(collection).valueChanges();
    }

    getCollectionListOrder(collection: string) {
        return this.afs
            .collection(collection, (ref) => ref.orderBy("Order", "asc"))
            .valueChanges();
    }

    getCollectionListNoName(collection: string) {
        return this.afs.collection(collection).valueChanges();
    }

    getCollectionListDate(collection: string) {
        return this.afs
            .collection(collection, (ref) => ref.orderBy("createdAt", "desc"))
            .valueChanges();
    }

    getActivatedLocations() {
        return this.afs
            .collection("UBICACIONES", (ref) => ref.where("estado", "==", true))
            .valueChanges();
    }

    getCitasUbicacionFecha(ubicacionId: string, fecha: string) {
        return this.afs
            .collection("CITAS", (ref) =>
                ref
                    .where("idUbicacion", "==", ubicacionId)
                    .where("fecha", "==", fecha)
            )
            .valueChanges();
    }

    getCitasUbicacion(ubicacionId: string, ms: number) {
        return this.afs
            .collection("CITAS", (ref) =>
                ref
                    .where("idUbicacion", "==", ubicacionId)
                    .where("ms", ">=", ms)
                    .orderBy("ms")
                    .limit(20)
            )
            .valueChanges();
    }

    getCitasUsuario(usuarioId: string, ms: number) {
        return this.afs
            .collection("CITAS", (ref) =>
                ref.where("idUsuario", "==", usuarioId).orderBy("ms").limit(20)
            )
            .valueChanges();
    }
    getNotificacionesUsuario(usuarioId: string, ms: number) {
        return this.afs
            .collection("NOTIFICACIONES", (ref) =>
                ref
                    .where("idReceptor", "==", usuarioId)
                    .orderBy("ms", "desc")
                    .limit(20)
            )
            .valueChanges();
    }

    getCitasUbicacionFechaHora(
        ubicacionId: string,
        fecha: string,
        hora: string
    ) {
        return this.afs
            .collection("CITAS", (ref) =>
                ref
                    .where("idUbicacion", "==", ubicacionId)
                    .where("fecha", "==", fecha)
                    .where("hora", "==", hora)
            )
            .valueChanges();
    }

    getCitasUsuarioFechaHora(usuarioId: string, fecha: string, hora: string) {
        return this.afs
            .collection("CITAS", (ref) =>
                ref
                    .where("idUsuario", "==", usuarioId)
                    .where("fecha", "==", fecha)
                    .where("hora", "==", hora)
            )
            .valueChanges();
    }

    //obtener los datos de la cartilla de la mascota que seleccionemos no como la anterior que solo pedian todo :(
    getConsultasMiMascota(
        collection: string,
        filter: string,
        operador: any,
        valor: any
    ) {
        return this.afs
            .collection(collection, (res) =>
                res
                    .where(filter, operador, valor)
                    .orderBy("fechavisitasaveterinario", "desc")
            )
            .valueChanges();
    }

    getCollectionListDateASC(collection: string) {
        return this.afs
            .collection(collection, (ref) => ref.orderBy("createdAt", "asc"))
            .valueChanges();
    }

    getCollectionListDateWhere(
        collection: string,
        filter: string,
        operador: any,
        valor: any
    ) {
        return this.afs
            .collection(collection, (res) => res.where(filter, operador, valor))
            .valueChanges();
    }

    getCollectionListDateWhereOrder(
        collection: string,
        filter: string,
        operador: any,
        valor: any
    ) {
        return this.afs
            .collection(collection, (res) =>
                res.where(filter, operador, valor).orderBy("ORDEN", "asc")
            )
            .valueChanges();
    }

    getCollectionDetail(ID: string, collection: string) {
        return this.afs.collection(collection).doc(ID).valueChanges();
    }

    async addCollection(collection: string, objetoData: any) {
        // {objetoData}
        const docRef = await this.afs.collection(collection).add(objetoData);
        this.idNEgocio = docRef.id;
        await this.afs
            .collection(collection)
            .doc(docRef.id)
            .update({
                prodid: docRef.id,
            })
            .catch((err) => {
                console.log(err);
            });
        return docRef.id;
    }
    addCollectionF(collection: string, objetoData: any) {
        // {objetoData}
        return this.afs
            .collection(collection)
            .add(objetoData)
            .catch((err) => {
                console.log(err);
            });
    }
    addCollectionSus(collection: string) {
        // {objetoData}
        return this.afs.collection(collection);
    }

    updateCollection(id: string, collection: string, objetoData: any) {
        return this.afs
            .collection(collection)
            .doc(id)
            .update(objetoData)
            .catch((err) => {
                console.log(err);
            });
    }

    async deleteCollection(id: string, collection: string) {
        await this.afs
            .collection(collection)
            .doc(id)
            .delete()
            .catch((err) => {
                console.log(err);
            });
    }

    addColAndIdPers(id: string, collection: string, obj: any) {
        return this.afs.collection(collection).doc(id).set(obj);
    }

    postRequest<T>(endPoint: string, item: any) {
        return this.http.post<T>(`${API}${endPoint}`, item, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        });
    } //end postRequest

    postRequest2<T>(endPoint: string, item: any) {
        fetch(API + endPoint, {
            body: JSON.stringify(item),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            mode: "no-cors",
        });
    }
}
