import { Component, OnInit } from "@angular/core";
import {
  NavParams,
  ModalController,
  AlertController,
  ToastController,
  PopoverController,
} from "@ionic/angular";
import dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import * as relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import "dayjs/locale/es";
dayjs.locale("es");
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
} from "@angular/forms";
import { FirebaseService } from "../services/firebase/firebase.service";
import { AppserviceService } from "../services/appservice.service";
import { UtilService } from "../services/util.service";
import { Recordatorio } from "../interfaces/recordatorio";
import { ReminderServiceService } from "../services/reminder-service.service";
import { EditarComponent } from "../registro-medico/editar/editar.component";
import { EditarRegistroSubMenuComponent } from "../components/editar-registro-sub-menu/editar-registro-sub-menu.component";

@Component({
  selector: "app-registro-medico-modal",
  templateUrl: "./registro-medico-modal.page.html",
  styleUrls: ["./registro-medico-modal.page.scss"],
})
export class RegistroMedicoModalPage implements OnInit {
  public registromedico;
  public checked: string;

  public fechaSeleccionada: string;

  public recordatorios: Recordatorio[];

  public username;
  public dataGroup: UntypedFormGroup;
  public cirugias: UntypedFormGroup;
  public alergias: UntypedFormGroup;
  public comida: UntypedFormGroup;
  public estros: UntypedFormGroup;
  public higiene: UntypedFormGroup;
  public incidencias: UntypedFormGroup;
  public limpieza: UntypedFormGroup;
  public mantenimientoperiodico: UntypedFormGroup;
  public medicamentos: UntypedFormGroup;
  public mediciones: UntypedFormGroup;
  public patologiasexistentes: UntypedFormGroup;
  public pdiagnostico: UntypedFormGroup;
  public rnumericos: UntypedFormGroup;
  public testdiagnostico: UntypedFormGroup;
  public vacunacion: UntypedFormGroup;
  public visitasaveterinario: UntypedFormGroup;
  public testgeneticos: UntypedFormGroup;
  public itemsCartilla = [];

  public user: any;
  constructor(
    private afs: FirebaseService,
    private formBuilder: UntypedFormBuilder,
    private navParams: NavParams,
    public modalController: ModalController,
    public util: UtilService,
    private recordatorioService: ReminderServiceService,
    public toastController: ToastController,
    private alertCtrl: AlertController,
    public popoverController: PopoverController
  ) {
    this.iniciarFormulario();
    this.recordatorios = [];
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("usuario"));
    this.registromedico = this.navParams.data;
    this.gerRegiterData(this.registromedico.icon);
  }
  iniciarFormulario() {
    this.cirugias = this.formBuilder.group({
      tipocirujua: ["", Validators.required],
      fechacirugia: ["", Validators.required],
      nombrecirugia: ["", Validators.required],
      preciocirugia: ["", Validators.required],
      observacionescirugia: [""],
      notascirugia: [""],
    });
    this.alergias = this.formBuilder.group({
      nombrealergias: ["", Validators.required],
      fechadiagalergias: ["", Validators.required],
      gravedadalergias: ["", Validators.required],
      categoriaalergias: ["", Validators.required],
      accionesrealalergias: [""],
      notasalergias: [""],
    });
    this.comida = this.formBuilder.group({
      nombrecomida: ["", Validators.required],
      tipocomida: ["", Validators.required],
      /* unidadcomida: ["", Validators.required], */
      fechacomida: ["", Validators.required],
      notascomida: [""],
      recordatorios: [],
    });
    this.estros = this.formBuilder.group({
      fechainicioestros: ["", Validators.required],
      fechafinalestros: ["", Validators.required],
      notasestros: [""],
    });
    this.higiene = this.formBuilder.group({
      tipohigihigiene: ["", Validators.required],
      fechatipohigiene: ["", Validators.required],
      gravedadhigiene: ["", Validators.required],
      notashigiene: [""],
      recordatorios: [],
    });
    this.incidencias = this.formBuilder.group({
      descripcionincidencias: ["", Validators.required],
      categoriaincidencias: ["", Validators.required],
      fechainsidenteincidencias: ["", Validators.required],
    });
    this.limpieza = this.formBuilder.group({
      tipolimpieza: ["", Validators.required],
      fechalimpieza: ["", Validators.required],
      gravedadlimpieza: ["", Validators.required],
      notaslimpieza: [""],
    });
    this.mantenimientoperiodico = this.formBuilder.group({
      tipomantenimientoperiodico: ["", Validators.required],
      fechamantenimientoperiodico: ["", Validators.required],
      gravedadmantenimientoperiodico: ["", Validators.required],
      notasmantenimientoperiodico: [""],
      recordatorios: [],
    });
    this.medicamentos = this.formBuilder.group({
      nombremedicamentos: ["", Validators.required],
      notasmedicamentos: [""],
      dosismedicamentos: ["", Validators.required],
      fechamedicamentos: ["", Validators.required],
      higienemedicamentos: ["", Validators.required],
      recordatorios: [],
    });
    this.mediciones = this.formBuilder.group({
      tipomediciones: ["", Validators.required],
      fechamediciones: ["", Validators.required],
      valormediciones: ["", Validators.required],
      notasmediciones: [""],
    });
    this.patologiasexistentes = this.formBuilder.group({
      nombrepatologiasexistentes: ["", Validators.required],
      fechapatologiasexistentes: ["", Validators.required],
      categoriapatologiasexistentes: ["", Validators.required],
      notaspatologiasexistentes: [""],
    });
    this.pdiagnostico = this.formBuilder.group({
      descripcionpdiagnostico: ["", Validators.required],
      fechapdiagnostico: ["", Validators.required],
      notaspdiagnostico: [""],
    });
    this.rnumericos = this.formBuilder.group({
      nombrernumericos: ["", Validators.required],
      fecharnumericos: ["", Validators.required],
      valorrnumericos: ["", Validators.required],
      margenrnumericos: ["", Validators.required],
      unidadrnumericos: ["", Validators.required],
    });
    this.testdiagnostico = this.formBuilder.group({
      nombretestdiagnostico: ["", Validators.required],
      fechatestdiagnostico: ["", Validators.required],
      resultadotestdiagnostico: ["", Validators.required],
      notastestdiagnostico: [""],
    });
    this.vacunacion = this.formBuilder.group({
      vacunavacunacion: ["", Validators.required],
      fechavacunacion: ["", Validators.required],
      /* higienevacunacion: ["", Validators.required], */
      notasvacunacion: [""],
      recordatorios: [],
    });
    this.visitasaveterinario = this.formBuilder.group({
      descripcionvisitasaveterinario: ["", Validators.required],
      fechavisitasaveterinario: ["", Validators.required],
      nombrevisitasaveterinario: ["", Validators.required],
      preciovisitasaveterinario: ["", Validators.required],
      notasvisitasaveterinario: [""],
    });
    this.testgeneticos = this.formBuilder.group({
      nombretestgeneticos: ["", Validators.required],
      resultacdotestgeneticos: ["", Validators.required],
      fechatestgeneticos: ["", Validators.required],
      notastestgeneticos: [""],
    });
  }
  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
  addRegistro(item) {
    console.log(item);
  }

  async presentPopover(e: any, item: any, tipo: string) {
    const popover = await this.popoverController.create({
      component: EditarRegistroSubMenuComponent,
      event: e,
      mode: "ios",
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    switch (data) {
      case "eliminar":
        this.eliminarRegistro(item, tipo);
        break;
      case "editar":
        this.editarRegistro(item, tipo);
        break;
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  async presentAlertToast(text: string) {
    const toast = await this.toastController.create({
      color: "danger",
      header: "ERROR!",
      position: "top",
      message: text,
      duration: 2000,
    });
    toast.present();
  }

  async eliminarRegistro(data: any, collection: string) {
    const alert = await this.alertCtrl.create({
      header: "Borrar registro",
      message: "Por favor confirme que desea borrar el registro.",
      buttons: [
        {
          text: "Cancelal",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah", data);
          },
        },
        {
          text: "Borrar",
          handler: () => {
            console.log("Confirm Okay");
            this.afs
              .deleteCollection(
                data.prodid,
                `CARTILLA-REGISTRO-${collection.toUpperCase()}`
              )
              .then(() => {
                this.presentToast("Registro borrado exitosamente.");
              });
          },
        },
      ],
    });

    await alert.present();
  }

  crearRecordatorios(
    lista: [],
    titulo: string,
    mascota: string,
    idRegistro: string
  ) {
    const mensaje = "Recordatorio pendiente.";

    for (let index = 0; index < lista.length; index++) {
      const element = lista[index];
      let date: dayjs.Dayjs;
      switch (element) {
        case "quincenal":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            15,
            "day"
          );
          break;
          case "mensual":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            30,
            "day"
          );
          break;
          case "trimestral":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            80,
            "day"
          );
          break;
          case "semestral":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            170,
            "day"
          );
          break;
        case "anual":
          date = dayjs(this.fechaSeleccionada + " 8", "YYYY-MM-DD H").subtract(
            360,
            "day"
          );
          break;
      }
      const recordatorio = new Recordatorio(
        mensaje,
        titulo,
        date.toDate().valueOf(),
        idRegistro
      );

      recordatorio.idDestino = mascota;
      recordatorio.ruta = "registro-medico";
      const user = JSON.parse(localStorage.getItem("usuario"));
      recordatorio.idReceptor = user.uid;
      this.recordatorioService.saveReminder(recordatorio);
    }
  }

  setFecha(f) {
    this.fechaSeleccionada = f.detail.value;
    console.log(this.fechaSeleccionada);
  }

  gerRegiterData(sufijo) {
    const str = sufijo;
    const resufijo = str.toUpperCase();
    console.log(resufijo);
    // const resufijo = sufijo;
    this.itemsCartilla = [];
    const resultaso = JSON.parse(localStorage.getItem("usuario"));
    if (resultaso != null) {
      const user: any = resultaso;
      // this.afs
      //   .getCollectionListDate(`CARTILLA-REGISTRO-${resufijo}`, this.navParams.data.uidmascota)
      //   .subscribe((result) => {
      //     const data: any = result;
      //     this.itemsCartilla = data;
      //     console.log("this.itemsCartilla: ", this.itemsCartilla);
      //   });
      this.afs
        .getCollectionListDateWhere(
          `CARTILLA-REGISTRO-${resufijo}`,
          "uidmascota",
          "==",
          this.navParams.data.uidmascota
        )
        .subscribe((response) => {
          this.itemsCartilla = response;
          console.log("this.itemsCartilla: ", this.itemsCartilla);
        });
    } else {
      this.util.presentToast("ERROR", "Credenciales correctas");
    }
  }

  fecha(fecha: string) {
    return dayjs(fecha, "YYYY-MM-DD").format("LL");
  }

  async editarRegistro(data: any, tipo: string) {
    console.log("editar", data);
    const modal = await this.modalController.create({
      component: EditarComponent,
      componentProps: {
        datos: data,
        tipo: tipo,
      },
    });
    modal.present();
    const datos: any = await modal.onWillDismiss();
    console.log(datos);
    if (datos.updated) {
      //this.gerRegiterData(datos.coleccion);
    }
  }

  getForm() {
    this.checked = "checked";
  }
  closeModal() {
    this.checked = "";
  }

  enviarDatos(collection: any, dato) {
    try {
      console.log("DATO ", dato);

      if (dato.valid) {
        const str = collection;
        const res = str.toUpperCase();
        const result = JSON.parse(localStorage.getItem("usuario"));

        console.log("RES : ", res);

        const recs = dato.value.recordatorios;

        if (res == "ESTROS") {
          let valid = dayjs(dato.value.fechainicioestros, "YYYY-MM-DD").isBefore(
            dayjs(dato.value.fechafinalestros, "YYYY-MM-DD"),
            "day"
          );
          if(!valid) {
            this.presentAlertToast("Verificar fechas.");
            return;
          } 
        }

        if (result != null) {
          const user: any = dato.value;
          user.uid = result.uid;
          user.createdAt = new Date();
          user.uidmascota = this.registromedico.uidmascota;
          this.afs
            .addCollection(`CARTILLA-REGISTRO-${res}`, user)
            .then((idRegistro) => {
              console.log("idRegistro: ", idRegistro);
              if (recs && recs.length > 0) {
                const titulo = "Recordatorio de " + collection;
                this.crearRecordatorios(
                  recs,
                  titulo,
                  this.registromedico.uidmascota,
                  idRegistro
                );
              }
              this.util.presentToast("PETICION", "Registro Agregado");
              this.closeModal();
            });
        } else {
          this.util.presentToast("ERROR", "Credenciales incorrectas");
        }
      } else {
        this.presentAlertToast("Falta completar datos.");
      }
    } catch (error) {
      console.error("Error ---> ", error);
    }
  }
}
