import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortName"
})
export class ShortNamePipe implements PipeTransform {
  transform(value: string): string {
    const one = value.indexOf(" ");
    const str = value.substr(one, value.indexOf(" ") + 1);
    if (one > -1 && typeof str === "string" ) {
      return (value.substr(
        0,
        one
      ) + str);
    } else {
      return value; 
    }
  }
}
