import { Hora } from "./hora";

export class Dia {
  id: string;
  dia: string;
  orden: string;
  horario: string;
  horas = {};

  constructor() {
    //this.horas = [];
    for (let index = 0; index < 24; index++) {
      const hora = new Hora();
      hora.id = index;
      hora.hora = index + ":00 - " + (index + 1) + ":00";
      hora.estado = false;
      this.horas[index] = hora;
    }
  }
}
