import * as dayjs from "dayjs";
export class Recordatorio {
  id: string;
  mensaje: string;
  titulo: string;
  ruta: string;
  idDestino: string;
  idReceptor: string;
  idRegistro: string;
  ms: number;

  constructor(mensaje: string, titulo: string, ms: number, idRegistro: string) {
    this.mensaje = mensaje;
    this.titulo = titulo;
    this.ms = ms;
    this.idRegistro = idRegistro;
  }

  date() {
    return new Date(this.ms);
  }

  fechaCorta() {
    return dayjs(new Date(this.ms)).format("DD MMM, YYYY HH:mm A");
  }
  fecha() {
    return dayjs(new Date(this.ms)).format("DD/MM/YYYY");
  }
}
