<ion-content class="dark">
    <ion-icon
        *ngIf="mostrarBotonCerrarPublicidad"
        name="close-circle-outline"
        class="botonSalir"
        size="large"
        (click)="cerrarModal()"
    ></ion-icon>

    <swiper-container pager="false" [options]="slideOpts" class="full dark">
        <swiper-slide class="dark" *ngFor="let ad of publicidad;">
            <ion-row class="ion-justify-content-center ion-align-items-center">
                <ion-spinner
                    name="crescent"
                    class="spin"
                    *ngIf="spinner"
                    color="light"
                ></ion-spinner>
                <ion-img
                    (ionImgDidLoad)="load()"
                    class="full"
                    src="{{ad.imagenURL[0].downloadURL}}"
                ></ion-img>
                <ion-button
                    *ngIf="visible"
                    expand="block"
                    color="secondary"
                    href="{{ad.productoURL}}"
                    target="_blank"
                    class="button"
                >
                    <ion-label>{{ad.textoboton}}</ion-label>
                </ion-button>
            </ion-row>
        </swiper-slide>
    </swiper-container>
</ion-content>
