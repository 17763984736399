import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FCM } from "@awesome-cordova-plugins/fcm/ngx";
import { throwError } from "rxjs";
import { Cita } from "../interfaces/cita";
import { FirebaseService } from "./firebase/firebase.service";

@Injectable({
    providedIn: "root",
})
export class NotificationServiceService {
    constructor(
        public fcm: FCM,
        private afs: FirebaseService,
        private http: HttpClient
    ) {}

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:
                "key=AAAAI4oiDBE:APA91bFYhdqgCan0RtMZSvJ_VX0B3zqdHlGvAsvPVVe-HkKJ0tS-0evs4k8WgCs9iYjZJZ3l82-5LORFtrQGHK8OLycgH1xls18VgjZI4Xe0udmyhGRHYwPKf2I81vKdXxCPzdtsR_6C",
        }),
    };

    // Handle API errors
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError("Something bad happened; please try again later.");
    }

    private enviarNotificacion(
        token: any,
        titulo: string,
        mensaje: string,
        idDestino: string,
        ruta: string,
        notificacionId: string
    ) {
        const data = {
            data: {
                mensaje: mensaje,
                idDestino: idDestino,
                ruta: ruta,
                titulo: titulo,
                title: titulo,
                body: mensaje,
                notifId: notificacionId,
                click_action: "FCM_PLUGIN_ACTIVITY",
                sound: "default",
                icon: "fcm_push_icon",
            },
            notification: {
                title: titulo,
                body: mensaje,
                sound: "default",
                soundname: "default",
                click_action: "FCM_PLUGIN_ACTIVITY",
                icon: "fcm_push_icon",
            },
            click_action: "FCM_PLUGIN_ACTIVITY",
            to: token.token,
            priority: "high",
            direct_book_ok: true,
        };

        return this.http
            .post(
                "https://fcm.googleapis.com/fcm/send",
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe((data) => {
                console.log(data);
            });
    }

    async guardarNotificacion(
        titulo: string,
        mensaje: string,
        idDestino: string,
        ruta: string,
        idReceptor: string
    ) {
        const notif = {
            mensaje: mensaje,
            idDestino: idDestino,
            ruta: ruta,
            titulo: titulo,
            leida: false,
            ms: new Date().valueOf(),
            idReceptor: idReceptor,
        };
        const id = await this.afs.addCollection("NOTIFICACIONES", notif);
        console.log("NOTIF ID ", id);
        return id;
    }

    notificarNuevaCita(cita: Cita, idReceptor: string, idCita: string) {
        const titulo = "HAS RECIBIDO UNA NUEVA SOLICITUD DE CITA";
        const mensaje =
            cita.nombre +
            " ha solicitado una nueva cita el " +
            cita.dia +
            ", " +
            cita.fechaLarga();
        this.afs
            .getCollectionListDateWhere(
                "FCM-TOKENS",
                "idUsuario",
                "==",
                idReceptor
            )
            .subscribe((tokens) => {
                this.guardarNotificacion(
                    titulo,
                    mensaje,
                    idCita,
                    "citas",
                    idReceptor
                ).then((notificacionId) => {
                    for (let index = 0; index < tokens.length; index++) {
                        console.log(tokens[index]);
                        this.enviarNotificacion(
                            tokens[index],
                            titulo,
                            mensaje,
                            idCita,
                            "citas",
                            notificacionId
                        );
                    }
                });
            });
    }

    notificarCitaAceptada(
        cita: Cita,
        idReceptor: string,
        nombreUbicacion: string
    ) {
        const titulo = "Cita aceptada";
        const mensaje =
            nombreUbicacion +
            " ha aceptado su cita con fecha: " +
            cita.dia +
            ", " +
            cita.fechaLarga();
        this.afs
            .getCollectionListDateWhere(
                "FCM-TOKENS",
                "idUsuario",
                "==",
                idReceptor
            )
            .subscribe((tokens) => {
                this.guardarNotificacion(
                    titulo,
                    mensaje,
                    cita.prodid,
                    "citas",
                    idReceptor
                ).then((notificacionId) => {
                    for (let index = 0; index < tokens.length; index++) {
                        console.log(tokens[index]);
                        this.enviarNotificacion(
                            tokens[index],
                            titulo,
                            mensaje,
                            cita.prodid,
                            "citas",
                            notificacionId
                        );
                    }
                });
            });
    }

    notificarCitaCancelada(
        cita: Cita,
        idReceptor: string,
        nombreUbicacion: string
    ) {
        const titulo = "Cita cancelada";
        const mensaje =
            nombreUbicacion +
            " ha cancelado su cita con fecha: " +
            cita.dia +
            ", " +
            cita.fechaLarga();
        this.afs
            .getCollectionListDateWhere(
                "FCM-TOKENS",
                "idUsuario",
                "==",
                idReceptor
            )
            .subscribe((tokens) => {
                this.guardarNotificacion(
                    titulo,
                    mensaje,
                    cita.prodid,
                    "citas",
                    idReceptor
                ).then((notificacionId) => {
                    for (let index = 0; index < tokens.length; index++) {
                        this.enviarNotificacion(
                            tokens[index],
                            titulo,
                            mensaje,
                            cita.prodid,
                            "citas",
                            notificacionId
                        );
                    }
                });
            });
    }
}
