<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-title color="tertiary">{{title}}</ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()">
                <ion-icon color="tertiary" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary" *ngIf="!stateBussines">
            <ion-button (click)="stateBussines=true">
                <ion-icon
                    slot="icon-only"
                    color="secondary"
                    name="add"
                ></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <div [hidden]="stateBussines" class="center" style="margin-bottom: 2%">
        <div *ngIf="myBusness.length === 0" style="text-align: center">
            <div>
                <img src="/assets/no-results.png" alt="" srcset="" />
            </div>
            <div>No tienes negocios creados</div>
            <ion-button (click)="stateBussines=true">
                Crear Negocio
            </ion-button>
        </div>

        <div *ngFor="let item of myBusness" class="tarjeta-negocio">
            <ion-grid class="businnes bus ion-padding">
                <ion-row>
                    <ion-item-sliding>
                        <ion-grid class="no-pad">
                            <ion-row>
                                <ion-col
                                    class=""
                                    sizeXs="12"
                                    sizeSm="6"
                                    style="width: 100%"
                                >
                                    <img
                                        style="border-radius: 1rem"
                                        src="{{item.foto}}"
                                        alt="{{item.nombre_negocio}}"
                                    />
                                </ion-col>
                                <ion-col class="no-pad" sizeXs="12" sizeSm="6">
                                    <ion-grid class="no-pad acomodo-info-card">
                                        <ion-row
                                            class="text-center ion-align-items-center tipo-negocio"
                                        >
                                            <ion-col class="no-pad">
                                                <ion-label
                                                    >[ {{item.businessType}}
                                                    ]</ion-label
                                                >
                                            </ion-col>
                                        </ion-row>
                                        <ion-row
                                            class="text-center ion-align-items-center nombre-negocio"
                                        >
                                            <ion-col>
                                                <ion-label
                                                    >{{item.nombre_negocio}}</ion-label
                                                >
                                            </ion-col>
                                        </ion-row>
                                        <ion-row
                                            class="text-center ion-align-items-end colocar-abajo"
                                        >
                                            <ion-col class="no-pad itemsito">
                                                <ion-item>
                                                    <ion-toggle
                                                        style="
                                                            text-align: center;
                                                        "
                                                        label="Activo"
                                                        name="estado"
                                                        color="success"
                                                        disabled="{{!item.editable}}"
                                                        checked="{{item.estado}}"
                                                        (ionChange)="updatedStart(item)"
                                                    ></ion-toggle>
                                                    <ion-label>
                                                        <span
                                                            class="{{item.estado ? 'ABIERTO' : 'CERRADO' }}"
                                                            >{{item.estado ?
                                                            'ACTIVO' :
                                                            'DESACTIVADO'
                                                            }}</span
                                                        ></ion-label
                                                    >
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </ion-grid>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-item-sliding>
                </ion-row>
                <ion-row>
                    <ion-col size="6">
                        <ion-button
                            color="danger"
                            expand="block"
                            id="present-alert"
                            (click)="setCurrentBusiness(item)"
                            >Borrar</ion-button
                        >
                    </ion-col>
                    <ion-alert
                        trigger="present-alert"
                        header="¡Atención!"
                        subHeader="Esta acción es irreversible"
                        message="¿Quieres borrar este negocio?"
                        [buttons]="alertButtons"
                    ></ion-alert>
                    <ion-col size="6">
                        <ion-button
                            color="primary"
                            expand="block"
                            (click)="detailBusness(item);stateBussines=true;estadoFrom='editar';ubicacion=item;"
                            >Editar
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
    <div [hidden]="!stateBussines">
        <ion-grid class="ion-padding">
            <ion-row class="img text-center">
                <ion-col>
                    <img *ngIf="image && !cameraLoading" [src]="image" alt="" />
                </ion-col>
                <ion-col size="12" class="spin-center">
                    <ion-spinner *ngIf="cameraLoading"></ion-spinner>
                </ion-col>
                <ion-col size="12" *ngIf="!image && !cameraLoading">
                    <button
                        class="center isbackground"
                        color="quad"
                        (click)="presentActionSheet()"
                        style="
                            border-radius: 0px !important;
                            --border-radius: 0;
                        "
                    >
                        <ion-icon class="size" name="camera"></ion-icon>
                    </button>
                </ion-col>
                <ion-col
                    size="12"
                    class="ion-text-center"
                    *ngIf="!image && !cameraLoading"
                >
                </ion-col>
            </ion-row>
            <ion-row class="ion-padding">
                <ion-col size="12" class="destino"> </ion-col>
                <ion-col size="12">
                    <ion-button
                        color="secondary"
                        (click)="presentActionSheet()"
                    >
                        <ion-icon
                            slot="icon-only"
                            name="camera"
                            color="light"
                            style="margin-right: 1rem"
                        ></ion-icon>
                        Subir foto
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>

        <form [formGroup]="dataForm">
            <ion-grid>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-select
                                label="Tipo de Negocio (Requerido)"
                                labelPlacement="stacked"
                                name="tiponegocio"
                                formControlName="businessType"
                                placeholder="Seleccione"
                                cancelText="Cancelar"
                            >
                                <ion-select-option value="veterinaria"
                                    >Veterinaria</ion-select-option
                                >
                                <ion-select-option value="estética"
                                    >Estética</ion-select-option
                                >
                                <ion-select-option value="proveedor"
                                    >Proveedor</ion-select-option
                                >
                                <ion-select-option value="otros"
                                    >Otro</ion-select-option
                                >
                            </ion-select>
                        </ion-item>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Nombre del negocio (Requerido)"
                                labelPlacement="floating"
                                name="nombre_negocio"
                                formControlName="nombre_negocio"
                                type="text"
                                maxlength="60"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-textarea
                                label="Descripción (Requerido)"
                                labelPlacement="floating"
                                name="descripcion"
                                formControlName="descripcion"
                                type="text"
                            ></ion-textarea>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Dirección (Requerido)"
                                labelPlacement="floating"
                                #googlePlaces
                                name="direccion"
                                formControlName="direccion"
                                id="googlePlaces"
                                [(ngModel)]="direccion"
                            ></ion-input>
                        </ion-item>

                        <h3
                            style="
                                padding: 10px 0px;
                                text-align: center;
                                font-size: 1rem;
                            "
                        >
                            <i
                                >*Puedes arrastrar el pin a donde lo necesites o
                                escribir tu dirección*</i
                            >
                        </h3>

                        <div
                            id="businessMap"
                            #businessMap
                            style="height: 400px"
                        ></div>
                    </ion-col>
                </ion-row>
                <ion-row
                    class="ion-justify-content-center"
                    *ngIf="dataForm.value.businessType == 'veterinaria'"
                >
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Nombre(s) Médico Responsable (Requerido)"
                                labelPlacement="floating"
                                name="nombreResponsable"
                                formControlName="nombreResponsable"
                                type="text"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Apellido Paterno del Médico Responsable
                                (Requerido)"
                                labelPlacement="floating"
                                name="paternoResponsable"
                                formControlName="paternoResponsable"
                                type="text"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Apellido Materno del Médico Responsable
                                (Requerido)"
                                labelPlacement="floating"
                                name="maternoResponsable"
                                formControlName="maternoResponsable"
                                type="text"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-input
                                label="Cédula Profesional (Requerido)"
                                labelPlacement="floating"
                                name="cedula"
                                formControlName="cedula"
                                type="text"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-button
                            (click)="botonVerificarCedula()"
                            [color]="botonCedulaColor"
                            [disabled]="checkCedulaButton()"
                            >{{botonCedulaString}}</ion-button
                        >
                        <ion-toast
                            [isOpen]="showCedulaVerificada"
                            message="¡Cédula Verificada!"
                            position="middle"
                            class="customToast"
                            [duration]="2800"
                            (click)="toastDismiss()"
                            color="success"
                            icon="checkmark-circle"
                        ></ion-toast>
                        <ion-toast
                            [isOpen]="showCedulaRepetida"
                            message="¡Esta cédula se ha utilizado más de 3 veces! Por favor contáctanos para verificar tu información"
                            position="middle"
                            layout="stacked"
                            class="customToast"
                            [duration]="30000"
                            (click)="toastDismiss()"
                            color="danger"
                            [buttons]="toastButtons"
                        ></ion-toast>
                        <ion-toast
                            [isOpen]="showCedulaError"
                            message="¡Parece que tus datos son inválidos! Verifica tus datos y prueba de nuevo"
                            position="middle"
                            class="customToast"
                            [duration]="3200"
                            (click)="toastDismiss()"
                            icon="warning"
                            color="danger"
                        ></ion-toast>
                    </ion-col>
                </ion-row>
                <div
                    *ngIf="this.cedulaVerificada || this.ubicacion.cedulaVerificada || dataForm.value.businessType != 'veterinaria'"
                >
                    <ion-row>
                        <ion-col size="12">
                            <ion-item class="form-vetlook">
                                <ion-input
                                    label="Responsable (Requerido)"
                                    labelPlacement="floating"
                                    name="responsablemedico"
                                    formControlName="responsibleDoctor"
                                    type="text"
                                ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-item class="form-vetlook">
                                <ion-input
                                    label="Teléfono (Requerido)"
                                    labelPlacement="floating"
                                    name="telefono"
                                    formControlName="telefono"
                                    type="text"
                                ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-item class="form-vetlook">
                                <ion-button
                                    class="padding-button"
                                    (click)="presentHorarioModal()"
                                    size="small"
                                >
                                    <ion-icon
                                        slot="icon-only"
                                        name="calendar"
                                        color="light"
                                        class="ion-padding"
                                    ></ion-icon>
                                    Establecer Horarios
                                </ion-button>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row
                        class="ion-align-items-center ion-justify-content-center"
                    >
                        <ion-col sizeLg="2" sizeXs="12" class="ion-padding">
                            <ion-img
                                [src]="pinImg"
                                style="
                                    width: 192px;
                                    margin-left: auto;
                                    margin-right: auto;
                                "
                                alt="VetlookPin"
                            ></ion-img>
                        </ion-col>
                        <ion-col sizeLg="4" sizeXs="12">
                            <ion-row
                                class="ion-padding ion-justify-content-center"
                            >
                                <ion-label>Elige el color de tu pin</ion-label>
                            </ion-row>
                            <ion-row
                                class="ion-padding ion-justify-content-center"
                            >
                                <color-circle
                                    [circleSize]="48"
                                    style="
                                        width: 192px;
                                        margin-left: auto;
                                        margin-right: auto;
                                    "
                                    [circleSpacing]="24"
                                    [color]="colorMarcador"
                                    (onChangeComplete)="changeColor($event)"
                                    [colors]="colors"
                                ></color-circle>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-item class="form-vetlook">
                                <ion-input
                                    label="Sitio Web (Opcional)"
                                    labelPlacement="floating"
                                    name="sitio_web"
                                    formControlName="sitio_web"
                                    type="text"
                                ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col size="12">
                            <ion-item>
                                <h2>Servicios (Requerido)</h2>
                            </ion-item>
                        </ion-col>
                        <ion-col size="12">
                            <ion-item
                                *ngIf="dataForm.value.businessType == 'veterinaria'"
                            >
                                <ion-label>{{vetLabel}}</ion-label>
                                <ionic-selectable
                                    [(ngModel)]="selectedVetServices"
                                    [items]="servicesVet"
                                    #vet
                                    headerColor="primary"
                                    closeButtonText="Cancelar"
                                    confirmButtonText="Guardar"
                                    [searchFailText]="'No se encontró el servicio'"
                                    searchPlaceholder="Buscar"
                                    [ngModelOptions]="{standalone: true}"
                                    [isMultiple]="true"
                                    [canSearch]="true"
                                    (onChange)="servicesVetChanged($event)"
                                    (onSelect)="servicesVetSelected($event)"
                                >
                                    <ng-template ionicSelectableHeaderTemplate>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button
                                                    color="secondary"
                                                    class="btn"
                                                    (click)="vet.close()"
                                                >
                                                    {{vet.closeButtonText}}
                                                </ion-button>
                                            </ion-buttons>

                                            <ion-title>
                                                {{vetLabel}}
                                            </ion-title>
                                        </ion-toolbar>
                                    </ng-template>
                                </ionic-selectable>
                            </ion-item>
                            <ion-item>
                                <ion-label>{{generalLabel}}</ion-label>
                                <ionic-selectable
                                    [(ngModel)]="selectedServices"
                                    #services
                                    [items]="servicesNormal"
                                    headerColor="primary"
                                    closeButtonText="Cancelar"
                                    confirmButtonText="Guardar"
                                    [searchFailText]="'No se encontró el servicio'"
                                    searchPlaceholder="Buscar"
                                    [ngModelOptions]="{standalone: true}"
                                    [isMultiple]="true"
                                    [canSearch]="true"
                                    (onChange)="servicesChanged($event)"
                                    (onSelect)="servicesSelected($event)"
                                >
                                    <ng-template ionicSelectableHeaderTemplate>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button
                                                    color="secondary"
                                                    class="btn"
                                                    (click)="services.close()"
                                                >
                                                    {{services.closeButtonText}}
                                                </ion-button>
                                            </ion-buttons>

                                            <ion-title>
                                                {{generalLabel}}
                                            </ion-title>
                                        </ion-toolbar>
                                    </ng-template>
                                </ionic-selectable>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-item>
                                <h2>Productos</h2>
                            </ion-item>
                        </ion-col>
                        <ion-col size="12">
                            <ion-item>
                                <ion-label
                                    >{{alimentoLabel}} (Opcional)</ion-label
                                >
                                <ionic-selectable
                                    class="divider"
                                    [(ngModel)]="selectedAlimento"
                                    #alimento
                                    [items]="alimentoMascotas"
                                    itemValueField="nombre"
                                    itemTextField="nombre"
                                    groupValueField="marca"
                                    groupTextField="marca"
                                    headerColor="primary"
                                    closeButtonText="Cancelar"
                                    confirmButtonText="Guardar"
                                    [searchFailText]="'No se encontró el alimento'"
                                    searchPlaceholder="Buscar"
                                    [isMultiple]="true"
                                    [ngModelOptions]="{standalone: true}"
                                    [canSearch]="true"
                                    (onChange)="alimentoChanged($event)"
                                    (onSelect)="alimentoSelected($event)"
                                >
                                    <ng-template ionicSelectableHeaderTemplate>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button
                                                    color="secondary"
                                                    class="btn"
                                                    (click)="alimento.close()"
                                                >
                                                    {{alimento.closeButtonText}}
                                                </ion-button>
                                            </ion-buttons>

                                            <ion-title style="font-size: 0.9em">
                                                {{alimentoLabel}}
                                            </ion-title>
                                        </ion-toolbar>
                                    </ng-template>
                                </ionic-selectable>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-item>
                                <h2>Accesorios</h2>
                            </ion-item>
                        </ion-col>
                        <ion-col size="12">
                            <ion-item>
                                <ion-label
                                    >{{accesoriosLabel}} (Opcional)</ion-label
                                >
                                <ionic-selectable
                                    [(ngModel)]="selectedAccesorios"
                                    #accesorios
                                    [items]="productosAccesorios"
                                    headerColor="primary"
                                    closeButtonText="Cancelar"
                                    confirmButtonText="Guardar"
                                    [searchFailText]="'No se encontró el accesorio'"
                                    searchPlaceholder="Buscar"
                                    [isMultiple]="true"
                                    [canSearch]="true"
                                    (onChange)="accesoriosChanged($event)"
                                    (onSelect)="accesoriosSelected($event)"
                                    [ngModelOptions]="{standalone: true}"
                                >
                                    <ng-template ionicSelectableHeaderTemplate>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button
                                                    color="secondary"
                                                    class="btn"
                                                    (click)="accesorios.close()"
                                                >
                                                    {{accesorios.closeButtonText}}
                                                </ion-button>
                                            </ion-buttons>

                                            <ion-title>
                                                {{accesoriosLabel}}
                                            </ion-title>
                                        </ion-toolbar>
                                    </ng-template>
                                </ionic-selectable>
                            </ion-item>
                        </ion-col>
                        <ion-col size="12">
                            <ion-item>
                                <ion-label
                                    >{{estilistasLabel}} (Opcional)</ion-label
                                >
                                <ionic-selectable
                                    [(ngModel)]="selectedEstilistas"
                                    #estilista
                                    [items]="productosEstilista"
                                    headerColor="primary"
                                    closeButtonText="Cancelar"
                                    confirmButtonText="Guardar"
                                    [searchFailText]="'No se encontró el servicio'"
                                    searchPlaceholder="Buscar"
                                    [isMultiple]="true"
                                    [canSearch]="true"
                                    (onChange)="estilistaChanged($event)"
                                    (onSelect)="estilistaSelected($event)"
                                    [ngModelOptions]="{standalone: true}"
                                >
                                    <ng-template ionicSelectableHeaderTemplate>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button
                                                    color="secondary"
                                                    class="btn"
                                                    (click)="estilista.close()"
                                                >
                                                    {{estilista.closeButtonText}}
                                                </ion-button>
                                            </ion-buttons>

                                            <ion-title>
                                                {{estilistasLabel}}
                                            </ion-title>
                                        </ion-toolbar>
                                    </ng-template>
                                </ionic-selectable>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="6">
                            <ion-button
                                expand="block"
                                color="light"
                                class="btn"
                                (click)="estadoFrom='editar';stateBussines=false;"
                            >
                                <ion-label>Cancelar</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="6">
                            <ion-button
                                expand="block"
                                color="secondary"
                                class="btn"
                                (click)="upload(estadoFrom,myNegocio)"
                            >
                                <ion-label
                                    >{{estadoFrom==='guardar' ? 'Guardar' :
                                    'Editar'}}</ion-label
                                >
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </div>
            </ion-grid>
        </form>
    </div>
</ion-content>
<ion-footer [translucent]="true">
    <div
        style="height: env(safe-area-inset-bottom); background-color: white"
    ></div>
</ion-footer>
