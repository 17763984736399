<ion-header class="ion-no-border">
    <ion-toolbar *ngIf="perfilData">
        <ion-title color="tertiary">Mi Perfil</ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()">
                <ion-icon color="tertiary" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div *ngIf="!perfilData" style="text-align: center">
        <img src="/assets/loading-pet.gif" alt="" srcset="" />
    </div>
    <div *ngIf="perfilData">
        <ion-grid>
            <ion-row>
                <ion-col class="ion-text-start">
                    <ion-avatar class="avatar-center">
                        <img
                            src="{{(perfilData.photoURL)?perfilData.photoURL:'/assets/placeholder.jpg'}}"
                        />
                        <div
                            class="camera-perfil"
                            (click)="presentActionSheet()"
                        >
                            <ion-icon name="camera"></ion-icon>
                        </div>
                    </ion-avatar>
                </ion-col>
                <ion-col size="12">
                    <p class="pagetext headerText">
                        {{ (perfilData.fullName ||
                        perfilData.responsibleDoctor)}}
                    </p>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <div class="spin-center">
                        <ion-spinner *ngIf="cameraLoading"></ion-spinner>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
        <form [formGroup]="perfilDataForm">
            <ion-grid>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook" *ngIf="perfilData.name">
                            <ion-label color="dark" position="stacked">
                                Nombre
                            </ion-label>
                            <ion-input
                                name="name"
                                formControlName="name"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.lastName"
                        >
                            <ion-label color="dark" position="stacked">
                                Apellidos
                            </ion-label>
                            <ion-input
                                name="lastName"
                                formControlName="lastName"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="perfilData.typeUser !== 'usuario'">
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.businessType == 'veterinaria'"
                        >
                            <ion-label color="dark" position="stacked">
                                Medico Veterinario Responsable
                            </ion-label>
                            <ion-input
                                name="responsablemedico"
                                formControlName="responsibleDoctor"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.businessType == 'veterinaria'"
                        >
                            <ion-label color="dark" position="stacked">
                                Medico Veterinario Responsable
                            </ion-label>
                            <ion-input
                                name="responsablemedico"
                                formControlName="responsibleDoctor"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.businessType == 'veterinaria'"
                        >
                            <ion-label color="dark" position="stacked">
                                Cédula Profesional
                            </ion-label>
                            <ion-input
                                name="cedula"
                                formControlName="cedula"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.businessType == 'otro'"
                        >
                            <ion-label color="dark" position="stacked">
                                Responsable
                            </ion-label>
                            <ion-input
                                name="responsablemedico"
                                formControlName="responsibleDoctor"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-label color="dark" position="stacked">
                                Teléfono de contacto (opcional)
                            </ion-label>
                            <ion-input
                                name="telefonocontacto"
                                formControlName="telephoneContact"
                                placeholder="Diez dígitos"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-label color="dark" position="stacked">
                                Código Postal (opcional)
                            </ion-label>
                            <ion-input
                                name="zipCode"
                                formControlName="zipCode"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.typeUser == 'usuario'"
                        >
                            <ion-label color="dark" position="stacked">
                                Teléfono (opcional)
                            </ion-label>
                            <ion-input
                                name="phoneNumber"
                                formControlName="phoneNumber"
                                placeholder="Diez dígitos"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item
                            class="form-vetlook"
                            *ngIf="perfilData.typeUser == 'usuario'"
                        >
                            <ion-label color="dark" position="stacked">
                                Fecha de nacimiento
                            </ion-label>
                            <ion-input
                                name="fechanaci"
                                formControlName="dateBirth"
                                type="date"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-label color="dark" position="stacked">
                                Correo Electrónico
                            </ion-label>
                            <ion-input
                                formControlName="email"
                                type="text"
                                name="email"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="form-vetlook">
                            <ion-label color="dark" position="stacked">
                                Dirección (opcional)
                            </ion-label>
                            <ion-input
                                name="direccion"
                                formControlName="address"
                                type="text"
                                (ionBlur)="updateIsFix(true)"
                            ></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</ion-content>
<ion-footer style="margin-bottom: env(safe-area-inset-bottom)">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button
                    expand="block"
                    color="primary"
                    (click)="actualizar(perfilDataForm)"
                    class="btn"
                    [disabled]="!isFix"
                    style="margin-top: 20px"
                >
                    <ion-label>Guardar</ion-label>
                </ion-button>
            </ion-col>
            <ion-col>
                <ion-button
                    expand="block"
                    color="danger"
                    fill="outline"
                    (click)="borrarUsuario()"
                    class="btn"
                    style="margin-top: 20px"
                >
                    <ion-label>{{warning}}</ion-label>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
