import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-editar-registro-sub-menu',
  templateUrl: './editar-registro-sub-menu.component.html',
  styleUrls: ['./editar-registro-sub-menu.component.scss'],
})
export class EditarRegistroSubMenuComponent implements OnInit {

  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}

  onclick(valor: string) {
    this.popoverController.dismiss(valor);
  }

}
