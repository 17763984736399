import { Component, Input, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { Cita } from "src/app/interfaces/cita";
import { FirebaseService } from "src/app/services/firebase/firebase.service";
import { NotificationServiceService } from "src/app/services/notification-service.service";

@Component({
  selector: "app-detalles",
  templateUrl: "./detalles.component.html",
  styleUrls: ["./detalles.component.scss"], 
})
export class DetallesComponent implements OnInit {
  @Input() cita: Cita;
  @Input() ubicacion: any;
  public mascota: any;
  private cancelada: boolean;
  constructor(
    private modalCtrl: ModalController,
    private firebase: FirebaseService,
    private alertController: AlertController,
    private notifs: NotificationServiceService,
    private toastController: ToastController
  ) {
    this.cancelada = false;
  }

  async closeModal() {
    await this.modalCtrl.dismiss({
      cancelada: this.cancelada,
    });
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastController.create({
      message: mensaje,
      duration: 2000,
    });
    toast.present();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: "Confirmar cancelación",
      message: "¿Cancelar esta cita?",
      buttons: [
        {
          text: "Cerrar",
          role: "cancel",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Cancelar cita",
          handler: () => this.cancelarCita(),
        },
      ],
    });

    await alert.present();
  }

  aceptarCita(cita: Cita) {
    if (cita.status != "Cancelada") {
      console.log("Aprobar");
      this.firebase
        .updateCollection(this.cita.prodid, "CITAS", { status: "Aceptada" })
        .then(() => {
          this.notifs.notificarCitaAceptada(
            this.cita,
            this.cita.idUsuario,
            this.ubicacion.nombre
          );
          this.presentToast("Cita aceptada");
          this.closeModal();
        });
    }
  }

  cancelarCita() {
    this.firebase.updateCollection(this.cita.prodid, "CITAS", { status: "Cancelada" }).then(() => {
      this.cancelada = true;
      this.notifs.notificarCitaCancelada(
        this.cita,
        this.cita.idUsuario,
        this.ubicacion.nombre
      );
      this.closeModal();
    });
  }

  ngOnInit() {
    this.firebase
      .getCollectionListDateWhere(
        "MASCOTAS",
        "prodid",
        "==",
        this.cita.idMascota
      )
      .subscribe((response) => {
        this.mascota = response[0];
      });
  }
}
