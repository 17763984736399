import { Component, OnInit, Input } from "@angular/core";
import { Usuario } from "./../interfaces/user";
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
} from "@angular/forms";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import { ActionSheetController } from "@ionic/angular";
import { FirebaseService } from "../services/firebase/firebase.service";
import { UtilService } from "./../services/util.service";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { AppserviceService } from "../services/appservice.service";
import dayjs from "dayjs";
import "dayjs/locale/es";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as auth from "firebase/compat/auth";
import * as firebase from "firebase/compat/app";

dayjs.extend(localizedFormat);
dayjs.locale("es");

@Component({
    selector: "app-perfil",
    templateUrl: "./perfil.page.html",
    styleUrls: ["./perfil.page.scss"],
})
export class PerfilPage implements OnInit {
    public perfilData: Usuario = null;
    uid: string;
    image: any;
    imgBase64: any;
    public isFix = false;
    public factura: any;
    public perfilDataForm: UntypedFormGroup;
    public counter = 0;
    public warning = "Borrar cuenta";

    public cameraLoading: boolean = false;
    @Input() user: any;
    constructor(
        public modalController: ModalController,
        private navParams: NavParams,
        private afs: FirebaseService,
        private utilService: UtilService,
        public toastController: ToastController,
        public actionSheetCtrl: ActionSheetController,
        public camera: Camera,
        private storage: AngularFireStorage,
        private formBuilder: UntypedFormBuilder,
        public storageService: AppserviceService
    ) {}

    ngOnInit() {
        this.uid = this.user.uid;
        this.getUser();
    }

    initForm(data: Usuario) {
        this.perfilDataForm = this.formBuilder.group({
            name: data.name,
            lastName: data.lastName,
            fullName: data.fullName,
            displayName: data.displayName,
            phoneNumber: data.phoneNumber,
            dateBirth: data.dateBirth,
            cedula: data.cedula,
            address: data.address,
            email: new UntypedFormControl({
                value: data.email,
                disabled: true,
            }),
            membership: data.membership,
            providerData: data.providerData,
            responsibleDoctor: data.responsibleDoctor,
            telephoneContact: data.telephoneContact,
            typeUser: data.typeUser,
            businessType: data.businessType,
            zipCode: data.zipCode,
        });
    }

    getUser() {
        this.afs
            .getCollectionDetail(this.uid, "USUARIOS")
            .subscribe((usuario) => {
                if (usuario) {
                    const data: any = usuario;
                    this.storageService.setObject("usuario", usuario);
                    this.perfilData = data;
                    this.initForm(this.perfilData);
                    // this.storageService.setObject("usuario", this.perfilData);
                    this.factura = localStorage.getItem("FACTURA")
                        ? JSON.parse(localStorage.getItem("FACTURA"))
                        : this.perfilData.facturaStripe;
                    if (this.perfilData.facturaStripe) {
                        this.factura = this.perfilData.stripe;
                    }
                }
            });
    }

    updateIsFix(value: boolean) {
        this.isFix = value;
    }

    public presentActionSheet() {
        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            allowEdit: false,
            correctOrientation: true,
        };
        this.actionSheetCtrl
            .create({
                header: "Seleccionar fuente de imagen",
                buttons: [
                    {
                        text: "Desde la Galeria",
                        icon: "images",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.PHOTOLIBRARY;
                            this.takeImage(options);
                        },
                    },
                    {
                        text: "Desde la Cámara",
                        icon: "camera",
                        handler: () => {
                            options.sourceType =
                                this.camera.PictureSourceType.CAMERA;
                            this.takeImage(options);
                        },
                    },
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                ],
            })
            .then((res) => res.present());
    }
    takeImage(option) {
        this.cameraLoading = true;
        this.camera.getPicture(option).then(
            (img) => {
                this.imgBase64 = img;
                console.log(img);
                this.image = "data:image/jpeg;base64," + img;
                this.upload();
            },
            (err) => {
                console.log(err);
                this.cameraLoading = false;
            }
        );
        // .then(img => {
        // this.image = window.Ionic.WebView.convertFileSrc(img);
        // });
    }
    upload() {
        const storageRef = this.storage.storage.ref();
        // Create a timestamp as filename
        const filename = Math.floor(Date.now() / 1000);

        // Create a reference to 'images/todays-date.jpg'
        const imageRef = storageRef.child(`mascotas/${filename}.jpg`);

        imageRef.putString(this.imgBase64, "base64").then((snapshot) => {
            // Do something here when the data is succesfully uploaded!
            this.utilService.presentToast("EXITO", "IMAGEN CARGADA");
            imageRef
                .getDownloadURL()
                .then((url) => {
                    const obj = {
                        photoURL: url,
                    };
                    this.afs.updateCollection(this.uid, "USUARIOS", obj);
                    this.cameraLoading = false;
                })
                .catch((error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    console.log("---", error);
                    this.cameraLoading = false;
                    switch (error.code) {
                        case "storage/object-not-found":
                            this.utilService.presentToast(
                                "ERROR",
                                "No se encontro imagen"
                            );
                            // File doesn't exist
                            break;

                        case "storage/unauthorized":
                            this.utilService.presentToast(
                                "ERROR",
                                "No se tiene permiso para ingresar a las fotos"
                            );
                            // User doesn't have permission to access the object
                            break;

                        case "storage/canceled":
                            this.utilService.presentToast(
                                "ERROR",
                                "No se tiene permiso al almacenamiento interno"
                            );
                            // User canceled the upload
                            break;
                    }
                });
        });
    }

    limpiar() {
        this.image = "";
    }

    dismissModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            dismissed: true,
        });
    }

    actualizar(user: any) {
        try {
            const usuario = user.value;
            usuario.updatedAt =
                dayjs().format("dddd") + ", " + dayjs().format("LL");
            usuario.fullName = usuario.name + " " + usuario.lastName;
            usuario.displayName = usuario.fullName;

            this.afs
                .updateCollection(this.uid, "USUARIOS", user.value)
                .then((resp) => {
                    this.getUser();
                });
            this.updateIsFix(false);
            this.utilService.presentToast("PETICION", "Usuario Actualizado");
        } catch (error) {
            this.utilService.presentToast(
                "ERROR",
                "Error al actualizar datos de usuario"
            );
            console.log("actualizar error --> ", error);
        }
    }

    borrarUsuario() {
        if (this.counter === 0) {
            this.counter = 1;
            this.warning = "Vuelve a presionar para borrar tu cuenta.";
        } else if (this.counter === 1) {
            // firebase.auth().currentUser.delete();
        }
        // user.delete();
    }
}
