<ion-content>
    <ion-header class="ion-no-border">
        <ion-toolbar>
            <ion-title color="primary"></ion-title>
            <ion-buttons slot="start">
                <ion-button (click)="dismissModal()">
                    <ion-icon color="light" name="arrow-back"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <div class="content-modal">
        <div
            class="containf-foto"
            style="background: #05d1e8; padding-bottom: 19px; padding-top: 15px"
        >
            <img
                src="{{ negocio.foto }}"
                alt="{{ negocio.nombre_negocio }}"
                width="100%"
                height="230"
            />
        </div>
    </div>
    <div class="about-info">
        <ion-grid class="ion-padding">
            <ion-row>
                <ion-col size="9" class="hora-">
                    <h3>{{ negocio.nombre_negocio }}</h3>
                </ion-col>
                <ion-col size="3" class="hora-" style="text-align: right">
                    <ion-badge style="font-size: 20px" color="secondary"
                        >{{ negocio.estado ? 'Abierto' : 'Cerrado' }}
                    </ion-badge>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="3" class="hora-"> Direccion </ion-col>
                <ion-col size="9" class="hora-" style="text-align: right">
                    {{ negocio.direccion }}
                </ion-col>
                <ion-col size="3" class="hora-"> Teléfono </ion-col>
                <ion-col size="9" class="hora-" style="text-align: right">
                    {{ negocio.telefono }}
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="6" class="hora-"> Calificar </ion-col>
                <ion-col size="6" class="hora-">
                    <rating
                        [(ngModel)]="rate"
                        readonly="false"
                        size="default"
                        class="calificacion"
                        (ngModelChange)="onModelChange($event)"
                    >
                    </rating>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="4" class="tet-tex">
                    <h3 class="hora-">Horarios</h3>
                </ion-col>
                <ion-col size="8" class="tet-tex" style="text-align: right">
                    {{negocio.week ? negocio.week : 'Lun a Sab'}} : Desde {{
                    negocio.horarios.lower}}H hasta {{negocio.horarios.upper}}H
                </ion-col>
            </ion-row>
            <ion-row> </ion-row>

            <ion-row>
                <ion-col size="6" class="hora-">
                    Reseñas ({{resenas.length}})
                </ion-col>
                <ion-col size="6" style="text-align: center">
                    <ion-button
                        (click)="escribirResena()"
                        shape="round"
                        expand="full"
                        color="secondary"
                        size="small"
                        type="button"
                        class="htm-btn"
                        >Agregar Reseña</ion-button
                    >
                </ion-col>
            </ion-row>

            <ion-row *ngFor="let item of resenas">
                <ion-col>
                    <ion-list>
                        <ion-item-sliding>
                            <ion-item>
                                <ion-avatar slot="start">
                                    <img
                                        src="{{item.foto}}"
                                        alt="{{item.usuario}}"
                                    />
                                </ion-avatar>
                                <ion-label>
                                    <h2>{{item.usuario}}</h2>

                                    <h3 style="padding-left: 0px !important">
                                        {{item.createdAt.seconds * 1000 |
                                        date:'mediumDate'}}
                                    </h3>
                                    <p style="padding-left: 0px !important">
                                        {{item.mensaje}}
                                    </p>
                                </ion-label>
                            </ion-item>
                            <ion-item-options
                                side="end"
                                (ionSwipe)="confirmarBorrar(item)"
                            >
                                <ion-item-option
                                    (click)="confirmarBorrar(item)"
                                    color="danger"
                                >
                                    <ion-icon name="trash"></ion-icon>
                                    Borrar</ion-item-option
                                >
                            </ion-item-options>
                        </ion-item-sliding>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>

<ion-footer class="footer-bgs">
    <div class="boton-footer">
        <div class="btn-flex">
            <ion-button
                expand="full"
                color="light"
                type="button"
                (click)="presentToastWithOptions()"
                >LLAMAR</ion-button
            >
        </div>
        <div class="btn-flex">
            <ion-button
                expand="full"
                color="primary"
                type="button"
                (click)="presentAlertPrompt()"
            >
                SOLICITAR CITA
            </ion-button>
        </div>
    </div>
</ion-footer>
