import { ShortNamePipe } from "./short-name.pipe";

import { NgModule } from "@angular/core";


@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    ShortNamePipe
  ],
  exports: [
    ShortNamePipe
  ]
})
export class ShortNamePipesModule {} 
