<ion-header class="ion-no-border">
    <ion-toolbar style="text-align: center" class="ion-toolbar">
        <ion-buttons slot="start">
            <ion-back-button
                icon="arrow-back"
                color="tertiary"
                text=""
                defaultHref="home"
            >
            </ion-back-button>
        </ion-buttons>
        <ion-title color="tertiary">Agenda</ion-title>
        <ion-buttons slot="end" *ngIf="ubicaciones && ubicaciones.length != 0">
            <ion-button color="secondary" (click)="presentModal()">
                <ion-icon slot="end" name="settings"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-row class="appointment-row-dates shadow" style="margin-bottom: 3rem">
        <ion-col
            size="2"
            class="ion-text-center"
            tappable
            *ngFor="let d of days"
            (click)="selectDay(d)"
            [ngClass]="{'active': fechaSeleccionada == d.format('YYYY-MM-DD')}"
            style="margin-bottom: 1.5rem"
        >
            <span class="small month">{{ d.format('MMM') }}</span> <br />
            <span class="day">{{ d.format('DD') }}</span> <br />
            <span class="small">{{ d.format('ddd') }}</span>
        </ion-col>
    </ion-row>

    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <div *ngIf="!ubicaciones">
                    <ion-spinner *ngIf="!ubicaciones"></ion-spinner>
                    <p>Cargando...</p>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="ubicaciones && ubicaciones.length == 0">
            <ion-col>
                <div style="margin-bottom: 2rem">
                    <h3>Aún no cuentas con negocios registrados.</h3>
                </div>
            </ion-col>
        </ion-row>
        <ion-row
            class="ion-justify-content-center"
            *ngIf="ubicaciones && ubicaciones.length == 0"
        >
            <ion-col sizeLg="6" sizeXs="8">
                <ion-button (click)="mostrarNegocios()"
                    >Crear un negocio</ion-button
                >
            </ion-col>
        </ion-row>
        <ion-row
            style="margin-bottom: 2rem"
            *ngIf="ubicaciones && ubicaciones.length > 0"
            class="ion-justify-content-center"
        >
            <ion-col sizeLg="6" sizeXs="12">
                <ion-select
                    label="Negocio"
                    labelPlacement="floating"
                    [interfaceOptions]="customActionSheetOptions"
                    placeholder="Selecciona un negocio"
                    [(ngModel)]="ubicacionModel"
                    (ionChange)="ubicacionSeleccionada()"
                    cancel-text="Cancelar"
                >
                    <ion-select-option
                        value="{{item.prodid}}"
                        *ngFor="let item of ubicaciones"
                        >{{item.nombre}}</ion-select-option
                    >
                </ion-select>
            </ion-col>
        </ion-row>
    </ion-grid>

    <div class="spin-center">
        <ion-spinner *ngIf="!citas"></ion-spinner>
    </div>

    <ion-content *ngIf="ubicaciones && ubicaciones.length > 0">
        <ion-item *ngIf="citas.length != 0">
            <ion-label>Citas</ion-label>
            <ion-badge color="tertiary" slot="end"
                >{{ citas.length }}</ion-badge
            >
        </ion-item>
        <div *ngIf="citas.length == 0" style="margin-top: 2rem">
            <ion-text color="danger">
                <h4
                    style="
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: center;
                    "
                >
                    No hay citas registradas para el dia seleccionado.
                </h4>
            </ion-text>
        </div>

        <ion-list *ngFor="let hora of horas">
            <ion-list-header lines="inset" class="list-header">
                <ion-label>{{ hora['0'] }} </ion-label>
                <ion-badge color="primary">{{ hora['1'].length }}</ion-badge>
            </ion-list-header>
            <ion-list *ngFor="let cita of hora['1']">
                <ion-item
                    button
                    (click)="presentDetailsModal(cita.prodid)"
                    detail
                >
                    <ion-chip [color]="cita.statusColor()" slot="start">
                        <ion-icon name="time"></ion-icon>
                        <ion-label>{{ cita.status }}</ion-label>
                    </ion-chip>
                    <ion-row>
                        <ion-col>
                            <ion-label class="ion-text-wrap">
                                <ion-text color="primary">
                                    <h3>Cita con {{ cita.nombre }}</h3>
                                </ion-text>
                                <!-- <p class="wrap-text">cita con {{ cita.nombre }}</p> -->
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-item>
            </ion-list>
        </ion-list>
    </ion-content>
</ion-content>
<ion-footer [translucent]="true">
    <div
        style="height: env(safe-area-inset-bottom); background-color: white"
    ></div>
</ion-footer>
